<template>
  <div class="">

    <v-card class="mt-1 mx-0" flat>
      <v-card-title class="my-0 py-1 fs-11pt" v-c:P v-bg:S#4>
        <span class="fw-500 fs-12pt f-sanspro">Resumo da indicação - Meta x Conteúdos x Acordos</span>
      </v-card-title>
      <v-divider class="mt-0 mb-1 py-0"></v-divider>
    </v-card>
    <v-row>
      <v-col cols="12">

        <v-card class="my-2 p-2">
          <v-row>
            <v-col cols="3">
              <v-card class="my-2 p-1 text-center fs-16pt f-sanspro fw-600">
                <p class="m-0 p-0 line-height-2">{{Object.keys(metasIndicadas).length}}</p>
                <p class="m-0 p-0 line-height-3 fs-9pt fw-300">indicações</p>
                <div class="mt-0 text-center f-roboto fs-7pt fw-600">
                  METAS DE COMPREENSÃO
                </div>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="my-2 p-1 text-center fs-16pt f-sanspro fw-600">
                <p class="m-0 p-0 line-height-2">{{Object.keys(conteudos).length}}</p>
                <p class="m-0 p-0 line-height-3 fs-9pt fw-300">indicações</p>
                <div class="mt-0 text-center f-roboto fs-7pt fw-600">
                  CONTEÚDOS
                </div>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="my-2 p-1 text-center fs-16pt f-sanspro fw-600">
                <p class="m-0 p-0 line-height-2">{{Object.keys(acordos).length}}</p>
                <p class="m-0 p-0 line-height-3 fs-9pt fw-300">indicações</p>
                <div class="mt-0 text-center f-roboto fs-7pt fw-600">
                  ACORDOS
                </div>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="my-2 p-1 text-center fs-16pt f-sanspro fw-600">
                <p class="m-0 p-0 line-height-2">{{Object.keys(questoes).length}}</p>
                <p class="m-0 p-0 line-height-3 fs-9pt fw-300">encontradas</p>
                <div class="mt-0 text-center f-roboto fs-7pt fw-600">
                  QUESTÕES
                </div>
              </v-card>
            </v-col>
          </v-row>
          <p class="m-0 pt-1 mx-3 fs-9pt f-raleway text-justify">
            <span class="fw-700 fs-8pt">Mensagem: </span>
          </p>
          <p class="p-0 m-0 my-1 mb-2 mx-3 fw-300 fs-10pt f-roboto text-justify line-height-1">
            Lembre-se - o ideal é que sejam recomendadas, por UC, de <strong>5 (CINCO)</strong> a <strong>7 (SETE)</strong> metas de compreensão.
            Ao indicar as metas, procure indicar um número tão próximo deste intervalo quanto possível.
            Obrigado
            <i v-i:duo#smiley#18 class=""></i>
          </p>
          <hr class="mt-3 mb-2">
          <div class="text-right mt-0 p-0" v-if="blocked">
            <v-btn :disabled="corrigir" class="me-1" color="warning" x-small @click="corrigirIndicacao">
              Corrigir indicação
            </v-btn>
            <v-btn :disabled="aprovar" class="me-1" color="error" x-small @click="aprovarIndicacao">
              Aprovar indicação
            </v-btn>
          </div>
          <div class="text-right mt-0 p-0" v-if="!blocked">
            <v-btn :disabled="true" class="me-1" color="warning" x-small @click="corrigirIndicacao">
              Corrigir indicação
            </v-btn>
            <v-btn :disabled="true" class="me-1" color="error" x-small @click="aprovarIndicacao">
              Aprovar indicação
            </v-btn>
          </div>
        </v-card>

        <v-card class="mt-4" :color="cor.active.b">
          <v-card class="p-2" :color="cor.active.b" v-if="meta != undefined && meta != null">
            <p class="p-0 m-0 f-sanspro fs-11pt">DETALHES da Meta de compreensão selecionada</p>
            <v-card class="p-1 px-2 fs-9pt f-raleway text-justify">
              <readmore class="m-0 p-0" size="300" :text="meta.texto" :key="refresh" v-if="meta.texto != undefined"/>
              <readmore class="m-0 p-0" size="300" :text="meta.meta" :key="refresh" v-if="meta.meta != undefined"/>
            </v-card>
          </v-card>

          <v-tabs height="28" class="mx-3 mt-2 fs-10pt text-left" v-model="tab" background-color="transparent" :color="cor.active.P">
            <v-tab class="" v-for="(item,index) in ['Conteúdos', 'Acordos','Questões']" :key="item" style="min-width: 0px;">
              <template slot:default class="px-0">
                <span v-if="index != 2" class="text-nonecase f-lato fs-11pt fw-600">{{item}}</span>
                <v-badge v-if="index == 2" class="text-nonecase f-lato fs-11pt fw-600" inline :color="cor.active.I">
                  <template v-slot:badge>
                    <span class="fs-10pt f-roboto fw-600">{{qtdeQuestoes}}</span>
                  </template>
                  {{item}}
                </v-badge>
              </template>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" v-bg:b>
            <v-tab-item class="mx-2">
              <listaconteudos :meta="meta" v-if="meta != undefined && meta != null" :key="refresh"/>
            </v-tab-item>
            <v-tab-item>
              <listacordos :meta="meta" v-if="meta != undefined && meta != null" :key="refresh"/>
            </v-tab-item>
            <v-tab-item>
              <listaquestoes :metaID="meta.id" />
            </v-tab-item>
          </v-tabs-items>

        </v-card>
      </v-col>
    </v-row>

    <textareadialog ref="txtareadialogacordo" fieldTitle="Recomendar acordo" mensagem="" @changeValue="novoTexto" />

  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import boxview from '@/components/boxView.vue'
import textareadialog from './textAreaDialog.vue'
import listaconteudos from "./listaConteudos.vue"
import listacordos from "./listaAcordos.vue"
import readmore from "@/components/readmore.vue"
import listaquestoes from "@/views/cursos/avaliacao/metaDetalhes/listaQuestoes.vue"

export default {
  name: "painelconteudos",
  components: { readmore, boxview, listaconteudos, listacordos, listaquestoes, textareadialog },
  props: [ "meta", "uc" ],
  computed: {
    blocked() {
      var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
      return (this.isUserAdmin || gestores.indexOf(this.$store.state.user.cargo) != -1);
    },
    aprovar() {
      return (this.aprovacao && !this.correcao)
    },
    corrigir() {
      return !(this.aprovacao && !this.correcao)
    }
  },
  data(){
    return {
      tab: null,
      loading: false,
      refresh: 0,
      conteudos: {},
      acordos: {},
      metasIndicadas: {},
      questoes: {},
      qtdeQuestoes: 0,
      aprovacao: false,
      correcao: false,
      profsRef: {},
    }
  },
  watch: {
    uc() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    //console.log("this.meta",this.meta);
    self.build();
  },
  methods:{

    build() {
      var self = this;

      if(self.uc != undefined) {

        rdb.ref("/curriculo/2021S2/ucsProfsRef/"+this.uc.id+"/professores").on('value',function(snapshot) {
          self.profsRef = snapshot.val();
        });

        rdb.ref("/curriculo/avaliacao/2021S2/ucsA2Indicadas/"+self.uc.id).on('value', function(snapshot) {
          var value = snapshot.val();
          if(value == null) {
            self.correcao = false;
            self.aprovacao = false;
          } else {
            self.correcao = value.correcao;
            self.aprovacao = true;
          }
        });

        rdb.ref('/curriculo/avaliacao/2021S2/metasIndicadasFinal/').orderByChild('ucID').equalTo(self.uc.id).on('value', function(snapshot) {
          self.metasIndicadas = {};
          self.conteudos = {};
          self.acordos = {};
          self.questoes = {};
          if(snapshot.val() != null) {
            self.metasIndicadas = snapshot.val();
            for(var key in self.metasIndicadas) {
              var meta = self.metasIndicadas[key];
              rdb.ref('/curriculo/avaliacao/2021S2/metasConteudos/'+meta.id).on('value',function(snapshot) {
                var ret = snapshot.val();
                if(ret != null) {
                  for(var idx in ret.conteudos) {
                    self.conteudos[idx] = ret.conteudos[idx];
                  }
                }
              });
              rdb.ref('/curriculo/avaliacao/2021S2/metasAcordos/'+meta.id).on('value',function(snapshot) {
                var ret = snapshot.val();
                if(ret != null) {
                  for(var idx in ret.acordos) {
                    self.acordos[idx] = ret.acordos[idx];
                  }
                }
              });
              rdb.ref('/curriculo/questoes').orderByChild("metaCodigo").equalTo(meta.metaCodigo).on('value',function(snapshot) {
                var ret = snapshot.val();
                if(ret != null) {
                  for(var idx in ret) {
                    self.questoes[idx] = ret[idx];
                  }
                }
              });

            }
          }
          console.log("self.metasIndicadas",self.metasIndicadas);
        });

        console.log("self.meta",self.meta);
        if(self.meta != undefined && self.meta != null) {
          rdb.ref('/curriculo/questoes').orderByChild("metaID").equalTo(self.meta.id).on('value',function(snapshot) {
            self.qtdeQuestoes = snapshotValToArray(snapshot.val()).length;
            console.log("self.qtdeQuestoes",self.qtdeQuestoes);
          });
        }

      }

    },

    novoTexto(texto) {
      console.log("novoTexto",texto);
    },

    aprovarIndicacao() {
      var self = this;
      console.log("aprovarIndicacao");
      console.log("self.uc",self.uc);
      if(Object.keys(self.metasIndicadas).length == 0) {
        self.toastD("Nenhuma meta indicada!");
        return;
      }

      var updates = {};
      updates["/curriculo/avaliacao/2021S2/ucsA2Indicadas/"+self.uc.id] = {
        ucID: self.uc.id,
        correcao: false,
      };
      console.log("updates",updates);
      rdb.ref().update(updates);
      self.toastS("Aprovação de Metas de Compreensão realizada com sucesso!");
      self.refresh++;
      self.$forceUpdate();
    },

    corrigirIndicacao() {
      var self = this;
      console.log("corrigirIndicacao");
      console.log("self.uc",self.uc);

      var updates = {};
      updates["/curriculo/avaliacao/2021S2/ucsA2Indicadas/"+self.uc.id] = {
        ucID: self.uc.id,
        correcao: true,
      };
      console.log("updates",updates);
      rdb.ref().update(updates);
      self.toastS("CORREÇÃO de Metas de Compreensão ativada com sucesso!");
      self.refresh++;
      self.$forceUpdate();
    }

  }
}
</script>

<style scoped>

.card-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
