<template>
  <v-row justify="center" v-if="area != null">
    <v-card v-bg:b>
      <v-toolbar :color="cor.active.B">
        <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-400 fs-11pt">
          <p class="p-0 m-0 mt-4 fs-12pt">Indicação de metas de compreensão - A2</p>
          <p class="p-0 m-0 fw-600" v-c:D>ACOMPANHAMENTO</p>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row class="mx-1 mt-1" v-bg:b>
        <v-col cols="12" sm="12" md="6" class="">
          <v-card class="mt-1 mx-0" flat>
            <div class="">
              <cardareaa2 :area="area" :verDetalhes="false" :key="refresh"/>
            </div>
          </v-card>
          <div class="mt-2 mb-0">
            <v-radio-group v-model="filtro" mandatory row>
              <v-radio value="todas" dense :color="area.cor" >
                <template v-slot:label>
                  <span class="ms-n1 f-roboto fw-500 fs-8pt">Todas UCs</span>
                  <span class="ms-1 rounded f-lato line-height-4 fs-8pt px-1" v-c:B :style="'background-color:'+area.cor">{{qtdeUcs}}</span>
                </template>
              </v-radio>
              <v-radio value="aguardando" class="ms-0" dense :color="area.cor">
                <template v-slot:label>
                  <span class="ms-n1 f-roboto fw-500 fs-8pt">Aguardando</span>
                  <span class="ms-1 rounded f-lato line-height-4 fs-8pt px-1" v-c:B :style="'background-color:'+hexShades(cor.active.S,0.5)">{{qtdeUcsAguardando}}</span>
                </template>
              </v-radio>
              <v-radio value="aprovadas" class="ms-0" dense :color="cor.active.V">
                <template v-slot:label>
                  <span class="ms-n1 f-roboto fw-500 fs-8pt">Aprovadas</span>
                  <span class="ms-1 rounded f-lato line-height-4 fs-8pt px-1" v-c:B :style="'background-color:'+hexTints(cor.active.V,0.1)">{{qtdeUcsAprovadas}}</span>
                </template>
              </v-radio>
              <v-radio value="correcao" class="ms-0" dense :color="cor.active.D">
                <template v-slot:label>
                  <span class="ms-n1 f-roboto fw-500 fs-8pt">Em correção</span>
                  <span class="ms-1 rounded f-lato line-height-4 fs-8pt px-1" v-c:B :style="'background-color:'+hexTints(cor.active.D,0.1)">{{qtdeUcsCorrecao}}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="clear"></div>
          <v-card class="mt-2 px-1 pt-1 pb-1 card-scroll" height="524" outlined>
            <v-list dense>
              <v-list-item-group>
                <div class="m-0 p-0" v-for="(uc,key,index) in ucs" :key="key">
                  <ucitem @selecionado="selecionado" :uc="uc" :area="area" v-if="filtro == 'todas'"/>
                  <ucitem @selecionado="selecionado" :uc="uc" :area="area" v-if="uc != undefined && uc.correcao == undefined && filtro == 'aguardando'"/>
                  <ucitem @selecionado="selecionado" :uc="uc" :area="area" v-if="uc != undefined && uc.correcao == false && filtro == 'aprovadas'"/>
                  <ucitem @selecionado="selecionado" :uc="uc" :area="area" v-if="uc != undefined && uc.correcao == true && filtro == 'correcao'"/>
                </div>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6">
          <carduc :uc="ucSel" :area="area" :key="refresh" v-if="ucSel != null"/>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import dialogtoolbar from "./dialogToolBar.vue";
import cardareaa2 from "./card-areaA2.vue"
import tagarea from "@/views/cursos/tagArea.vue";
import ucitem from "./uc-item.vue";
import carduc from "./card-uc.vue";

export default {
  name: "acompanhaarea",
  components: { dialogtoolbar, cardareaa2, tagarea, ucitem, carduc },
  props: {
    area: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
      ucs: [],
      ucSel: null,
      filtro: "todas",
      qtdeUcs: 0,
      qtdeUcsAprovadas: 0,
      qtdeUcsAguardando: 0,
      qtdeUcsCorrecao: 0,
    }
  },
  watch: {
    filtro() {
      this.refresh++;
      //console.log("this.filtro",this.filtro);
    },
    area() {
      this.build();
      this.refresh++;
    }
  },
  mounted: function() {
    var self = this;
    self.build();
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      if(self.area == null) return;

      rdb.ref('/curriculo/ucs/').orderByChild("oferta2021S2").equalTo(true).on('value',function(snapshot) {
        var ucs = snapshot.val();
        var ucsArea = {};
        //console.log("ucs",ucs);
        self.qtdeUcs = 0;
        for(var key in ucs) {
          if(ucs[key].areas[self.area.id] != undefined) {
            self.qtdeUcs++;
            ucsArea[key] = ucs[key];
          }
        }
        self.ucs = ucsArea;
        //console.log("self.ucs",self.ucs);
        //console.log("self.qtdeUcsOfertadas",self.qtdeUcsOfertadas);
        rdb.ref('/curriculo/avaliacao/2021S2/ucsA2Indicadas/').on('value',function(snapshot) {
          var ucs = snapshot.val();
          self.qtdeUcsAprovadas = 0;
          self.qtdeUcsCorrecao = 0;
          for(var key in ucs) {
            if(ucsArea[key] != undefined) {
              self.ucs[key].correcao = ucs[key].correcao;
              if(self.ucs[key].correcao) {
                self.qtdeUcsCorrecao++;
              } else {
                self.qtdeUcsAprovadas++;
              }
            }
          }
          self.qtdeUcsAguardando = Object.keys(ucsArea).length - self.qtdeUcsAprovadas - self.qtdeUcsCorrecao;
          if(self.filtro == 'todas') {
            self.ucSel = self.ucs[Object.keys(self.ucs)[0]];
            //console.log("self.ucSel",self.ucSel);
          }
        });
      });

    },

    selecionado(uc) {
      //console.log("selecionado",uc);
      this.ucSel = uc;
    }

  }
}
</script>

<style scoped>
</style>
