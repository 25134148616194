import { render, staticRenderFns } from "./feedControler.vue?vue&type=template&id=7b0a54b5&scoped=true&"
import script from "./feedControler.vue?vue&type=script&lang=js&"
export * from "./feedControler.vue?vue&type=script&lang=js&"
import style0 from "./feedControler.vue?vue&type=style&index=0&id=7b0a54b5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b0a54b5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VFabTransition,VIcon,VImg,VListItem,VListItemAvatar,VListItemContent,VTooltip})
