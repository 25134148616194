<template>
  <!-- NIVEL 2 -->
  <div class="me-1 mt-n4 mb-0 py-0" style="margin-left: 8%" v-if="resposta != null">
    <v-list three-line class="mb-n4 py-0">
      <v-list-item>
        <v-list-item-avatar size="40">
          <v-img :src="resposta.urlFoto"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <div class="m-0 mb-1 p-0 f-roboto fw-500 fs-7pt">
              {{resposta.usrNome}}
              <span class="ms-1 ms-1 f-sanspro fs-8pt fw-300">{{$moment(resposta.createdAt).fromNow()}}</span>
            </div>
          </v-list-item-title>
          <div class="m-0 p-0 f-raleway fw-300 fs-10pt" style="word-wrap: normal;">
            <readmore class="m-0 p-0" size="300" :text="resposta.texto" />
          </div>
          <v-list-item-subtitle>
            <likes :uc="uc" :color="color" :rdbPath="rdbPath" :key="refresh"/>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import { color } from "./colors.js"
import { faker } from '@/components/dummy/faker.js'
import moment from 'moment';
import likes from "./likes.vue"

export default {
  name: 'feedComentItem',
  components: { readmore, likes },
  props: {
    color: { default: "#000000", type: String },
    uc: { default: null, type: Object },
    rdbPath: { default: "/pathNotDefined", type: String },
  },
  computed: {},
  data() {
    return {
      respostaEnabled: false,
      texto: "",
      user: null,
      resposta: null,
      showCurtidas: false,
      likes: [],
      liked: false,
      likedId: "semID",
      refresh: 0,
    }
  },
  watch: {
  },
  mounted() {
    var self = this;

    self.user = self.userLog;

    if(this.rdbPath != undefined) {
      rdb.ref(this.rdbPath).on('value',function(snapshot) {
        var ret = snapshot.val();
        console.log("resposta");
        console.log(ret);
        self.resposta = ret;
      });
      rdb.ref(self.rdbPath+"/likes").on('value',function(snapshot) {
        var ret = snapshotValToArray(snapshot.val());
        console.log("likes in: ",self.rdbPath+"/likes");
        console.log(ret);
        self.likes = ret;
        for (var i = 0; i < ret.length; i++) {
          if(ret[i].usrID == self.user.id) {
            self.liked = true;
            self.likedId = ret[i].id;
            break;
          }
        }
        self.$nextTick(function() {
          self.$forceUpdate();
        })
      });
    }

    eventBus.$on("closeAllComents", function() {
      console.log("closeAllComents")
      self.respostaEnabled = false;
    });

  },
  methods: {

    showResposta() {
      var self = this;
      console.log("showResposta");
      this.$emit("novaResposta", "@"+this.resposta.usrNome+" ");
    },

    pushKey(path,obj) {
      var pathUrl = this.rdbPath+"/"+path+"/";
      console.log("pushKey on: ",pathUrl);
      var newKey = rdb.ref(pathUrl).push().key;
      console.log("put com nova chave: "+newKey);
      var itemClone = Object.assign({}, obj);
      itemClone.id = newKey;
      var updates = {};
      console.log(pathUrl + newKey);
      updates[pathUrl + newKey] = itemClone;
      rdb.ref().update(updates);
      console.log("pushKey success!");
    },

    clickLike() {
      console.log("clickLike");
      console.log(this.rdbPath);
      console.log(this.user);
      //var user = faker.getRamdonRealUser();
      //console.log(user);
      if(this.liked) {
        var pathUrl = this.rdbPath+"/likes/"+this.likedId;
        console.log(pathUrl);
        rdb.ref(pathUrl).remove();
        this.liked = false;
        this.likedId = "semID";
        console.log("like removed!");
      } else {
        var likeObj = {
          usrNome: this.user.nome,
          usrID: this.user.id,
          createdAt: moment().format(),
        };
        this.pushKey("likes",likeObj);
      }
    }


  }
}
</script>

<style scoped>

.form-control:focus{border-color: #4d594d;  box-shadow: none; -webkit-box-shadow: none;}
.has-error .form-control:focus{box-shadow: none; -webkit-box-shadow: none;}

</style>
