import { render, staticRenderFns } from "./descontinuadosEmenta.vue?vue&type=template&id=84841246&scoped=true&"
import script from "./descontinuadosEmenta.vue?vue&type=script&lang=js&"
export * from "./descontinuadosEmenta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84841246",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFabTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBadge,VBtn,VCard,VCardText,VFabTransition})
