<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-text>
        <div class="pt-2 fs-11pt f-raleway fw-700 text-center">
          <i class="btn-icon-left" v-i:duo#warning#22 v-c:D></i>
          <span class="">Mensagem</span>
        </div>
        <v-divider class="my-2"></v-divider>
        <p class="my-0 text-center f-raleway fs-10pt">
          {{msgProp}}
        </p>
        <v-divider class="my-2"></v-divider>
        <div class="mt-4 text-center">
          <v-btn color="" small @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn class="ms-4" color="success" small @click="dialog = false; $emit('confirmar');">
            OK
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "comfirmDialog",
  components: { },
  props: [ "mensagem" ],
  computed: { },
  data(){
    return {
      msgProp: "",
      dialog: false,
    }
  },
  watch: {
    mensagem(to, from) {
      var self = this;
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    this.build();
  },
  methods:{

    build() {
      var self = this;
      this.msgProp = this.mensagem;
    },

    show() {
      this.dialog = true;
    }

  }
}

</script>

<style scoped>

</style>
