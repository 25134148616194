<template>
  <div class="">
    <div class="mb-0" v-if="Math.ceil(qtdeVotos/totalIndicacoes*100) > 70">
      <v-progress-linear :color="hexTints(cor.active.V,.3)" :value="Math.ceil(qtdeVotos/totalIndicacoes*100)" striped height="14">
        <strong class="pt-0 fw-500 f-lato fs-10pt" v-c:K>{{ Math.ceil(qtdeVotos/totalIndicacoes*100) }}%</strong>
        <span class="ms-3 f-lato fs-8pt fw-300" v-c:K>({{qtdeVotos}} em {{totalIndicacoes}} votos)</span>
      </v-progress-linear>
    </div>
    <div class="mb-0" v-if="Math.ceil(qtdeVotos/totalIndicacoes*100) > 49 && Math.ceil(qtdeVotos/totalIndicacoes*100) <= 70">
      <v-progress-linear :color="hexTints(cor.active.W,.1)" :value="Math.ceil(qtdeVotos/totalIndicacoes*100)" striped height="14">
        <strong class="pt-0 fw-500 f-lato fs-10pt" v-c:K>{{ Math.ceil(qtdeVotos/totalIndicacoes*100) }}%</strong>
        <span class="ms-3 f-lato fs-8pt fw-300" v-c:K>({{qtdeVotos}} em {{totalIndicacoes}} votos)</span>
      </v-progress-linear>
    </div>
    <div class="mb-0" v-if="Math.ceil(qtdeVotos/totalIndicacoes*100) < 50">
      <v-progress-linear :color="hexTints(cor.active.D,.4)" :value="Math.ceil(qtdeVotos/totalIndicacoes*100)" striped height="14">
        <strong class="pt-0 fw-500 f-lato fs-10pt" v-c:K>{{ Math.ceil(qtdeVotos/totalIndicacoes*100) }}%</strong>
        <span class="ms-3 f-lato fs-8pt fw-300" v-c:K>({{qtdeVotos}} em {{totalIndicacoes}} votos)</span>
      </v-progress-linear>
    </div>
  </div>
</template>

<script>

export default {
  name: "votosIndicador",
  components: {},
  props: [ "qtdeVotos", "totalIndicacoes" ],
  computed: {},
  data(){
    return {
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
