<template>
  <div class="row mt-1 pt-0">
    <div class="col-12 col-lg-12 m-0">
      <v-card class="mb-0 pb-0 mt-0 pt-0" outlined>
        <v-card-text class="mb-0 mb-0 pt-1 pb-0">
          <v-row class="m-0 p-0">
            <v-col class="m-0 p-0">
              <v-btn @click="$refs.refformempresa.show()" class="text-nonecase m-0 mt-1 py-0 px-1" block outlined small :color="area.cor">
                <i v-i:duo#plus-circle#16 class="btn-icon-left"></i>
                <span>adicionar empresa</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-2 pt-0 pb-0">
            <v-col class="py-0 my-0" cols="12">
              <selectempresas @changesel="changeSelectEmpresas"/>
            </v-col>
          </v-row>
          <!-- <p><VueObjectView v-model="value" :key="refreshKey"/></p> -->

          <div v-if="loading" class="mt-4">
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
          </div>

          <v-card v-if="!loading && empresas.length == 0" class="p-3" v-bg:b border="left">
            <p class="m-0 p-0" style="color: black;">
              Nenhuma empresa encontrada.
            </p>
          </v-card>

          <hr class="mt-1 mb-2">

          <v-list two-line dense class="mx-0 px-0 pt-0 mt-0">
            <v-list-item-group v-model="empresaSel">
              <div class="px-0 mx-0" v-for="(empresa,index) in empresasFiltradas">
                <listaempresaitem :empresa="empresa" @empresaClick="empresaClick"/>
                <v-divider class="mt-0 mb-1 p-0"></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <formempresa ref="refformempresa" />

  </div>

</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import VueObjectView from 'vue-object-view';
import selectempresas from "./selectempresas.vue";
import listaempresaitem from "./listaEmpresaItem.vue";
import formempresa from "./formEmpresa.vue";

export default {
  name: "listaempresas",
  components: { selectempresas, listaempresaitem, formempresa },
  props: [ "area" ],
  data(){
    return {
      loading: false,
      empresas: [],
      empresasParaAtualizar: {},
      empresasFiltradas: [],
      nomesSel: [],
      empresaSel: null,
      refreshKey: 0,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    rdb.ref('/empresas/').on('value', function(snapshot) {
      self.empresas = snapshot.val();
      self.empresasFiltradas = self.empresas;
      console.log("self.empresas",self.empresas);
      self.filtrar();
      self.loading = false;
    });
    //console.log(self.userLog);
  },

  methods:{

    changeFiltro(dados) {
      console.log("changeFiltro",dados);
      this.filtros = dados;
      this.filtrar();
    },

    filtrar() {
      var self = this;
      console.log("filtrar");
      this.empresasFiltradas = this.empresas;
      this.filtrarNome();
      //this.filtrarSetor();
      //this.filtrarEquipe();
      //this.filtrarCargo();
      console.log("this.empresasFiltradas",self.empresasFiltradas);
    },

    filtrarNome() {
      var self = this;
      console.log("filtrarNome");
      if(this.nomesSel.length == 0) {
        return;
      }
      var empresas = {};
      for(var key in self.empresasFiltradas) {
        if(this.nomesSel.indexOf(key) != -1) {
          empresas[key] = self.empresasFiltradas[key];
        }
      }
      self.empresasFiltradas = empresas;
    },

    changeSelectEmpresas(dados) {
      console.log("changeSelectEmpresas",dados);
      this.nomesSel = dados;
      this.filtrar();
    },

    empresaClick(empresa) {
      this.$emit('empresaClick',empresa);
    }

  }
}
</script>

<style scoped>

</style>
