<template>
  <span>
    <span v-for="area in vetSiglas" class="ms-0">
      <span v-if="areas[area] != undefined">
        <img :src="areas[area].urlIcone" class="ps-0 ms-0" style="width: 20px;">
        <span class="badge ms-1 me-2" :style="'backgroundColor: '+areas[area].cor">
          {{ areas[area].sigla }}
        </span>
      </span>
    </span>
  </span>
</template>

<script>
import { rdb, snapshotToArray, snapshotValToArray } from '@/firebasedb.js'

export default {
  name: "tagsArea",
  props: [ "siglas" ],
  data: function() {
    return {
      areas: [],
      vetSiglas: [],
    }
  },
  created: function() {

  },
  mounted: function() {
    var self = this;
    console.log("tagsarea Mounted");
    //console.log(this.siglas);
    this.vetSiglas = this.siglas.split(";");
    //console.log(this.vetSiglas);
    rdb.ref('/areas').on('value', function(snapshot) {
      var ret = snapshotValToArray(snapshot.val());
      //console.log(ret);
      for(var i=0; i<ret.length; i++) {
        self.areas[ret[i].sigla] = ret[i];
      }
      self.$forceUpdate();
      //console.log(self.areas);
    });
  },
  methods: {


  }
}
</script>

<style scoped>

</style>
