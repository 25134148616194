<template>
  <div class="right p-0 m-0 line-height-1" v-if="user != null && user.admin">
    <v-btn class="" v-c:D text x-small @click="descontinuar">
      <i class="" style="margin-top: -3px; margin-right: 1px;" v-i:duo#dots-three-circle#14></i>
      <span class="text-nonecase fw-300 f-roboto fs-8pt">descontinuar</span>
    </v-btn>
    <comfirmdialog ref="comfdialog" :mensagem="'Deseja realmente DESCONTINUAR '+fieldTitle+' ?'" @confirmar="confirmaDescontinuar"/>
    <textareadialog ref="txtareadialog" :fieldTitle="fieldTitle" mensagem="teste" @changeValue="novoTexto"/>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import comfirmdialog from "@/views/cursos/confirmDialog.vue"
import textareadialog from "@/views/cursos/planos/textAreaDialog.vue"

export default {
  name: "descontinuarBtn",
  components: { comfirmdialog, textareadialog },
  props: [ "area", "ucSel","fieldTitle", "index" ],
  computed: {
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolorn50: function() { return 'color:'+this.hexTints(this.area.cor,-50)+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      uc: null,
      user: null,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      console.log("descontinuarBtn change uc");
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    self.user = self.userLog;

    this.build();
  },
  methods:{

    build() {
      var self = this;
      this.uc = this.ucSel;
      //var ementaPath = "/ucs/"+this.uc.id+"/ementa/";
      //console.log(ementaPath);
      //this.ementaPath = ementaPath;
    },

    descontinuar() {
      console.log("descontinuar");
      this.$refs.comfdialog.show();
    },

    confirmaDescontinuar() {
      console.log("confirmaDescontinuar");
      this.$refs.txtareadialog.show();
    },

    novoTexto(texto) {
      console.log(texto);
      this.$emit("descontinuar",texto,this.index);
    }

  }
}
</script>

<style scoped>

</style>
