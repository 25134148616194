<template>
  <v-card class="" v-bg:b>
    <v-row class="mx-0">
      <v-col cols="12" lg="6">
        <v-card class="p-1">
          <p class="my-0 mx-2 fs-10pt f-sanspro fw-600">
            UCs Duais 2021/2
          </p>
          <hr class="mb-0 mt-1">
          <v-card class="mt-3 mb-0 pb-0">
            <v-alert class="mb-1" type="info" colored-border border="left" :color="hexTints(area.cor,0.1)">
              <span class="fs-10pt f-raleway fw-400">Nenhuma UC indicada.</span>
            </v-alert>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" class="">
        <v-card class="p-1">
          <p class="my-0 mx-2 fs-10pt f-sanspro fw-600">
            UCs Ofertadas 2021/2
          </p>
          <hr class="mb-0 mt-1">
          <ucsviewarea :especificas="false" @clickUc="clickUc" :area="area"/>
        </v-card>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import ucsviewarea from "./ucsViewArea.vue"

export default {
  name: "ucsduais",
  components: { ucsviewarea },
  props: [ "area", "empresa" ],
  computed: {},
  data(){
    return {
      tab: null,
      refresh: 0,
      selectInitialUc: 0,
    }
  },
  watch: {
  },
  mounted: function() {
    var self = this;
  },
  methods:{

    selectInitialUc(uc) {
      console.log("selectInitialUc");
      console.log(uc);
      this.uc = uc;
      this.refresh++;
    },

    clickUc(uc) {
      console.log("clickUc");
      console.log(uc);
      //this.uc = uc;
      this.refresh++;
    }

  }
}
</script>

<style scoped>

</style>
