<template>
  <v-card class="p-0" width="400">
    <v-toolbar :color="cor.active.b" dense>
      <i v-i:duo#info#28></i>
      <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-400 fs-11pt">
        <p class="p-0 m-0">Detalhes da indicação da meta de compreensão</p>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card class="p-0 py-2">
      <v-row>
        <v-col cols="3" class="text-center px-0 mx-0">
          <v-progress-circular :rotate="360" :size="65" :width="8" :value="parseInt((this.qtde/this.qtdeTotal)*100)" :color="getColor">
            <div class="m-0 p-0 text-center" v-cHex="hexShades(getColor,0.5)">
              <div class="m-0 p-0 line-height-1 fs-11pt f-sanspro fw-800">
                {{ parseInt((this.qtde/this.qtdeTotal)*100) }}%
              </div>
              <div class="m-0 p-0 line-height-1 fs-8pt f-sanspro fw-400">
                {{this.qtde}} em {{this.qtdeTotal}}
              </div>
            </div>
          </v-progress-circular>
          <div class="mt-2 fs-9pt f-sanspro line-height-2">
            <span class="fw-800">{{conteudos.length}}</span>
            <span class="ms-1">conteúdo</span><span v-if="conteudos.length > 1">s</span>
          </div>
          <div class="mt-0 fs-9pt f-sanspro line-height-2">
            <span class="fw-800">{{acordos.length}}</span>
            <span class="ms-1">acordo</span><span v-if="acordos.length > 1">s</span>
          </div>
        </v-col>
        <v-col cols="9" class="text-left px-0 mx-0">
          <div class="text-left px-0 mx-0 fs-9pt f-sanspro">
            <span class="me-1 fw-600 fs-11pt">{{this.qtde}}</span>
            <span class="me-1 fw-400" v-if="this.qtde == 1">sugestão no total de </span>
            <span class="me-1 fw-400" v-if="this.qtde > 1">sugestões no total de </span>
            <span class="me-1 fw-600 fs-11pt">{{this.qtdeTotal}}</span>
            <span class="me-1 fw-400" v-if="this.qtdeTotal == 1">recomendação </span>
            <span class="me-1 fw-400" v-if="this.qtdeTotal > 1">recomendações </span>
          </div>
          <div class="mt-1">
            <div class="text-left mt-1 p-0" v-for="(user,index) in votos" :key="index">
              <avatarcon :user="user" size="24"/>
              <span class="ms-2 f-raleway fs-7pt fw-300">{{user.nome}}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import avatarcon from "@/views/admin/cards/avatar-con.vue";

export default {
  name: "tooltipcontent",
  components: { avatarcon },
  props: {
    qtdeTotal: { default: 0, type: Number},
    qtde: { default: 0, type: Number},
    votos: { default: [], type: Array},
    meta: { default: null, type: Object},
  },
  computed: {
    getColor() {
      if(this.qtde == 0) {
        return "#ffffff";
      }
      if((this.qtde/this.qtdeTotal)*100 > 80) {
        return "#00b02c";
      }
      if((this.qtde/this.qtdeTotal)*100 > 60) {
        return "#cfc600";
      }
      if((this.qtde/this.qtdeTotal)*100 > 40) {
        return "#e2c400";
      }
      if((this.qtde/this.qtdeTotal)*100 > 20) {
        return "#d65400";
      }
      if((this.qtde/this.qtdeTotal)*100 > 0) {
        return "#9f0404";
      }
      return "#ffffff";
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      conteudos: [],
      acordos: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;

      if(self.meta != null) {
        rdb.ref('/curriculo/avaliacao/2021S2/metasConteudos/'+self.meta.id).on('value',function(snapshot) {
          self.conteudos = [];
          var ret = snapshot.val();
          if(ret != null) {
            self.conteudos = snapshotValToArray(ret.conteudos);
            console.log("self.conteudos",self.conteudos);
          }
        });
        rdb.ref('/curriculo/avaliacao/2021S2/metasAcordos/'+self.meta.id).on('value',function(snapshot) {
          self.acordos = [];
          var ret = snapshot.val();
          if(ret != null) {
            self.acordos = snapshotValToArray(ret.acordos);
            console.log("self.acordos",self.acordos);
          }
        });
      }
    }
  }
}
</script>

<style scoped>
</style>
