<template>
  <div id="chart" class="h66">
    <apexchart type="radialBar" :height="height" :options="chartOptions" :series="series" :key="3"></apexchart>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "semidonut",
  components: {},
  props: {
    color: { default: "blue", type: String },
    values: { default: null, type: Array },
    height: { default: 140, type: Number },
    offsetY: { default: -14, type: Number },
  },
  computed: {
    chartOptions() {
      return this.buildChartOptions();
    },
    series() {
      if(this.values == null) return [0];
      return this.values;
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    buildChartOptions() {
      var options = {
        chart: { type: 'radialBar', offsetY: this.offsetY, sparkline: { enabled: true } },
        colors: [ this.color ],
        plotOptions: {
          radialBar: {
            startAngle: -90, endAngle: 90,
            hollow: { margin: 0, size: "52%" },
            track: {
              background: "#ffffff", strokeWidth: '97%', margin: 5, // margin is in pixels
              dropShadow: { enabled: true, top: 1, left: 0, color: '#999', opacity: 0.8, blur: 4 }
            },
            dataLabels: { name: { show: false, }, value: { offsetY: -16, fontSize: '12pt', color: this.color } }
          }
        },
        fill: { type: 'gradient', gradient: { shade: 'light', shadeIntensity: 0.4, inverseColors: false, opacityFrom: 1, opacityTo: 1, stops: [0, 50, 53, 91] }, },
        labels: ['Entregas'],
      }
      return options;
    }

  }
}
</script>

<style scoped>
</style>
