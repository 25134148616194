<template>
  <div class="row">
    <div class="row mt-0">
      <div class="col-12 col-lg-12 mt-0">
        <v-tabs class="mt-n2" v-model="tab" background-color="transparent" grow :color="area.cor">
          <v-tab class="mt-0" v-for="item in items" :key="item">
            <span class="f-raleway fw-400">{{ item }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">

          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <v-card>
                      <v-card-text class="mt-n3">
                        <div class="row">
                          <div class="col-12 col-lg-12">
                            <div class="card bg-white shadow-sm mt-0 p-3">
                              <b-form-checkbox-group v-model="regionais">
                                <div class="row">
                                  <div class="col-12 col-lg-2 f-lato fs-12pt p-0">
                                    Regionais:
                                  </div>
                                  <div class="col-6 col-lg-2 p-0">
                                    <b-form-checkbox value="SP"><span class="fs-8pt">SP</span></b-form-checkbox>
                                  </div>
                                  <div class="col-6 col-lg-2 p-0">
                                    <b-form-checkbox small value="MG / GO"><span class="fs-8pt">MG / GO</span></b-form-checkbox>
                                  </div>
                                  <div class="col-6 col-lg-2 p-0">
                                    <b-form-checkbox value="SUL"><span class="ms-1 fs-8pt">SUL</span></b-form-checkbox>
                                  </div>
                                  <div class="col-6 col-lg-2 p-0">
                                    <b-form-checkbox value="NE"><span class="ms-1 fs-8pt">NE</span></b-form-checkbox>
                                  </div>
                                </div>
                              </b-form-checkbox-group>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-0">
                          <div class="col-12 col-lg-12">
                            <cardCoordenadores :editLock="editLock" :siglaArea="area.sigla" grandeArea="2" :regionaisSel="regionais"/>
                          </div>
                        </div>

                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card class="mt-2">
              <v-card-text>
                <v-alert type="info">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <div class="card bg-white shadow-sm mt-0 p-3">
                      <b-form-checkbox-group v-model="regionais">
                        <div class="row">
                          <div class="col-6 col-lg-2 fs-5">
                            Regionais:
                          </div>
                          <div class="col-6 col-lg-2">
                            <b-form-checkbox value="SP"><span class="ms-1">SP</span></b-form-checkbox>
                          </div>
                          <div class="col-6 col-lg-2">
                            <b-form-checkbox value="MG / GO"><span class="ms-1">MG / GO</span></b-form-checkbox>
                          </div>
                          <div class="col-6 col-lg-2">
                            <b-form-checkbox value="SUL"><span class="ms-1">SUL</span></b-form-checkbox>
                          </div>
                          <div class="col-6 col-lg-2">
                            <b-form-checkbox value="NE"><span class="ms-1">NE</span></b-form-checkbox>
                          </div>
                        </div>
                      </b-form-checkbox-group>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <cardCoordenadores :editLock="editLock" :siglaArea="area.sigla" grandeArea="2" :regionaisSel="regionais"/>
                  </div>
                </div>

              </v-card-text>
            </v-card>
          </v-tab-item>


        </v-tabs-items>
      </div>
    </div>

  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import cardCoordenadores from "./cardCoordenadores"

export default {
  name: "pessoasView",
  components: { cardCoordenadores },
  props: [ "editLock", "area" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      tab: null,
      items: [ 'COORDENADORES', 'PROFESSORES' ],
      regionais: [ "SP", "MG / GO", "SUL", "NE"],
    }
  },
  mounted: function() {
    var self = this;
  },
  methods:{

  }
}
</script>

<style scoped>

.badgeTop {
  margin-bottom: 0px;
  margin-right: 12px;
  border: 2px solid #ccc;
  background-color: #2c0738;
  color: #fff;
  font-weight: bold; font-size: 14pt;
}

.fs-7 {
  font-size: 8pt !important;
}
</style>
