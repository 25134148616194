<template>
  <div class="" v-if="dados != undefined">
    <v-card class="p-2 mt-2" outlined>
      <v-row>
        <v-col cols="12" lg="3" class="my-0">
          <p class="mt-0 mb-1 fs-10pt f-lato fw-600">Questões</p>
          <hr class="mt-0 mb-2">

          <v-list dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item class="border" style="min-height: 34px; max-height: 34px; height: 34px;" v-for="(item,key,index) in dados.questoes" :key="index">
                <v-list-item-icon class="me-0 pe-0">
                  <i v-i:duo#note#22 class="absolute me-0 pe-0" style="left: 10px; top: 0px;"></i>
                </v-list-item-icon>
                <v-list-item-content class="">
                  <v-list-item-title>
                    <span class="f-roboto fw-600 fs-8pt">ID:</span>
                    <span class="ms-1 f-roboto fw-500 fs-10pt">{{item.id}}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="12" lg="9">
          <p class="mt-0 mb-1 fs-10pt f-lato fw-600">Item</p>
          <hr class="mt-0 mb-2">
          <v-card outlined class="p-2">
            <p class="m-0 p-0">
              <span class="p-0 m-0 fs-8pt f-titillium fw-600">Dificuldade:</span>
              <span v-bg:P v-c:B class="rounded px-1 mx-1 fs-9pt f-roboto fw-300">
                {{dados.questoes[selectedItem].dificuldade}}
              </span>
              <span class="p-0 m-0 ms-4 fs-8pt f-titillium fw-600">Criado em:</span>
              <span v-bg:P v-c:B class="rounded px-1 mx-1 fs-9pt f-roboto fw-300" v-if="false">
                {{moment(dados.questoes[selectedItem].dataCriacao).format("DD/MM/YYYY")}}
              </span>
            </p>
            <p class="m-0 p-0">
              <span class="p-0 m-0 fs-8pt f-titillium fw-600">Autor:</span>
              <span class="mx-1 fs-9pt f-roboto fw-300">
                {{dados.questoes[selectedItem].autor}}
              </span>
            </p>
          </v-card>
          <v-card outlined class="p-2">
            <p class="p-0 m-0 fs-8pt f-titillium fw-600">Enunciado:</p>
            <p class="p-0 mx-2 fs-10pt f-lato fw-300 text-justify">
              {{dados.questoes[selectedItem].enunciado}}
            </p>
            <p class="p-0 mx-0 mb-1 mt-2 fs-8pt f-titillium fw-600">Chave:</p>
            <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
            <p class="p-0 mx-0 mb-1 mt-2 fs-8pt f-titillium fw-600">Distratores:</p>
            <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import moment from 'moment';

export default {
  name: "listaquestoes",
  components: { readmore },
  props: [ "dados" ],
  computed: {
  },
  data(){
    return {
      loading: false,
      selectedItem: 0,
    }
  },
  watch: {
    questoes(to, from) {
      var self = this;
      //console.log("meta change");
      //self.buildMeta();
    },
  },
  mounted: function() {
    var self = this;
    //this.buildMeta();
  },
  methods:{

    buildMeta() {
      //console.log("buildMeta",this.meta.id);
      var self = this;
      self.loading = true;
      rdb.ref('/curriculo/mmc/questoes').orderByChild("descritorID").equalTo(this.meta.id).on('value',function(snapshot) {
        self.questoes = snapshotValToArray(snapshot.val());
        //console.log("self.questoes",self.questoes);
        self.loading = false;
      });
    }

  }
}
</script>

<style scoped>

</style>
