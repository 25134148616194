<template>
  <span>
    <v-btn @click="$refs.dialogmetadetalhesref.show()" class="mx-1 px-1 text-nonecase f-sanspro fw-300 fs-10pt" :color="color" text x-small>
      <i v-i:duo#eye#14 class="btn-icon-left me-0"></i>
      <span class="ms-0 me-1 f-sanspro fw-600 ms-1 fs-8pt">ver</span>
    </v-btn>

    <dialogmetadetalhes ref="dialogmetadetalhesref" :meta="meta" />

  </span>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import dialogmetadetalhes from "./dialogMetaDetalhes.vue"

export default {
  name: "btnverdetalhesmetas",
  components: { dialogmetadetalhes },
  props: {
    color: { default: "#000000", type: String },
    uc: { default: null, type: Object },
    meta: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      show: false,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
