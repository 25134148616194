<template>
  <div class="row py-0">
    <div class="col-12 col-lg-12 mt-2 text-left">
      <v-card class="mx-1 mb-3">
        <v-card-text class="border text-left text-justify pt-1 pb-2 px-2" style="max-height: 90px;">
          <div class="mb-1 py-0">
            <v-checkbox dense class="left p-0 m-0 mb-n4 me-n1" v-model="ofertadas"></v-checkbox>
            <span class="ms-0 fs-8pt fw-400 f-lato">filtrar ofertadas 2021/2</span>
          </div>
        </v-card-text>
      </v-card>
      <div v-if="loading" class="mt-0">
        <span class="fs-8pt ms-2 fw-300">carregando...</span>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
      </div>
      <v-list dense class="mx-0 px-0 pt-0 mt-0">
        <v-list-item-group v-model="ucSel" :color="area.cor">
          <div class="px-0 mx-0" v-for="(uc, index) in ucs" :key="index">
            <v-list-item class="px-3 mx-0 pb-0" @click="$emit('clickUc',uc)" style="min-height: 60px; height: 60px">
              <v-list-item-icon class="ps-0 ms-0 me-n1 mt-1">
                <div class="m-0 p-0 rounded" v-if="uc.oferta2021S2">
                  <i v-i:duo#star#14 class="absolute" :style="'top: -1px; right: 31px; color: '+hexShades('#ffe600',0.15)"></i>
                  <div class="absolute mt-0 pt-0" style="top: 2px; right: 4px; ">
                    <span class="fs-8pt fw-500 py-0 px-1" :style="'color: '+hexShades(area.cor,.1)">
                      <span class="fw-600 f-roboto" :style="'color: '+hexShades('#ffe600',0.5)">21.2</span>
                    </span>
                  </div>
                </div>
                <div class="mt-2">
                  <i v-i:duo#graduation-cap#20 class="btn-icon-left me-2"></i>
                </div>
                <div class="absolute" style="top: 34px; left: 14px;">
                  <span v-for="(area, key, index) in uc.areas" :key="key">
                    <tagarea class="me-1" :sigla="area.sigla"/>
                  </span>
                </div>
              </v-list-item-icon>
              <v-list-item-content class="ms-0 mt-2 pt-0">
                <v-list-item-title class="ms-0 mt-0 pt-0 text-left f-roboto fw-600 fs-9pt f-raleway">
                  {{uc.nome}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="m-0 p-0"></v-divider>
          </div>
        </v-list-item-group>
      </v-list>

    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import tagarea from "@/components/tagArea.vue"

export default {
  name: "ucsView",
  components: { tagarea },
  props: [ "editLock", "area", "curso" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      ucSel: "",
      ucs: [],
      filtroNome: "",
      loading: false,
      ofertadas: false,
    }
  },
  watch: {
    curso(to, from) {
      var self = this;
      //console.log("ucsView change curso");
      var vetUCs = objToArray(this.curso.ucs);
      this.ucs = [];
      this.buildUCs(vetUCs,true);
    },
    ofertadas(to, from) {
      var self = this;
      //console.log("ucsViewArea change ofertadas");
      var vetUCs = objToArray(this.curso.ucs);
      this.ucs = [];
      this.buildUCs(vetUCs,true);
    }
  },
  mounted: function() {
    var self = this;
    var vetUCs = objToArray(this.curso.ucs);
    this.ucs = [];
    this.buildUCs(vetUCs,true);
  },
  methods:{

    buildUCs(vetUCs,first) {
      var self = this;
      this.loading = false;
      vetUCs = objToArray(vetUCs);
      //console.log("vetUCs",vetUCs);
      if(vetUCs.length > 0) {
        var tpIdx = vetUCs[0].id;
        //console.log("tpIdx", tpIdx);
        vetUCs.shift();
        rdb.ref('/curriculo/ucs/'+tpIdx).once('value').then(function(snapshot) {
          var ret = snapshot.val();
          //console.log(ret);
          if(self.ofertadas) {
            if(ret.oferta2021S2) {
              self.ucs.push(ret);
              if(first) {
                self.ucSel = 0;
                self.$emit("clickUc",ret);
              }
              self.buildUCs(vetUCs,false);
              self.loading = false;
            } else {
              self.buildUCs(vetUCs,false);
            }
          } else {
            self.ucs.push(ret);
            if(first) {
              self.ucSel = 0;
              self.$emit("clickUc",ret);
            }
            self.buildUCs(vetUCs,false);
            self.loading = false;
          }
        });
      } else {
        //this.$forceupdate();
        //console.log(self.ucs);
      }
    }
  }
}
</script>

<style scoped>

</style>
