<template>
  <span class="">
    <v-btn class="m-0 p-0" icon @click="clickLike" small>
      <i class="btn-icon-left fs-12pt m-0 p-0" v-c:S v-if="!liked" v-i:ic#thumb-up#16></i>
      <i class="btn-icon-left fs-12pt m-0 p-0" v-cHex="color" v-if="liked" v-i:ic#thumb-up#16></i>
    </v-btn>
    <span class="m-0 p-0 ms-n1 f-raleway fs-9pt fw-500" v-if="likes.length==0">
      curtir
    </span>
    <v-tooltip v-if="likes.length>0" right class="p-0" color="black" v-model="showCurtidas" open-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
          <span class="m-0 ms-n1 p-0 f-raleway fs-9pt fw-500" v-if="likes.length>0">
            {{likes.length}} curtida<span v-if="likes.length>1">s</span>
          </span>
        </v-btn>
      </template>
      <div class="p-0 m-0 f-raleway fs-8pt fw-500" v-for="(like,index) in likes" :key="index" v-if="index<=10">
        <p class="m-0 p-0" v-if="index<10">{{like.usrNome}}</p>
        <p class="m-0 p-0 fs-10pt fw-700" v-if="index==10">e mais {{likes.length-10}} pessoas...</p>
      </div>
    </v-tooltip>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import moment from "moment"

export default {
  name: "likes",
  components: {},
  props: {
    color: { default: "#000000", type: String },
    uc: { default: null, type: Object },
    rdbPath: { default: "/pathNotDefined", type: String },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      likes: [],
      liked: false,
      likedId: "semID",
      showCurtidas: false,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //console.log("this.color",this.color);
    //console.log("this.rdbPath",this.rdbPath);
    rdb.ref(this.rdbPath+"/likes").on('value',function(snapshot) {
      var ret = snapshotValToArray(snapshot.val());
      //console.log("likes");
      //console.log(ret);
      self.likes = ret;
      for (var i = 0; i < ret.length; i++) {
        if(ret[i].usrID == self.userLog.id) {
          self.liked = true;
          self.likedId = ret[i].id;
          break;
        }
      }
    });
  },
  methods:{

    build() {
      var self = this;
    },

    clickLike() {
      //console.log("clickLike");
      //console.log(this.rdbPath);
      //console.log(this.user);
      //var user = faker.getRamdonRealUser();
      //console.log(user);
      if(this.liked) {
        var pathUrl = this.rdbPath+"/likes/"+this.likedId;
        //console.log(pathUrl);
        rdb.ref(pathUrl).remove();
        this.liked = false;
        this.likedId = "semID";
        //console.log("like removed!");
      } else {
        var updates = {};
        var newKey = rdb.ref(this.rdbPath+"/likes/").push().key;
        var likeObj = {
          id: newKey,
          usrNome: this.userLog.nome,
          usrID: this.userLog.id,
          createdAt: moment().format(),
        };
        updates[this.rdbPath+"/likes/"+newKey] = likeObj;
        //console.log("updates",updates);
        rdb.ref().update(updates);
      }
    }

  }
}
</script>

<style scoped>
</style>
