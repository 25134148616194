<template>
  <div class="">
    <v-list-item class="mt-0 ms-0 ps-0">
      <v-hover v-slot="{ hover }">
        <v-list-item-icon class="ms-0 me-4 px-0">
          <v-img hover max-width="50" max-height="64" class="me-0 pe-0 rounded-lg" :src="getImage">
            <v-overlay v-if="hover && getGoogleLink != ''" absolute color="#036358">
              <a class="text-decoration-none" :href="getGoogleLink" target="_blank" rel="noopener noreferrer">
                <div class="text-center fs-10pt f-roboto">
                  <v-icon class="fs-10pt">mdi-google</v-icon>
                  <p class="my-0 py-0 fs-7pt text-white" style="line-height: normal;">google books</p>
                </div>
              </a>
            </v-overlay>
          </v-img>
        </v-list-item-icon>
      </v-hover>
      <!-- GONÇALVES, Priscila de Fátima; BARRETO, Jeanine dos Santos; ZENKER, Aline Maciel; FAGUNDES, Rubem.
      Testes de software e gerência de configuração. Soluções Educacionais Integradas, 2019. E-book.
      Disponível em: https://integrada.minhabiblioteca.com.br/books/9788595029361 -->
      <v-list-item-content class="ms-0 ps-0 fs-10pt f-raleway fw-300 text-justify">
        <span>
          {{biblio.autor}}.
          <span class="fw-500">{{biblio.titulo}}. </span>
          <span class="">{{biblio.editora}}. </span>
          <p class="pb-0 mb-0 mt-1 text-left text-truncate">
            <span class="fs-9pt fw-500">Disponível em: </span>
            <a class="fs-9pt f-roboto" :href="biblio.link">{{biblio.link}}</a>
          </p>
          <p class="py-0 my-0 mt-1" :style="scolor" v-if="getGoogleLink != ''">
            <span class="fs-10pt f-lato fw-300">disponível no </span>
            <v-icon :style="scolor" class="fs-10pt">mdi-google</v-icon>
            <span class="fs-10pt f-lato fw-300">oogle books</span>
          </p>
        </span>
        <v-row>
          <v-col cols="12" class="m-0 p-0 mt-4">
            <feedcontroler ref="fcontroler" :area="area" :uc="uc" :color="area.cor" :rdbPath="'/curriculo/socialData/biblios/'+biblio.id" :key="refresh"/>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import feedcontroler from '@/views/feedsComponent/feedControler.vue'

export default {
  name: 'biblioItem',
  components: { feedcontroler },
  props: [ 'uc', 'area', 'biblio' ],
  computed: {
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    getImage: function () {
      if(this.biblio.googleObj != undefined) {
        if(this.biblio.googleObj.volumeInfo.imageLinks != undefined) {
          return this.biblio.googleObj.volumeInfo.imageLinks.smallThumbnail;
        } else {
          return "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Fbook-cover-dummy.jpg?alt=media&token=8421c096-e3e0-43d0-8be0-4d13b5225bf0";
        }
      }
      return "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Fbook-cover-dummy.jpg?alt=media&token=8421c096-e3e0-43d0-8be0-4d13b5225bf0";
    },
    getGoogleLink: function () {
      if(this.biblio.googleObj != undefined) {
        if(this.biblio.googleObj.accessInfo.accessViewStatus != "NONE") {
          return this.biblio.googleObj.accessInfo.webReaderLink;
        } else {
          return this.biblio.googleObj.volumeInfo.previewLink;
        }
      }
      return "";
    },
  },
  data() {
    return {
      refresh: 0,
    }
  },
  watch: {
    biblio(to, from) {
      var self = this;
    },
  },
  mounted() {
    var self = this;

  },
  methods: {


  }
}
</script>
