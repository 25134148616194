<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="">
      <v-col cols="12" v-if="slots[0] != slotKey">
        <v-card>
          <v-toolbar dense :color="cor.active.b">
            <v-toolbar-title>
              <span class="fs-12pt f-titillium fw-300">{{title}}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items :color="cor.active.b">
              <v-btn class="ms-0" text small @click="slotKey = slots[0]">
                <i v-i:duo#x-circle#24 class="btn-icon-left ps-1"></i>
                fechar
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div class="">
            <slot :name="slot" :dados="dados" v-if="slot == slotKey" v-for="(slot,key,index) in slots"></slot>
          </div>
        </v-card>
      </v-col>
      <slot :name="slotKey" v-if="slots[0] == slotKey"></slot>
    </v-row>
  </v-container>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "boxview",
  components: {},
  props: [ "slots", "eventToggle" ],
  computed: {},
  watch: {},
  data(){
    return {
      loading: false,
      slotKey: "",
      title: "",
      dados: { algo: "oi" },
    }
  },
  mounted: function() {
    var self = this;
    //console.log("boxView Mounted");
    self.slotKey = self.slots[0];
    //console.log("self.slotKey",self.slotKey);
    this.eventBus.$on(this.eventToggle, function(value) {
      //console.log("eventBus "+self.eventToggle+" fired!", value);
      self.slotKey = value.slotKey;
      self.title = value.title;
      self.dados = value.dados;
      //console.log("self.dados",self.dados);
      self.$forceUpdate();
    });
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
