<template>
  <div class="row" v-if="uc != null">
    <div class="col-12 col-lg-12 mb-0 pb-0">
      <div class="mt-0 mb-1 text-dark fs-12pt f-lato fw-400">
        <span :style="'color:'+area.cor">Professores</span>
      </div>
      <hr class="my-0 p-0">
    </div>

    <!-- TABS -->
    <v-tabs class="mt-2" height="28" v-model="tab" background-color="transparent" :color="area.cor">
      <v-tab class="mt-0 px-2 me-1" style="min-width: 0px;">
        <template slot:default class="px-0" >
          <span class="text-nonecase f-sanspro fs-11pt fw-400">
            <v-badge inline :color="area.cor" :content="liberados.length" v-if="liberados.length > 0">
              Liberados
            </v-badge>
            <span v-if="liberados.length ==0">Liberados</span>
          </span>
        </template>
      </v-tab>
      <v-tab class="mt-0 px-2 me-1" style="min-width: 0px;">
        <template slot:default class="px-0 mx-0" >
          <span class="text-nonecase f-sanspro fs-11pt fw-400">
            <v-btn class="text-nonecase f-sanspro fs-11pt fw-400" text small :loading="loading" :style="'color:'+area.cor">
              <v-badge inline color="error" :content="pendentes.length" v-if="pendentes.length > 0">
                Pendentes
              </v-badge>
              <span v-if="pendentes.length ==0">Pendentes</span>
            </v-btn>
          </span>
        </template>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <!-- LIBERADOS -->
      <v-tab-item>
        <div v-if="liberados.length == 0" class="col-12 col-lg-12 mt-0 pt-1">
          <v-card outlined class="mt-2">
            <v-card-text class="m-0">
              <v-alert type="info" class="m-0 text-dark fs-10pt" :color="hexTints(area.cor,70)">
                Nenhum professor encontrado.
              </v-alert>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-12 col-lg-12 mt-2 mx-0 px-0">
          <v-list two-line>
            <professoritem  v-for="(prof,idx,index) in liberados" :key="idx" :area="area" :ucSel="uc" :prof="prof" :liberadoSel="true"/>
          </v-list>
        </div>
      </v-tab-item>

      <!-- PENDENTES -->
      <v-tab-item>
        <div v-if="pendentes.length == 0" class="col-12 col-lg-12">
          <v-card outlined class="mt-0">
            <v-card-text class="">
              <v-alert type="info" class="m-0 text-dark fs-10pt" :color="hexTints(area.cor,70)">
                Nenhum professor encontrado.
              </v-alert>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-12 col-lg-12 mt-2 mx-0 px-0">
          <v-list two-line>
            <professoritem  v-for="(prof,idx,index) in pendentes" :key="idx" :area="area" :ucSel="uc" :prof="prof" :liberadoSel="false"/>
          </v-list>
        </div>
      </v-tab-item>

    </v-tabs-items>

  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import professoritem from '@/views/cursos/professores/professorItem.vue'

export default {
  name: "professoresView",
  components: { professoritem },
  props: [ "editLock", "area", "ucSel" ],
  computed: {},
  data(){
    return {
      tab: null,
      uc: null,
      liberados: [],
      pendentes: [],
      countDown: 0,
      loading: true,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      //console.log("professoresView change uc");
      this.buildProfessores();
    },
    countDown() {
      if(this.countDown <= 0) {
        //console.log("terminei");
        this.loading = false;
      }
    }
  },
  mounted: function() {
    var self = this;
    this.buildProfessores();
  },
  methods:{

    buildProfessores() {
      var self = this;
      this.uc = this.ucSel;
      //console.log("this.uc",this.uc);
      this.loading = true;
      if(this.ucSel.id != undefined) {
        rdb.ref("/curriculo/2021S2/ucsProfsRef/"+this.uc.id+"/professores").on('value',function(snap) {
          self.pendentes = [];
          self.liberados = [];
          var profsLiberados = {};
          var profs = snap.val();
          for(var idx in profs) {
            profsLiberados[idx] = true;
            rdb.ref("/usuarios/pessoas").child(idx).once('value',function(snap) {
              self.liberados.push(snap.val());
            });
          }
          //console.log("profsLiberados",profsLiberados);
          var profsPendentes = {};
          self.loading = false;
          rdb.ref("/curriculo/ofertas/2021S2").orderByChild("ucID").equalTo(self.uc.id).on('child_added',function(snap) {
            //console.log("snap.key",snap.key);
            if(profsPendentes[snap.val().profID] == undefined) {
              profsPendentes[snap.val().profID] = true;
              rdb.ref("/usuarios/pessoas").child(snap.val().profID).once('value',function(snap) {
                //console.log("profs",snap.val());
                if(!profsLiberados[snap.key]) {
                  self.pendentes.push(snap.val());
                }
              });
            }
          });
        });
      }
    },

  }
}
</script>

<style scoped>

</style>
