<template>
  <div class="m-0 p-0">
    <v-card class="mt-2 card-scroll" height="510px">
      <v-card-text class="text-left text-justify">
        <v-alert class="p-2" type="info" :color="cor.active.S" v-if="meta == undefined">
          <p class="p-0 m-0 f-sanspro">Nenhuma meta selecionada!</p>
        </v-alert>
        <v-row v-if="meta != undefined">
          <v-col cols="12" lg="12">
            <v-row>
              <v-col cols="12" class="text-right mt-1 pt-0 mb-2">
                <v-btn :color="hexShades(cor.active.S,.3)" text small class="text-nonecase" @click="$refs.dialogacordos.show(meta)">
                  <i v-i:duo#plus-circle#20 class="btn-icon-left"></i>
                  recomendar acordo
                </v-btn>
              </v-col>
            </v-row>
            <v-card class="p-0 m-0 mb-2" outlined v-if="alert">
              <v-alert class="m-0" dense elevation="2" v-model="alert" border="left" color="cor.active.I">
                <p class="m-0 p-0 ms-0 f-titillium fs-9pt fw-600">Sobre acordos:</p>
                <p v-cHex="'#9d0000'" class="mt-0 mb-0 p-0 me-2 line-height-4 f-raleway fs-10pt text-justify">
                  O objetivo do processo de recomendações para elaboração de questões da A2
                  é justamente facilitar o entendimento das metas de compreensão,
                  sob a perspectiva do que <strong>NÃO DEVE</strong> ser mencionado/citado/indicado nas questões
                  que forem elaboradas por todos os nossos professores da UC para esta meta de compreensão.
                </p>
              </v-alert>
              <v-btn @click="alert = false" class="absolute right-0 top-0 me-2" color="" icon small>
                <i v-i:duo#x-circle#20></i>
              </v-btn>
            </v-card>

            <v-alert class="p-2" type="info" :color="cor.active.S" v-if="acordos != undefined && Object.keys(acordos).length == 0">
              <p class="p-0 m-0 f-sanspro">Nenhum acordo encontrado!</p>
            </v-alert>

            <v-card class="p-1 mb-4" outlined elevation="2" v-bg:b#3 v-for="(item,key,index) in acordos" :key="index">
              <p class="m-0 p-0 ms-1 f-titillium fs-9pt fw-600">Conteúdo:</p>
              <div class="mx-1 f-lato fs-10pt text-justify">
                <readmore class="m-0 p-0" size="180" :text="item.acordo" :key="index"/>
              </div>
              <div class="mt-4 mb-1">
                <feedcontroler class="mt-n1 pt-0" ref="fcontroler" :color="cor.active.P" :rdbPath="'/curriculo/socialData/metasAcordos/'+meta.id+'/acordo/'+item.id+'/'"/>
              </div>
            </v-card>

          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <dialogrecomendaracordos ref="dialogacordos" @changeValue="novoTexto" />

  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponentNoBlock/feedControler.vue'
import dialogrecomendaracordos from './dialogRecomendarAcordos.vue'

export default {
  name: "listaacordos",
  components: { readmore, dialogrecomendaracordos, feedcontroler },
  props: [ "meta" ],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      alert: true,
      acordos: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //console.log("listaAcordos mounted");
    if(this.meta == undefined) return;
    rdb.ref('/curriculo/avaliacao/2021S2/metasAcordos/'+this.meta.id).on('value',function(snapshot) {
      var ret = snapshot.val();
      if(ret != null) {
        self.acordos = ret.acordos;
      } else {
        self.acordos = {};
      }
      //console.log("self.acordos",self.acordos);
    });
  },
  methods:{

    build() {
      var self = this;
    },

    novoTexto(texto) {
      //console.log("novoTexto",texto);
    }

  }
}
</script>

<style scoped>

.card-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
