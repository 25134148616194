<template>
  <v-list-item class="mb-0 pb-0 mt-0" style="height: 56px; min-height: 56px; max-height: 56px;" @click="$emit('empresaClick',empresa)">
    <v-list-item-avatar size="42" class="my-0 mb-0">
      <v-avatar size="42">
        <img :src="empresa.urlFotoLogo" class="rounded-circle" alt="" style="">
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content class="text-left mb-0 mt-0 p-0">
      <v-list-item-title class="m-0 p-0">
        <p class="p-0 m-0 line-height-2 text-nonecase fs-10pt f-roboto">
          {{empresa.nome}}
        </p>
      </v-list-item-title>
      <v-list-item-subtitle class="m-0 p-0">
        <p class="fs-10pt f-roboto mt-2 line-height-0">
          {{empresa.breveDescr}}
        </p>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";

export default {
  name: "listaempresaitem",
  components: {},
  props: [ "empresa" ],
  data(){
    return {
      loading: false,
      refreshKey: 0,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
  },

  methods:{

  }
}
</script>

<style scoped>

</style>
