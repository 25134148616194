<template>
  <div class="row mb-0 pt-0">

    <!-- LISTA DE CURSOS -->
    <cursoslistview :area="area" @cursoClicked="cursoClicked"/>

    <!-- TITULO -->
    <div class="col-12 text-left my-0 py-0" v-if="curso != null">
      <div class="mt-1 mb-3 py-0">
        <div :style="'color:'+hexShades(area.cor,.3)" class="me-1 left fw-700 f-roboto fs-10pt my-1">
          <i v-i:duo#graduation-cap#18></i>
          Curso:
        </div>
        <div class="ms-0 pt-1">
          <v-icon :color="area.cor" class="fs-13pt pb-1 mt-0 ms-0">fas fa-graduation-cap</v-icon>
          <span :style="'color:'+hexShades(area.cor,.3)" class="ms-2 f-raleway fs-14pt fw-500">{{curso.nome}}</span>
        </div>
      </div>
      <hr class="my-0 mt-n2 py-0">
    </div>

    <!-- LISTA DE UCS -->
    <div class="col-12 col-lg-3 mx-0 mt-1">
      <p :style="'color:'+hexShades(area.cor,.3)" class="ms-1 my-0 f-roboto fs-11pt fw-400 text-left">
        Unidades Curriculares
      </p>
      <hr class="my-0 mx-0">
      <v-card outlined class="mt-2">
        <ucsview @clickUc="clickUc" v-if="curso != null" editLock="false" :area="area" :curso="curso"/>
      </v-card>
    </div>

    <!-- PLANO DE ENSINO -->
    <div class="col-12 col-lg-9">
      <planocontainer v-if="uc != null" :ucSel="uc" :area="area"/>
    </div>

  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import cursoslistview from "@/views/cursos/cursosListView.vue"
import ucsview from "@/views/cursos/ucsView.vue"
import planocontainer from "@/views/cursos/planos/planoContainer.vue"

export default {
  name: "graduacaoView",
  props: [ "editLock", "area" ],
  components: { ucsview, planocontainer, cursoslistview },
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
  },
  data(){
    return {
      curso: null,
      cursos: [],
      cursoSel: "",
      uc: null,
    }
  },
  watch: {
    area(to, from) {
      var self = this;
      console.log("graduacaoView change area");
      self.build();
    }
  },
  mounted: function() {
    var self = this;
    console.log("graduacaoView mounted");
    self.build();
  },
  methods:{

    build() {
      var self = this;
    },

    cursoClicked(curso) {
      console.log("component cursoClicked",curso);
      this.curso = curso;
    },

    clickUc(uc) {
      console.log("clickUc");
      console.log(uc);
      this.uc = uc;
    }

  }
}
</script>

<style scoped>

</style>
