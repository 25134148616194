<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <dialogtoolbar @close="dialog = false" />
        <v-row class="mx-1 mt-1">
          <v-col cols="12" class="">
            <v-card class="mt-1 mx-0" v-if="metaObj != null" flat>
              <v-row class="pb-2">
                <v-col cols="12" lg="12" class="my-0 pb-0">
                  <v-card class="p-2 px-4" v-bg:I v-c:B>
                    <p class="p-0 m-0 fs-8pt f-titillium fw-600">Meta:</p>
                    <p class="p-0 m-0 fs-11pt f-titillium fw-300 text-justify">
                      {{metaObj.texto}}
                    </p>
                  </v-card>
                  <p class="mx-1 mt-1 mb-n4 pb-0 fs-10pt right" v-if="metaObj.conteudos != null">
                    {{Object.keys(metaObj.conteudos).length}} conteúdos
                  </p>
                </v-col>

                <!-- TABS -->
                <v-col cols="12" lg="12" class="my-0 pb-0">
                  <v-tabs class="mt-1" height="28" v-model="tab" background-color="transparent" :color="area.cor">

                    <tabblocked class="me-2" :area="area" :color="area.cor" itemName="Questões"/>

                    <v-tab class="mt-0 px-2 me-1" style="min-width: 0px;">
                      <template slot:default class="px-0" >
                        <span class="text-nonecase f-raleway fs-11pt fw-600">Conteúdos</span>
                      </template>
                    </v-tab>
                    <v-tab class="mt-0 px-2 me-1" style="min-width: 0px;">
                      <template slot:default class="px-0" >
                        <span class="text-nonecase f-raleway fs-11pt fw-600">Acordos</span>
                      </template>
                    </v-tab>

                    <v-tabs-items v-model="tab">
                      <v-tab-item  v-if="blocked">
                        <listaquestoes :metaID="metaObj.id" />
                      </v-tab-item>

                      <v-tab-item>
                        <listaconteudos :metaID="metaObj.id" />
                      </v-tab-item>

                      <v-tab-item>
                        <listaacordos :metaID="metaObj.id" />
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </v-col>

              </v-row>

            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>


</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import listaquestoes from "./listaQuestoes.vue"
import dialogtoolbar from "./dialogToolBar.vue"
import listaconteudos from "./listaConteudos.vue"
import listaacordos from "./listaAcordos.vue"
import tabblocked from "./tabBlocked.vue"

export default {
  name: "dialogmetadetalhes",
  components: { readmore, tabblocked, listaquestoes, dialogtoolbar, listaconteudos, listaacordos },
  props: {
    color: { default: "#000000", type: String },
    area: { default: function() { return { cor: "#303030" }; }, type: Object },
    uc: { default: null, type: Object },
    meta: { default: null, type: Object },
    dados: { default: null, type: Object },
  },
  computed: {
    blocked() {
      var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
      return (this.isUserAdmin || gestores.indexOf(this.$store.state.user.cargo) != -1);
    }
  },
  data(){
    return {
      tab: null,
      loading: false,
      dialog: false,
      metaObj: null,
    }
  },
  watch: {
    meta(to, from) {
      var self = this;
      this.metaObj = this.meta;
      //console.log("meta change");
      //self.buildMeta();
    },
  },
  mounted: function() {
    var self = this;
    this.metaObj = this.meta;
    //this.buildMeta();
  },
  methods:{

    show(meta) {
      console.log("show");
      this.metaObj = this.meta;
      if(meta != undefined) {
        this.metaObj = meta;
      }
      console.log("this.meta",this.metaObj);
      this.buildMeta();
      this.dialog = true;
    },

    buildMeta() {
      //console.log("buildMeta",this.meta.id);
      var self = this;
      self.loading = true;
      rdb.ref('/curriculo/questoesValidas').orderByChild("metaID").equalTo(this.metaObj.id).on('value',function(snapshot) {
        self.questoes = snapshotValToArray(snapshot.val());
        console.log("self.questoes",self.questoes);
        self.loading = false;
      });
    }

  }
}
</script>

<style scoped>

</style>
