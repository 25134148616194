<template>
  <div class="row">
    <div v-if="metas.length > 0" class="">
      <div class="mb-0" v-for="(meta,key,index) in metas" :key="index">
        <v-card class="p-2 mt-2" v-bg:B outlined>
          <v-btn class="absolute right-0 me-2 text-nonecase" color="" x-small text @click="$refs.dialogmetadetalhesref.show(meta)">
            <span>ver detalhes</span>
          </v-btn>
          <p class="m-0 p-0 fs-8pt f-roboto fw-400">Meta de compreensão:</p>
          <p class="me-1 mt-n1 mb-n1 p-0 f-raleway fs-10pt fw-300">
            <readmore class="mt-0" size="300" :text="meta.texto" />
          </p>
          <div class="mt-4 mb-1">
            <feedcontroler class="mt-n1 pt-0" ref="fcontroler" :color="cor.active.P" :rdbPath="'/curriculo/socialData/metasA2/'+meta.id+'/'"/>
          </div>
        </v-card>
      </div>
    </div>
    <dialogmetadetalhes ref="dialogmetadetalhesref" />

  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import forma2indicameta from "@/views/cursos/avaliacao/formA2indicaMeta.vue"
import feedcontroler from '@/views/feedsComponentNoBlock/feedControler.vue'
import a2tooltipprofsmeta from '@/views/cursos/avaliacao/a2toolTipProfsMeta.vue'
import boxview from '@/components/boxView.vue'
import metabox from './metaBox.vue'
import votosindicador from './votosIndicador.vue'
import dialogmetadetalhes from "@/views/cursos/avaliacao/metaDetalhes/dialogMetaDetalhesA2.vue"

export default {
  name: "a2listametas",
  components: { readmore, a2tooltipprofsmeta, votosindicador, feedcontroler, dialogmetadetalhes },
  props: [ "uc", "metas", "totalIndicacoes" ],
  computed: {},
  data(){
    return {
      tab: null,
    }
  },
  watch: {
    uc(to, from) {
      var self = this;
      //console.log("listaMetas change uc",self.uc);
      //this.buildMetas();
    }
  },
  mounted: function() {
    var self = this;
    //console.log("this.uc",this.uc);
    //console.log("this.metas",this.metas);

  },
  methods:{

    buildMetas() {
      var self = this;
      //console.log("buildMetas");
      //console.log("this.uc",this.uc);

    },

    questoesClick(meta) {
      //console.log("questoesClick");
      var self = this;

      rdb.ref('/curriculo/mmc/questoes').orderByChild("descritorID").equalTo(meta.codMeta).on('value',function(snapshot) {
        var questoes = snapshotValToArray(snapshot.val());
        console.log("questoes",questoes);
        self.eventBus.$emit("toggleMeta",{
          slotKey: "second",
          title: "Lista de itens",
          dados: {
            meta: meta,
            questoes: questoes
          }
        });
      });

    }


  }
}
</script>

<style scoped>

</style>
