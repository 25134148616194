<template>
  <div class="row" v-if="uc != null">
    <div class="col-12 col-lg-12 mb-0 pb-0">
      <div class="mt-0 mb-1 text-dark fs-12pt f-lato fw-400">
        <span :style="scolor">Bibliografia</span>
      </div>
      <hr class="my-0 p-0">
    </div>

    <div class="col-12 col-lg-12 mt-n3 mb-0 pb-0">
      <div class="mt-0 mb-0 text-dark fs-12pt f-lato fw-400">
        <v-list class="mx-0 mt-0 pt-0">
            <v-subheader :style="scolor" class="f-monserrat fw-300 fs-12pt ms-0 ps-0 my-0 py-0 mb-n2">
              BÁSICA
            </v-subheader>
            <v-divider class="my-0 ps-0"></v-divider>
            <template v-for="(biblio,index) in bibliosBas">
              <biblioitem :area="area" :biblio="biblio" />
              <!-- <v-divider class="my-0 ps-0"></v-divider> -->
            </template>

            <v-subheader :style="scolor" class="f-monserrat fw-300 fs-12pt ms-0 ps-0 my-0 py-0 mb-n2">
              COMPLEMENTAR
            </v-subheader>
            <v-divider class="my-0 ps-0"></v-divider>
            <template v-for="(biblio,index) in bibliosCom">
              <biblioitem :uc="uc" :area="area" :biblio="biblio" />
              <!-- <v-divider class="my-0 ps-0"></v-divider> -->
            </template>

        </v-list>
      </div>
    </div>

  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import biblioitem from "@/views/cursos/planos/biblioItem.vue"

export default {
  name: "bibliosView",
  components: { readmore, biblioitem },
  props: [ "editLock", "area", "ucSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      uc: null,
      bibliosBas: [],
      bibliosCom: [],
      items: [
        { header: 'BÁSICA' },
        { divider: true, inset: false },
        {
          avatar: 'http://books.google.com/books/content?id=jquqlFwU1ioC&printsec=frontcover&img=1&zoom=5&source=gbs_api',
          title: 'Brunch this weekend?',
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { header: 'COMPLEMENTAR' },
        { divider: true, inset: false },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Oui oui',
          subtitle: '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
      ],
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      console.log("bibliosView change uc");
      this.preBuildBiblios();
    }
  },
  mounted: function() {
    var self = this;
    console.log("bibliosView mounted");
    this.preBuildBiblios();
  },
  methods:{

    preBuildBiblios() {
      var self = this;
      this.uc = this.ucSel;
      this.bibliosBas = [];
      this.bibliosCom = [];
      if(this.uc.biblios == undefined) return;
      var vetBibliosBas = objToArray(this.uc.biblios.basica);
      var vetBibliosCom = objToArray(this.uc.biblios.complementar);
      console.log(vetBibliosBas);
      console.log(vetBibliosCom);
      this.buildBiblios(vetBibliosBas,this.bibliosBas);
      this.buildBiblios(vetBibliosCom,this.bibliosCom);
    },

    buildBiblios(vetBiblios,srcVet) {
      var self = this;
      if(vetBiblios.length > 0) {
        var tpIdx = vetBiblios[0];
        //console.log("tpIdx");
        //console.log(tpIdx);
        vetBiblios.shift();
        self.buildBiblios(vetBiblios,srcVet);
        //console.log('/biblios/'+tpIdx);
        rdb.ref('/biblios/'+tpIdx).once('value').then(function(snapshot) {
          var ret = snapshot.val();
          //console.log(ret);
          srcVet.push(ret);
          self.buildBiblios(vetBiblios,srcVet);
        });
      } else {
        console.log(srcVet);
      }
    }

  }
}
</script>

<style scoped>

</style>
