<template>
  <v-timeline-item fill-dot :color="cor.active.S" v-c:K>
    <template v-slot:icon>
      <v-avatar :color="hexTints(cor.active.V,0.4)">
        <span v-i:ic#rocket#26 class="pb-1"></span>
      </v-avatar>
    </template>
    <v-row class="pt-1">
      <v-col cols="12" md="6" class="pt0 mt0">
        <p class="my-0 py-0 f-lato fs-10pt fw-500 line-height-2">
          <i v-i:ic#star#14 class="left"></i>
          <span class="left pt-1 mt-0 ms-1">Possimus delectus temporibus</span>
        </p>
        <p class="clear my-0 py-0 f-raleway fs-10pt fw-500">
          Planejamento Acadêmico
        </p>
        <p class="my0 f-roboto fs-9pt fw-400" v-c:V>
          Concluído em 20 de Abril de 2021
        </p>
      </v-col>
      <v-col cols="12" md="6" class="pt-0 mt-0">
        <v-card class="" plain :color="cor.active.b">
          <v-card-text class="py-1 px-1">
            <div class="my0 f-roboto fs-8pt fw-300" v-c:K>
              <p class="m-0 p-0">
                <span class="fw-400">Responsável:</span>
                <span class="ms-1 fs-10pt fw-500">Gestão de área</span>
              </p>
              <p class="m-0 p-0">
                <dummy attr="lorem.sentence"/>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-timeline-item>

</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'

export default {
  name: "historicoitem",
  components: { },
  props: [ "area", "empresa" ],
  computed: {},
  data(){
    return {
      tab: null,
      refresh: 0,
    }
  },
  watch: {
  },
  mounted: function() {
    var self = this;
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
