<template>
  <v-row class="m-0 p-0">
    <v-col cols="12" class="m-0 p-0">
      <v-row class="m-0 py-0">
        <v-col cols="12" lg="12" class="m-0 p-0">
          <div class="" style="width: 100%; height: 110px;">
            <div class="absolute" style="left: 1.5%; top: 12px; width: 97.5%">
              <progressiveimage :height="110" :src="area.urlFotoCapa"/>
            </div>
            <div class="absolute" style="top: 92px; right: 32px; z-index: 99;" v-if="isUserAdmin || isGestores">
              <v-btn @click="$refs.reffileupload.upLoad()" class="py-0 me-1 text-nonecase f-raleway fw-300 fs-7pt" x-small v-c:K v-bg:B#6>
                <span class="me-1 btn-icon-left" v-i:duo#image#12 v-c:K></span>
                Alterar capa
              </v-btn>
              <fileupload ref="reffileupload" path="/capa" @urlUpload="changeCapa" accept="image/*" />
            </div>
            <!-- <img :src="area.urlFotoCapa" class="" style="width: 100%; max-height: 110px;" alt=""> -->
            <div class="absolute" style="top: 30px; left: 40px; z-index: 1;">
              <v-avatar size="60" :color="cor.active.B">
                <iconarea :sigla="area.sigla" />
              </v-avatar>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="12" class="m-0 p-0">
          <v-card outlined class="m-0 p-1 px-3">
            <v-container fluid class="m-0 p-0">
              <v-row dense align="center" justify="start">
                <v-col cols="auto" class="">
                  <tagarea class="" :sigla="area.sigla" />
                </v-col>
                <v-col cols="9" lg="auto">
                  <div class="text-truncate ps-1 mt-2 f-roboto fw-700 fs-12pt" :style="'color: '+area.cor">
                    {{area.nome}}
                  </div>
                </v-col>
                <v-col cols="12" lg="auto" class="ps-2">
                  <div class="ps-2 mb-n1 fs-9pt f-sanspro fw-400" :style="'color: '+area.cor">Gestores: </div>
                  <div class="mt-1 ps-2">
                    <avataronline v-for="(user,key,index) in responsaveis" :key="index" :userID="user.id" :size="32"/>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-container fluid class="">
        <v-row dense class="p-0 m-0">
          <v-col cols="12" class="m-0 p-0">
            <v-container fluid class="m-0 p-0">
              <v-tabs class="" show-arrows height="26" v-model="tab" background-color="transparent" :color="area.cor">
                <v-tab class="me-2 px-1" v-for="item in tabItems" :key="item" style="min-width: 0px;">
                  <template slot:default class="px-0" >
                    <span class="text-nonecase f-raleway fs-10pt fw-600">
                      {{ item }}
                    </span>
                  </template>
                </v-tab>
                <tabblocked :area="area" :color="area.cor" itemName="Entregas A2"/>
                <tabblocked :area="area" :color="area.cor" itemName="Indicações A2"/>
                <!-- <tabblocked :area="area" :color="area.cor" itemName="Ampliação Catálogo Pós"/> -->
              </v-tabs>
            </v-container>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-row v-bg:b class="">
                  <v-col cols="12" lg="8" class="mt-3">
                    <v-row>
                      <v-col cols="12" v-for="(item,index) in relatos" :key="index">
                        <postrelato :relato="item" :key="refresh+index"/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="4" class="mt-3">
                    <datasproximas />
                    <cardevento />
                    <cardevento />
                    <cardevento />
                    <cardevento />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <ucsarea v-if="area != undefined" editLock="false" :area="area"/>
              </v-tab-item>

              <v-tab-item>
                <v-card outlined class="mt-0" elevation="4">
                  <v-card-text>
                    <cursosview v-if="area != undefined" editLock="false" :area="area"/>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <appcalendario route="professores"/>
              </v-tab-item>

              <v-tab-item v-bg:b class="p-0 m-0">
                <v-toolbar :color="cor.active.B">
                  <v-toolbar-title class="my-0 ps-1 ms-1 f-sanspro fw-400 fs-11pt">
                    <p class="p-0 m-0 mt-1 fs-12pt">Entregas - A2 - 2021/2</p>
                    <p class="p-0 m-0 fw-600" v-c:D>ACOMPANHAMENTO</p>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-row class="mt-0 pb-3">
                  <v-col cols="12" md="6" lg="4">
                    <areacard v-if="area != undefined" :key="area.id" :area="area" @areaClick="areaClick" />
                  </v-col>
                </v-row>
                <areadetalhes ref="areadetalhesref" />
              </v-tab-item>

              <v-tab-item>
                <acompanhaareanodialog v-if="area != undefined" :area="area" />
              </v-tab-item>

              <!--
              <v-tab-item>
              <ampliacaocatalogo v-if="area != undefined" :area="area" />
            </v-tab-item> -->

            <v-tab-item>
              <v-card outlined class="mt-4" style="min-height: 400px;">
                <v-card-text>
                  <v-alert type="info" class="text-dark" :color="hexTints(area.cor,.95)">
                    Funcionalidade em construção.
                  </v-alert>
                  <!-- <pessoasview editLock="false" :area="area"/> -->
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card class="p-0">
                <v-alert type="info" class="text-dark" :color="hexTints(area.cor,.95)">
                  Funcionalidade em construção.
                </v-alert>
                <!-- <empresasareaview editLock="false" :area="area"/> -->
              </v-card>
            </v-tab-item>


            <v-tab-item>
              <v-card outlined class="mt-4" style="height: 400px;">
                <v-card-text>
                  <v-alert type="info" class="text-dark" :color="hexTints(area.cor,.95)">
                    Funcionalidade em construção.
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>

        </v-col>
      </v-row>
    </v-container>
  </v-col>
</v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import cardevento from "@/components/duaiscomponents/cardEvento.vue"
import pessoasview from "@/views/area/pessoas/pessoasView.vue"
import cursosview from "@/views/cursos/cursosView.vue"
//import deparaview from "@/views/depara/deparaView.vue"
import iconarea from "@/views/area/iconArea.vue"
import tagarea from "@/views/area/pessoas/tagArea.vue"
//import calendarioareaview from "@/views/calendarios/calendarioAreaView.vue"
import empresasareaview from "@/views/empresas/empresasAreaView.vue"
import ucsarea from "@/views/cursos/ucsArea.vue"
//import postrelato from '@/views/relatos/postRelato.vue'
import datasproximas from '@/views/home/datasProximas.vue'
import appcalendario from "@/views/appCalendario/appCalendario2.vue"
import fileupload from '@/components/fileloading/fileupload.vue'
import progressiveimage from "@/components/progressive-image/progressive-image.vue"
import tabblocked from "./tabBlocked.vue"
import acompanhaareanodialog from "@/views/avaliacao/acompanhamento/acompanha-areaNoDialog.vue"
import areacard from "@/views/avaliacao/encomendas/acompanhamento/area-card.vue";

export default {
  name: "AreaView",
  components: {
    areacard, acompanhaareanodialog, tabblocked, dummy, progressiveimage, fileupload, datasproximas, ucsarea, cardevento, pessoasview, cursosview, empresasareaview, appcalendario, iconarea, tagarea,
    'postrelato': () => import('@/views/relatos/postRelato.vue'),
    'areadetalhes': () => import('@/views/avaliacao/encomendas/acompanhamento/area-detalhes.vue'),
    //'ampliacaocatalogo': () => import('@/views/cursos/pos/ampliacao/ampliacao-catalogo.vue'),
    'postrelato': () => import('@/views/relatos/postRelato.vue'),
    'avataronline': () => import('@/components/avatar-online.vue'),
  },
  props: ['editLock'],
  computed: {},
  data(){
    return {
      tab: null,
      tabItems: [ "Área", "Unidades Curriculares", "Cursos", "Calendário" ],
      //tabItems: [ "Área", "Unidades Curriculares", "Cursos", "Pessoas", "Empresas/Duais", "Calendário", "Ofertas" ],
      equipeArea: [],
      cards: [
        { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 12 },
        { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 12 },
      ],
      area: {
        cor: "#ffffff",
        sigla: "",
        nome: "",
        urlIcone: "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg",
        urlFotoCapa: "",
        equipe: [],
      },
      responsaveis: [],
      relatos: [],
      refresh: 0,
    }
  },
  watch: {
    $route(to, from) {
      var self = this;
      self.buildArea();
    }
  },
  mounted: function() {
    var self = this;
    var tpIdx = this.$route.params.id;
    //console.log("Area Mounted")
    //console.log(tpIdx)
    this.equipeArea.push(this.userLog);
    this.equipeArea.push(this.userLog);
    this.equipeArea.push(this.userLog);

    eventBus.$on("atualizaAreaView", function() {
      var tpIdx = self.$route.params.id;
      rdb.ref('/curriculo/areas/'+tpIdx).once('value').then(function(snapshot) {
        var ret = snapshot.val();
        //console.log(ret);
        self.area = ret;
        self.$forceUpdate();
      });
    });

    //console.log("userLog",this.userLog);
    self.buildArea();

    rdb.ref('/curriculo/relatos').on('value', function(snapshot) {
      self.relatos = self.shuffleArray(snapshotValToArray(snapshot.val()));
      //console.log("self.relatos",self.relatos);
    });

  },

  methods:{

    shuffleArray(arr) {
      // Loop em todos os elementos
      for (let i = arr.length - 1; i > 0; i--) {
        // Escolhendo elemento aleatório
        const j = Math.floor(Math.random() * (i + 1));
        // Reposicionando elemento
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      // Retornando array com aleatoriedade
      return arr;
    },

    buildArea() {
      var self = this;
      //console.log("change route");
      self.relatos = self.shuffleArray(self.relatos);
      self.refresh++;
      var tpIdx = self.$route.params.id;
      rdb.ref('/curriculo/areas/'+tpIdx).once('value').then(function(snapshot) {
        var ret = snapshot.val();
        self.area = ret;
        //console.log("self.area",self.area);
        self.responsaveis = [];
        rdb.ref('/usuarios/pessoas').orderByChild("cargo").equalTo("Gestor(a) de Área").on('value', function(snapshot) {
          var ret = snapshot.val();
          for(var key in ret) {
            for(var area in ret[key].areasIC) {
              if(ret[key].areasIC[area].id == self.area.id) {
                self.responsaveis.push(ret[key]);
              }
            }
          }
          self.$forceUpdate();
        });
        rdb.ref('/usuarios/pessoas').orderByChild("cargo").equalTo("Assessor(a) de Área").on('value', function(snapshot) {
          var ret = snapshot.val();
          for(var key in ret) {
            for(var area in ret[key].areasIC) {
              if(ret[key].areasIC[area].id == self.area.id) {
                self.responsaveis.push(ret[key]);
              }
            }
          }
          self.$forceUpdate();
        });
        rdb.ref('/usuarios/pessoas').orderByChild("cargo").equalTo("Coordenador(a) Regional").on('value', function(snapshot) {
          var ret = snapshot.val();
          for(var key in ret) {
            for(var area in ret[key].areasIC) {
              if(ret[key].areasIC[area].id == self.area.id) {
                self.responsaveis.push(ret[key]);
              }
            }
          }
          self.$forceUpdate();
        });
      });
    },

    EmpresaClick(item) {
      //console.log("EmpresaClick",this.editLock);
      //console.log(item);
    },

    editarArea() {
      //console.log(this.area);
      this.$router.push({ name: 'CadArea', params: { id: this.area.id } });
    },

    changeCapa(url) {
      var self = this;
      //console.log("changeCapa",url);
      var updates = {};
      updates["/curriculo/areas/"+this.area.id+"/urlFotoCapa"] = url;
      //console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Capa alterada com sucesso!");
      //console.log("agora já elvis!");
      rdb.ref('/curriculo/areas/'+this.area.id).once('value').then(function(snapshot) {
        var ret = snapshot.val();
        //console.log(ret);
        self.area = ret;
        self.$forceUpdate();
      });

    },

    areaClick(area) {
      var self = this;
      this.$refs.areadetalhesref.show(area)
    }


  }
}
</script>

<style scoped>

</style>
