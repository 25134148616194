<template>
  <div class="col-12 text-left mb-0 pb-0">
    <span v-for="(curso, i) in cursos" :key="i">
      <p class="ms-0 my-0 py-0 fs-8pt" v-if="curso.tipo=='Bacharelado' && curso.first">BACHARELADO</p>
      <p class="ms-0 my-0 py-0 fs-8pt" v-if="curso.tipo=='Tecnologia' && curso.first">CST</p>
      <v-btn class="me-2 mb-2 py-3" :color="area.cor" :style="i==cursoSel ? 'background-color:'+corSelect : ''" elevation="3" :text="i!=cursoSel" outlined x-small @click="clickCurso(i)">
        <i v-i:duo#graduation-cap#20 class="btn-icon-left me-2"></i>
        <span class="fs-8pt f-roboto fw-400 text-dark">{{curso.nome}}</span>
      </v-btn>
    </span>
    <hr class="mb-0 pb-0">
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'

export default {
  name: "cursosListView",
  components: { },
  props: [ "editLock", "area", "ucsLoading" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      cursos: [],
      cursoSel: 0,
      corSelect: "",
    }
  },
  watch: {
    area(to, from) {
      var self = this;
      console.log("cursosListView change area");
      this.buildCursos();
    }
  },
  mounted: function() {
    var self = this;
    console.log("cursosListView mounted");
    this.buildCursos();
  },
  methods:{

    buildCursos() {
      var self = this;
      this.cursos = [];
      this.cursoSel = 0;
      this.corSelect = this.hexTints(this.area.cor,90);
      rdb.ref('/curriculo/cursos').on('value', function(snapshot) {
        var retAll = snapshot.val();
        console.log(retAll);
        var ret = [];
        for(var key in retAll) {
          if(retAll[key].area.id == self.area.id) {
            ret.push(retAll[key]);
          }
        }
        console.log(ret);
        var vetCursos = [];
        var bFirst = false;
        for (var i = 0; i < ret.length; i++) {
          if(ret[i].tipo == "Bacharelado") {
            if(!bFirst) { ret[i].first = true; bFirst = true; }
            vetCursos.push(ret[i]);
          }
        }
        var cFirst = false;
        for (var i = 0; i < ret.length; i++) {
          if(ret[i].tipo == "Tecnologia") {
            if(!cFirst) { ret[i].first = true; cFirst = true; }
            vetCursos.push(ret[i]);
          }
        }
        self.cursos = vetCursos;
        self.cursoSel = 0;
        self.clickCurso(0);
      });
    },

    clickCurso(idx) {
      console.log(idx);
      this.cursoSel = idx;
      this.$emit("cursoClicked",this.cursos[idx]);
    },

  }
}
</script>

<style scoped>

</style>
