<template>
  <div class="text-center">
    <hr class="mt-1 mb-2">
    <div class="row">
      <div class="col-12 col-lg-3">
        <div class="" style="">
          <pie-chart :chartData="widgetChart"></pie-chart>
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-5">
        <div class="card pb-0 text-start">
          <p class="mb-0 mt-2 mx-2 text-start f-raleway fs-11pt">Coordenadores Específicos:</p>
          <hr class="mx-2 mt-0 mb-2">
          <p v-if="areas[siglaArea] != undefined" class="mt-1 mb-2 ms-2" style="font-size: 9pt;">
            <b-icon icon="circle-fill" :style="'color: '+areas[siglaArea].cor"></b-icon>
            {{areas[siglaArea].sigla}}
            <span class="mt-0 mb-0 ms-3" style="font-size: 9pt;">
              {{grandesAreas[parseInt(grandeArea)][siglaArea].porc}}%
            </span>
          </p>
        </div>
        <div class="card pt-2 pb-0 text-start">
          <p class="mb-0 mt-2 mx-2 text-start f-raleway fs-11pt">Coordenadores Compartilhados:</p>
          <hr class="mx-2 mt-0 mb-2">
          <div class="row">
            <div class="col-12 col-lg-4" v-for="elem in grandesAreas[parseInt(grandeArea)]" v-if="elem.id != areas[siglaArea].sigla">
              <div v-if="areas[siglaArea] != undefined" class="mt-1 mb-1 ms-2" style="font-size: 9pt;">
                <div style="width: 60px; float: left;">
                  <b-icon icon="circle-fill" :style="'color: '+elem.cor"></b-icon>
                  {{elem.id}}
                </div>
                <span class="mt-0 mb-0 ms-1" style="font-size: 9pt;">{{elem.porc}}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 mt-3">
        <div class="row mb-0 pb-0" @click="">
          <div class="col-12 pb-0">
            <div class="card p-2 mx-3">
              <div class="profile-overview pb-0">
                <p class="mb-1 f-raleway">Coordenadores de CURSOS</p>
                <hr class="m-0 mb-2">
                <h4 class="mb-1">{{coordCurso.length}}</h4>
              </div>
            </div>
          </div>
          <div class="col-12 pb-0">
            <div class="card p-2 mx-3">
              <div class="profile-overview pb-0">
                <p class="mb-1 f-raleway">Coordenadores de ÁREA</p>
                <hr class="m-0 mb-2">
                <h4 class="mb-1">{{coordArea.length}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h4 class="text-left fs-14pt f-raleway mt-6 mb-1">Coordenadores de Área</h4>
    <hr class="mt-0 mb-3">
    <div class="row mt-3">
      <div class="col-6 col-lg-3 mb-1 mt-0" v-for="item in coordArea" v-if="regionaisSel.indexOf(item.Regional) != -1">
        <popoverinfopessoas :item="item" :siglaArea="siglaArea"/>
        <div class="" :id="item.id+siglaArea">
          <b-avatar style="margin-top: 0px; margin-bottom: 0px; border: 2px solid #ccc;" size="5rem" :src="item.urlFoto"></b-avatar>
          <p class="mt-1 mb-0 fs-8pt f-roboto fw-500">{{item.NOME}}</p>
          <p class="text-muted mt-n1 mb-0 f-roboto fs-9pt">{{item.Cargo}} - {{item.Regional}}</p>
        </div>
      </div>
    </div>
    <h4 class="text-left fs-14pt f-raleway mt-6 mb-1">Coordenadores de Cursos</h4>
    <hr class="mt-0 mb-3">
    <div class="row mt-4">
      <div class="col-6 col-lg-3 mb-1 mt-0" v-for="item in coordCurso" v-if="regionaisSel.indexOf(item.Regional) != -1">
        <popoverinfopessoas :item="item" :siglaArea="siglaArea"/>
        <div class="" :id="item.id+siglaArea">
          <b-avatar style="margin-top: 0px; margin-bottom: 0px; border: 2px solid #ccc;" size="5rem" :src="item.urlFoto"></b-avatar>
          <p class="mt-1 mb-0 fs-8pt f-roboto fw-500">{{item.NOME}}</p>
          <p class="text-muted mt-n1 mb-0 f-roboto fs-9pt">{{item.Cargo}} - {{item.Regional}}</p>
        </div>
      </div>
    </div>
    <div v-if="isEditLock" class="row mb-0 mt-0">
      <div class="col-12 text-end mb-0 mt-0">
        <button class="btn btn-link btn-sm mb-0 mt-0" style="font-size: 7pt;" @click="editar()">
          <b-icon icon="pencil-square"></b-icon>
          <span class="">Editar</span>
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { rdb, snapshotValToArray } from '@/firebasedb.js'
import pieChart from './pieChart.js'
import popoverinfopessoas from './popoverInfoPessoas'

export default {
  name: "cardCoordenadores",
  components: { pieChart, popoverinfopessoas },
  props: ['editLock', "siglaArea", "grandeArea", "regionaisSel" ],
  watch: {
    siglaArea: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.load();
    }
  },
  computed: {
    isEditLock: function () {
      console.log("card edit",this.editLock);
      return this.editLock;
    }
  },
  data(){
    return {
      pessoas: [],
      areas: [],
      gestores: [],
      regionais: [],
      assesssores: [],
      coordCurso: [],
      coordArea: [],
      widgetChart: {},
      grandesAreas: [ {},
        {}, { "ENG": { total: 0, porc: 0 }, "AU&D": { total: 0, porc: 0 }, "TI&C": { total: 0, porc: 0 }, "C&A": { total: 0, porc: 0 } }, {}
      ],

    }
  },
  mounted: function() {
    var self = this;
    console.log("cardCoordenadores Mounted");
    this.grandesAreas = [ {},
      {}, { "ENG": { total: 0, porc: 0 }, "AU&D": { total: 0, porc: 0 }, "TI&C": { total: 0, porc: 0 }, "C&A": { total: 0, porc: 0 } }, {}
    ];

    rdb.ref('/areas').on('value', function(snapshot) {
      var ret = snapshotValToArray(snapshot.val());
      //console.log(ret);
      for(var i=0; i<ret.length; i++) {
        self.areas[ret[i].sigla] = ret[i];
      }
      self.$forceUpdate();
      //console.log(self.areas);
    });

    this.load();
  },

  methods:{

    load() {
      var self = this;
      rdb.ref('/pessoas').once('value', function(snapshot) {
        var ret = snapshotValToArray(snapshot.val());
        console.log(ret);
        var pessoas = [];
        self.gestores = [];
        self.assesssores = [];
        self.regionais = [];
        self.coordCurso = [];
        self.coordArea = [];
        var qtdeCoord = 0;
        for(var i=0; i < ret.length; i++) {
          if(ret[i].Areas.indexOf(self.siglaArea) != -1) {
            var vetAreas = snapshotValToArray(self.grandesAreas[parseInt(self.grandeArea)]);
            //console.log("loop vetAreas")
            //console.log(vetAreas)
            for(var k=0; k < vetAreas.length; k++) {
              console.log(vetAreas[k]);
              if(ret[i].Areas.indexOf(vetAreas[k].id) != -1) {
                self.grandesAreas[parseInt(self.grandeArea)][vetAreas[k].id].total++;
                qtdeCoord++;
              }
            }
            if(ret[i].Cargo == "Gestor(a) de Área") {
              self.gestores.push(ret[i]);
            }
            if(ret[i].Cargo == "Assessor(a) de Área") {
              self.assesssores.push(ret[i]);
            }
            if(ret[i].Cargo == "Coordenador(a) Regional") {
              self.regionais.push(ret[i]);
            }
            if(ret[i].Cargo == "Coordenador(a) de Cursos") {
              self.coordCurso.push(ret[i]);
            }
            if(ret[i].Cargo == "Coordenador(a) de Área") {
              self.coordArea.push(ret[i]);
            }
            pessoas.push(ret[i]);
          }
        }
        console.log("GrandeArea:",self.grandeArea);
        console.log(self.grandesAreas[parseInt(self.grandeArea)]);
        self.pessoas = pessoas;
        console.log(self.pessoas);
        console.log(self.gestores);
        console.log(self.assesssores);
        console.log(self.regionais);
        console.log(self.coordCurso);
        console.log(self.coordArea);
        var vetAreas = snapshotValToArray(self.grandesAreas[parseInt(self.grandeArea)]);
        for(var k=0; k < vetAreas.length; k++) {
          var qtde = self.grandesAreas[parseInt(self.grandeArea)][vetAreas[k].id].total / qtdeCoord * 100;
          self.grandesAreas[parseInt(self.grandeArea)][vetAreas[k].id].porc = qtde.toFixed(1);
          self.grandesAreas[parseInt(self.grandeArea)][vetAreas[k].id].cor = self.areas[vetAreas[k].id].cor;
          console.log(self.grandesAreas[parseInt(self.grandeArea)][vetAreas[k].id]);
        }
        self.buildChart();
      });
    },

    editar() {
      console.log(this.empresa);
      this.$router.push({ name: 'CadEmpresa', params: { id: this.empresa.id } });
    },
    EmpresaClick(item) {
      console.log("EmpresaClick",this.editLock);
      console.log(item);
      this.$router.push({ name: 'Empresa', params: { id: item.id } });
    },

    buildChart() {
      var self = this;
      var dados = {
        //labels: ["TI&C", "AU&D", "ENG"],
        labels: [],
        datasets: [
          {
            label: 'Gráfico',
            data: [],
            backgroundColor: []
          },
        ],
      };
      var vetAreas = snapshotValToArray(self.grandesAreas[parseInt(self.grandeArea)]);
      for(var k=0; k < vetAreas.length; k++) {
        dados.labels.push(vetAreas[k].id);
        dados.datasets[0].data.push(self.grandesAreas[parseInt(self.grandeArea)][vetAreas[k].id].porc);
        dados.datasets[0].backgroundColor.push(self.areas[vetAreas[k].id].cor);
      }
      this.widgetChart = dados;
      this.$forceUpdate();
      console.log("update Charts");
      console.log(dados);
    }
  }
}
</script>

<style scoped>


.card-container {
  padding: 100px 0px;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.profile-card-1 {
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-position: center;
  padding-top: 100px;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  max-width: 300px;
}

.profile-card-1 .profile-content {
  position: relative;
  background-color: #FFF;
  padding: 70px 20px 20px 20px;
  text-align: center;
}

.profile-card-1 .profile-img {
  position: absolute;
  height: 100px;
  left: 0px;
  right: 0px;
  z-index: 10;
  top: -50px;
  transition: all 0.25s linear;
  transform-style: preserve-3d;
}

.profile-card-1 .profile-img img {
  height: 100px;
  margin: auto;
  border-radius: 50%;
  border: 5px solid #FFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.profile-card-1 .profile-name {
  font-size: 18px;
  font-weight: bold;
  color: #021830;
}

.profile-card-1 .profile-address {
  color: #777;
  font-size: 12px;
  margin: 0px 0px 15px 0px;
}

.profile-card-1 .profile-description {
  font-size: 13px;
  padding: 5px 10px;
  color: #777;
}

.profile-card-1 .profile-icons .fa {
  margin: 5px;
  color: #777;
}

.profile-card-1:hover {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
}

.profile-card-1:hover .profile-img {
  transform: rotateY(180deg);
}

.profile-card-2 {
  max-width: 300px;
  background-color: #FFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
}

.profile-card-2 img {
  transition: all linear 0.25s;
}

.profile-card-2 .profile-name {
  position: absolute;
  left: 30px;
  bottom: 70px;
  font-size: 30px;
  color: #FFF;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-icons {
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: #FFF;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-username {
  position: absolute;
  bottom: 50px;
  left: 30px;
  color: #FFF;
  font-size: 13px;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-icons .fa {
  margin: 5px;
}

.profile-card-2:hover img {
  filter: grayscale(100%);
}

.profile-card-2:hover .profile-name {
  bottom: 80px;
}

.profile-card-2:hover .profile-username {
  bottom: 60px;
}

.profile-card-2:hover .profile-icons {
  right: 40px;
}

.profile-card-3 {
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  padding: 25px 15px;
}

.profile-card-3 .profile-name {
  font-weight: bold;
  color: #21304e;
}

.profile-card-3 .profile-location {
  color: #999;
  font-size: 13px;
  font-weight: 600;
}

.profile-card-3 img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin: 10px auto;
  border-radius: 50%;
  transition: all linear 0.25s;
}

.profile-card-3 .profile-description {
  font-size: 13px;
  color: #777;
  padding: 10px;
}

.profile-card-3 .profile-icons {
  margin: 15px 0px;
}

.profile-card-3 .profile-icons .fa {
  color: #fe455a;
  margin: 0px 5px;
}

.profile-card-3:hover img {
  height: 110px;
  width: 110px;
  margin: 5px auto;
}

.profile-card-4 {
  max-width: 300px;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
}

.profile-card-4 img {
  transition: all 0.25s linear;
}

.profile-card-4 .profile-content {
  position: relative;
  padding: 15px;
  background-color: #FFF;
}

.profile-card-4 .profile-name {
  font-weight: bold;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -70px;
  color: #FFF;
  font-size: 17px;
}

.profile-card-4 .profile-name p {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1.5px;
}

.profile-card-4 .profile-description {
  color: #777;
  font-size: 12px;
  padding: 10px;
}

.profile-card-4 .profile-overview {
  padding: 15px 0px;
}

.profile-card-4 .profile-overview p {
  font-size: 10px;
  font-weight: 600;
  color: #777;
}

.profile-card-4 .profile-overview h4 {
  color: #273751;
  font-weight: bold;
}

.profile-card-4 .profile-content::before {
  content: "";
  position: absolute;
  height: 20px;
  top: -10px;
  left: 0px;
  right: 0px;
  background-color: #FFF;
  z-index: 0;
  transform: skewY(3deg);
}

.profile-card-4:hover img {
  transform: rotate(5deg) scale(1.1, 1.1);
  filter: brightness(110%);
}

.profile-card-5 {
  max-width: 300px;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  padding: 50px 15px 25px 15px;
}

.profile-card-5 img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin: 10px auto;
  border-radius: 50%;
  transition: all linear 0.25s;
  position: relative;
}

.profile-card-5::before {
  content: "";
  position: absolute;
  top: -60px;
  right: 0px;
  left: 0px;
  height: 170px;
  background-color: #4fb96e;
  transform: skewY(-20deg);
}

.profile-card-5 .profile-name {
  padding-top: 15px;
  font-weight: bold;
  color: #333;
}

.profile-card-5 .profile-designation {
  font-size: 13px;
  color: #777;
}

.profile-card-3 .profile-location {
  color: #999;
  font-size: 13px;
  font-weight: 600;
}

.profile-card-5 .profile-description {
  font-size: 13px;
  color: #777;
  padding: 10px;
}

.profile-card-5 .profile-overview {
  padding: 15px 0px;
}

.profile-card-5 .profile-overview p {
  color: #777;
  font-size: 13px;
}

.profile-card-5 .profile-overview h2 {
  font-weight: bold;
  color: #1e2832;
}

.profile-card-5 .profile-icons .fa {
  margin: 7px;
  color: #4fb96e;
}

.profile-card-5:hover img {
  transform: rotate(-5deg);
}

.profile-card-6 {
  max-width: 300px;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
}

.profile-card-6 img {
  transition: all 0.15s linear;
}

.profile-card-6 .profile-name {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #FFF;
  padding: 15px 20px;
  background: linear-gradient(140deg, rgba(0, 0, 0, 0.4) 50%, rgba(255, 255, 0, 0) 50%);
  transition: all 0.15s linear;
}

.profile-card-6 .profile-position {
  position: absolute;
  color: rgba(255, 255, 255, 0.4);
  left: 30px;
  top: 100px;
  transition: all 0.15s linear;
}

.profile-card-6 .profile-overview {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 50%, rgba(255, 255, 0, 0));
  color: #FFF;
  padding: 50px 0px 20px 0px;
  transition: all 0.15s linear;
}

.profile-card-6 .profile-overview h3 {
  font-weight: bold;
}

.profile-card-6 .profile-overview p {
  color: rgba(255, 255, 255, 0.7);
}

.profile-card-6:hover img {
  filter: brightness(80%);
}

.profile-card-6:hover .profile-name {
  padding-left: 25px;
  padding-top: 20px;
}

.profile-card-6:hover .profile-position {
  left: 40px;
}

.profile-card-6:hover .profile-overview {
  padding-bottom: 25px;
}

.profile-card-7 {
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
}

.profile-card-7 .profile-content {
  padding: 60px 30px 30px 30px;
  background-color: #FFF;
  position: relative;
}

.profile-card-7 .profile-content img {
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  top: -40px;
  border: 5px solid #FFF;
}

.profile-card-7 .profile-content .profile-name {
  position: absolute;
  font-size: 17px;
  font-weight: bold;
  top: -35px;
  left: 125px;
  color: #FFF;
}

.profile-card-7 .profile-overview {
  padding: 5px 0px;
}

.profile-card-7 .profile-overview p {
  color: #777;
  font-size: 11px;
  font-weight: 600;
}

.profile-card-7 .profile-overview h5 {
  color: #142437;
  font-weight: bold;
}

.profile-card-8 {
  background: linear-gradient(#09121c, #36445a);
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  transition: all 0.25s linear;
}

.profile-card-8 .profile-name {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 25px;
  color: #58d683;
  font-size: 17px;
  font-weight: bold;
}

.profile-card-8 .profile-designation {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50px;
  color: #FFF;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
}

.profile-card-8 .profile-icons {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 80px;
  color: rgba(255, 255, 255, 0.7);
}

.profile-card-8 .profile-icons .fa {
  margin: 5px;
}

.profile-card-8:hover {
  transform: scale(1.05, 1.05);
}

.profile-card-9 {
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  padding: 30px 15px;
  background-color: #FFF;
  transition: all 0.25s linear;
}

.profile-card-9 img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin: 10px auto;
}

.profile-card-9 .profile-name {
  font-size: 15px;
  color: #3249b9;
  font-weight: 600;
}

.profile-card-9 .profile-designation {
  font-size: 13px;
  color: #777;
}

.profile-card-9 .profile-description {
  padding: 10px;
  font-size: 13px;
  color: #777;
  margin: 15px 0px;
  background-color: #F1F2F3;
  border-radius: 5px;
}

.profile-card-9 a {
  padding: 10px 15px;
  background-color: #3249b9;
  color: #FFF;
  font-size: 11px;
  border-radius: 25px;
}

.profile-card-9:hover {
  transform: scale(1.05, 1.05);
}

.profile-card-10 {
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  padding: 30px 15px;
  background-color: #1f2124;
  color: #EEE;
}

.profile-card-10 img {
  margin: 10px auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid transparent;
  box-shadow: 0px 0px 0px 2px #64c17b;
  transition: all 0.25s linear;
}

.profile-card-10 .profile-name {
  color: #FFF;
  font-weight: bold;
  font-size: 17px;
}

.profile-card-10 .profile-location {
  font-size: 13px;
  opacity: 0.7;
}

.profile-card-10 .profile-description {
  padding: 10px;
  font-size: 13px;
}

.profile-card-10 .profile-icons .fa {
  color: #ffc75e;
  margin: 10px;
}

.profile-card-10:hover img {
  transform: scale(1.1);
}
</style>
