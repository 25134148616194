<template>
  <v-card class="m-0 p-0 mt-1" flat v-if="rdbPath != null">
    <v-toolbar class="mx-2" dense v-bg:b#3 height="30" max-height="30">
      <v-toolbar-title class="grey--text">
        <likes :uc="uc" :color="color" :rdbPath="rdbPath" :key="refresh"/>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="grey--text">
        <comments :uc="uc" :color="color" :rdbPath="rdbPath" :key="refresh+1" @comentsClick="comentsClick"/>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="grey--text">
        <btncommentblock @showComents="showComents" :uc="uc" :color="color" />
      </v-toolbar-title>
    </v-toolbar>
    <div v-if="comentsEnabled" class="mx-0 px-0 mt-4">

      <feedcoment @closeResp="closeResp" v-if="index<qtdeLista" v-for="(coment,index) in coments" :key="index" :uc="uc" :area="area" :rdbPath="rdbPath+'/coments/'+coment.id"/>
      <p class="m-0 mt-n4 mb-n2 mx-4 p-0 text-right" v-if="coments.length > qtdeLista">
        <v-btn @click="qtdeLista=qtdeLista+4" color="" class="p-0 m-0 text-nonecase fw-300 fs-8pt" text x-small>mostrar mais</v-btn>
      </p>

      <v-list-item class="mx-2 p-0 mt-4" v-if="respostaEnabled && !commentblocked">
        <v-list-item-avatar size="36">
          <v-img :src="user.urlFoto"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-fab-transition>
            <v-btn @click="addComent" class="mt-9 me-1" v-cHex="color" x-small fab right top absolute>
              <v-icon v-cHex="color">mdi-send</v-icon>
            </v-btn>
          </v-fab-transition>
          <textarea ref="txtcomment" v-model="textoComent" class="pe-13 form-control form-control-sm fs-10pt f-raleway" style="height: 44px;"></textarea>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import moment from 'moment';
import readmore from "@/components/readmore.vue"
import feedcoment from "./feedComent.vue"
import likes from "./likes.vue"
import comments from "./comments.vue"
import btncommentblock from "./btnCommentBlock.vue"

export default {
  name: 'feedcontoler',
  components: { readmore, likes, comments, btncommentblock, feedcoment },
  props: {
    color: { default: "#000000", type: String },
    uc: { default: null, type: Object },
    rdbPath: { default: "/pathNotDefined", type: String },
  },
  computed: {},
  data() {
    return {
      user: {},
      refresh: 0,
      comentsEnabled: false,
      showCurtidas: false,
      respostaEnabled: false,
      coments: [],
      textoComent: "",
      createdAt: "",
      commentblocked: false,
      qtdeLista: 4,
    }
  },
  watch: {
  },
  mounted() {
    var self = this;
    this.build();

  },
  methods: {

    build() {
      var self = this;
      this.area = this.areaSel;
      self.liked = false;
      this.comentsEnabled = false;
      self.user = self.userLog;

      if(this.rdbPath != null) {
        rdb.ref(this.rdbPath+"/likes").on('value',function(snapshot) {
          var ret = snapshotValToArray(snapshot.val());
          //console.log("likes");
          //console.log(ret);
          self.likes = ret;
          for (var i = 0; i < ret.length; i++) {
            if(ret[i].usrID == self.user.id) {
              self.liked = true;
              self.likedId = ret[i].id;
              break;
            }
          }
          self.refresh++;
        });
        rdb.ref(this.rdbPath+"/coments").on('value',function(snapshot) {
          var ret = snapshotValToArray(snapshot.val());
          //console.log("coments",ret);
          //console.log(ret);
          self.coments = ret;
          self.refresh++;
        });
        rdb.ref(this.rdbPath+"/createdAt").on('value',function(snapshot) {
          var ret = snapshot.val();
          self.createdAt = ret;
          self.refresh++;
        });
      }
    },

    closeResp() {
      //console.log("closeResp");
      this.respostaEnabled = false;
    },

    comentsClick() {
      //console.log("comentsClick");
      if(!this.comentsEnabled) {
        this.respostaEnabled = true;
        this.eventBus.$emit("closeAllComents");
      }
      this.comentsEnabled = !this.comentsEnabled;
      this.qtdeLista = 4;
    },

    showComents(focus) {
      var self = this;
      //console.log("showComents");
      this.qtdeLista = 4;
      this.eventBus.$emit("closeAllComents");
      if(self.respostaEnabled) {
        self.respostaEnabled = false;
        return;
      }
      self.respostaEnabled = true;
      if(!this.comentsEnabled) {
        this.comentsEnabled = true;
        this.eventBus.$emit("closeAllComents");
        if(focus) {
          this.$nextTick(function() {
            self.$refs.txtcomment.focus();
          });
        }
      } else {
        if(focus) {
          this.$nextTick(function() {
            self.$refs.txtcomment.focus();
          });
        } else {
          this.eventBus.$emit("closeAllComents");
          this.comentsEnabled = false;
        }
      }
    },

    showResposta(focus) {
      this.eventBus.$emit("closeAllComents");
      this.respostaEnabled = !this.respostaEnabled;
      this.textoComent = "";
    },

    addComent() {
      //console.log("addComent");
      //console.log(this.textoComent);
      var comentObj = {
        usrNome: this.user.nome,
        usrID: this.user.id,
        urlFoto: this.user.urlFoto,
        texto: this.textoComent,
        createdAt: moment().format(),
      };
      this.pushKey("coments",comentObj);
      console.log("coment pushed success!");
      this.respostaEnabled = !this.respostaEnabled;
      this.textoComent = "";
      this.comentsEnabled = true;
      this.eventBus.$emit("closeAllComents");
    },

    pushKey(path,obj) {
      var pathUrl = this.rdbPath+"/"+path+"/";
      //console.log("pushKey on: ",pathUrl);
      var newKey = rdb.ref(pathUrl).push().key;
      //console.log("put com nova chave: "+newKey);
      var itemClone = Object.assign({}, obj);
      itemClone.id = newKey;
      var updates = {};
      //console.log(pathUrl + newKey);
      updates[pathUrl + newKey] = itemClone;
      rdb.ref().update(updates);
      //console.log("pushKey success!");
    },


  }
}
</script>

<style scoped>

.form-control:focus{border-color: #eeeeee;  box-shadow: none; -webkit-box-shadow: none;}
.has-error .form-control:focus{box-shadow: none; -webkit-box-shadow: none;}

</style>
