<template>
  <div class="row py-0">
    <div class="col-12 col-lg-12 mt-2 text-left">
      <div class="py-0 mt-1 mb-n4 mx-2">
        <v-text-field v-model="filtroNome" class="py-0 my-0 fs-10pt fw-300"  solo dense>
          <template v-slot:label>
            <span class="fs-10pt fw-300">filtrar por nome</span>
          </template>
        </v-text-field>
      </div>
      <div v-if="loading" class="mt-0">
        <span class="fs-8pt ms-2 fw-300">carregando...</span>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
      </div>
      <v-list dense class="mx-0 px-0 pt-0 mt-n4">
        <v-list-item-group v-model="ucSel" :color="area.cor">
          <div class="px-0 mx-0" v-for="(uc, index) in ucs" :key="index">
            <v-list-item class="px-3 mx-0 pb-0" @click="$emit('clickUc',uc)" style="min-height: 60px; height: 60px">
              <v-list-item-icon class="ps-0 ms-0 me-n1 mt-1">
                <div class="m-0 p-0 rounded" v-if="uc.oferta2021S2">
                  <i v-i:duo#star#14 class="absolute" :style="'top: -1px; right: 31px; color: '+hexShades('#ffe600',0.15)"></i>
                  <div class="absolute mt-0 pt-0" style="top: 2px; right: 4px; ">
                    <span class="fs-8pt fw-500 py-0 px-1" :style="'color: '+hexShades(area.cor,.1)">
                      <span class="fw-600 f-roboto" :style="'color: '+hexShades('#ffe600',0.5)">21.2</span>
                    </span>
                  </div>
                </div>
                <div class="mt-2">
                  <i v-i:duo#graduation-cap#20 class="btn-icon-left me-2"></i>
                </div>
                <div class="absolute" style="top: 34px; left: 14px;">
                  <span v-for="(area, key, index) in uc.areas" :key="key">
                    <tagarea class="me-1" :sigla="area.sigla"/>
                  </span>
                </div>
              </v-list-item-icon>
              <v-list-item-content class="ms-0 mt-2 pt-0">
                <v-list-item-title class="ms-0 mt-0 pt-0 text-left f-roboto fw-600 fs-9pt f-raleway">
                  {{uc.nome}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="m-0 p-0"></v-divider>
          </div>
        </v-list-item-group>
      </v-list>

    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import tagarea from "@/components/tagArea.vue"

export default {
  name: "ucsViewArea",
  components: { tagarea },
  props: [ "especificas", "area", "curso" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      ucSel: "",
      ucs: [],
      ucsDB: {},
      filtroNome: "",
      ofertadas: true,
      loading: false,
    }
  },
  watch: {
    area(to, from) {
      var self = this;
      console.log("ucsViewArea change area");
      this.ucs = [];
      this.buildUCs();
    },
    ofertadas(to, from) {
      var self = this;
      console.log("ucsViewArea change ofertadas");
      this.ucs = [];
      this.buildUCs();
    },
    filtroNome() {
      console.log("filtroNome",this.filtroNome);
      this.buildUCs();
    }
  },
  mounted: function() {
    var self = this;
    self.loading = true;
    rdb.ref('/curriculo/ucs/').on('value',function(snapshot) {
      self.ucsDB = snapshot.val();
      console.log("self.ucsDB",self.ucsDB);
      self.buildUCs();
    });
  },
  methods:{

    buildUCs() {
      var self = this;
      var area = this.area;
      this.ucs = [];
      self.loading = true;
      console.log("area",area);
      console.log("ofertadas",self.ofertadas);
      console.log("especificas",self.especificas);
      var vetUCs = [];
      for(var key in self.ucsDB) {
        if(self.especificas) {
          var areas = self.ucsDB[key].areas;
          //console.log(Object.keys(areas).length);
          var aKey = Object.keys(areas)[0];
          if(Object.keys(areas).length == 1 && area.sigla == areas[aKey].sigla) {
            if(self.ofertadas) {
              if(self.ucsDB[key].oferta2021S2) {
                vetUCs.push(self.ucsDB[key]);
              }
            } else {
              vetUCs.push(self.ucsDB[key]);
            }
          }
        } else {
          var areas = self.ucsDB[key].areas;
          for(var aKey in areas) {
            if(area.sigla == areas[aKey].sigla) {
              if(self.ofertadas) {
                if(self.ucsDB[key].oferta2021S2) {
                  if(self.ucsDB[key].nome.toUpperCase().lastIndexOf(self.filtroNome.toUpperCase()) != -1) {
                    vetUCs.push(self.ucsDB[key]);
                  }
                }
              } else {
                if(self.ucsDB[key].nome.toUpperCase().lastIndexOf(self.filtroNome.toUpperCase()) != -1) {
                  vetUCs.push(self.ucsDB[key]);
                }
              }
            }
          }
        }
      }
      console.log("vetUCs",vetUCs);
      self.ucs = vetUCs;
      //self.$emit("selectInitialUc",self.ucs[0]);
      self.loading = false;
    }

  }
}
</script>

<style scoped>

</style>
