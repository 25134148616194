<template>
  <div class="" v-if="dados != undefined">
    <v-card class="pt-2 px-2">
      <v-row class="pb-2">
        <v-col cols="12" lg="12" class="my-0 pb-0">
          <v-card class="p-2 px-4" v-bg:I v-c:B>
            <p class="p-0 m-0 fs-8pt f-titillium fw-600">Meta:</p>
            <p class="p-0 m-0 fs-11pt f-titillium fw-300 text-justify">
              {{dados.meta.texto}} {{dados.meta.meta}}
            </p>
          </v-card>
          <p class="mx-1 mt-1 mb-n4 pb-0 fs-10pt right" v-if="dados.meta.conteudos != null">
            {{Object.keys(dados.meta.conteudos).length}} conteúdos
          </p>
        </v-col>

        <!-- TABS -->
        <v-col cols="12" lg="12" class="my-0 pb-0">
          <v-tabs class="mt-1" height="28" v-model="tab" background-color="transparent" :color="area.cor">
            <v-tab class="mt-0 px-2 me-1" v-for="item in ['Questões', 'Conteúdos', 'Acordos']" :key="item" style="min-width: 0px;">
              <template slot:default class="px-0" >
                <span class="text-nonecase f-raleway fs-11pt fw-600">{{ item }}</span>
              </template>
            </v-tab>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <listaquestoes :dados="dados" />
              </v-tab-item>
              <v-tab-item>
                <v-card outlined elevation="4" class="mt-2">

                  <v-card outlined class="p-1 my-2" v-for="(conteudo,key,index) in dados.meta.conteudos" :key="index">
                    <p class="p-0 m-0 fs-8pt f-titillium fw-600">Conteúdo:</p>
                    <p class="p-0 m-0 fs-11pt f-titillium fw-300 text-justify">
                      {{conteudo}}
                    </p>
                  </v-card>

                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card outlined elevation="4" class="mt-2">
                  <v-alert type="info" class="m-0 fs-10pt text-dark" :color="hexTints(area.cor,.7)">
                    Funcionalidade em construção.
                  </v-alert>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-col>

      </v-row>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import listaquestoes from "./listaQuestoes.vue"

export default {
  name: "metabox",
  components: { readmore, listaquestoes },
  props: [ "dados" ],
  computed: {
  },
  data(){
    return {
      tab: null,
      loading: false,
      area: { cor: "#303030" },
    }
  },
  watch: {
    questoes(to, from) {
      var self = this;
      //console.log("meta change");
      //self.buildMeta();
    },
  },
  mounted: function() {
    var self = this;
    //this.buildMeta();
  },
  methods:{

    buildMeta() {
      //console.log("buildMeta",this.meta.id);
      var self = this;
      self.loading = true;
      rdb.ref('/curriculo/mmc/questoes').orderByChild("descritorID").equalTo(this.meta.id).on('value',function(snapshot) {
        self.questoes = snapshotValToArray(snapshot.val());
        //console.log("self.questoes",self.questoes);
        self.loading = false;
      });
    }

  }
}
</script>

<style scoped>

</style>
