<template>
  <div class="row">
    <div class="col-12 col-lg-12 text-left">
      <p :style="'color:'+hexShades(area.cor,.1)" class="mt-3 mb-1 f-monserrat fs-13pt fw-500">
        Plano de ensino
      </p>
      <hr class="mt-0 mb-3">

      <!-- TABS -->
      <v-tabs height="200" class="mt-1 fs-10pt text-left" v-model="tab" background-color="transparent" vertical :color="area.cor">
        <v-tab class="" v-for="item in items" :key="item" >
          <div class="f-lato fs-9pt px-0 ms-0 fw-400" style="text-align: left; width: 100%;">{{ item }}</div>
        </v-tab>

        <v-tabs-items v-model="tab">

          <v-tab-item>
            <div class="p-2 ps-4">
              <posementaview :area="area" :nanoSel="nano" @listaDescontinuados="listaDescontinuados('ementa')"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <postopicosview :area="area" :nanoSel="nano" @listaDescontinuados="listaDescontinuados('topicos')"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <posbibliosview :area="area" :nanoSel="nano"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <professoresview :area="area" :nanoSel="nano"/>
            </div>
          </v-tab-item>

        </v-tabs-items>

      </v-tabs>


    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import posementaview from "@/views/cursos/pos/posEmentaView"
import topicosview from "@/views/cursos/planos/topicosView"
import bibliosview from "@/views/cursos/planos/bibliosView"
import professoresview from "@/views/cursos/professores/professoresView"
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"

export default {
  name: "posplanoView",
  components: { readmore, dummy, posementaview, topicosview, bibliosview, professoresview },
  props: [ "editLock", "area", "nano" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      tab: null,
      items: [ 'Ementa', 'Tópicos', 'Bibliografia', 'Professores' ],
    }
  },
  watch: {
    nano(to, from) {
      var self = this;
      console.log("posplanoView change nano");
    }
  },
  mounted: function() {
    var self = this;
  },
  methods:{

    listaDescontinuados(tipo) {
      this.$emit("listaDescontinuados",tipo);
    },

    goTab(tab) {
      this.tab = tab;
    }

  }
}
</script>

<style scoped>

</style>
