<template>
  <div class="row">
    <div class="row mt-0">
      <div class="col-12 col-lg-12 mt-0">

        <!-- TABS -->
        <v-tabs class="mt-n2" height="26" v-model="tab" background-color="transparent" :color="area.cor">
          <v-tab class="mt-0 px-2 me-1" v-for="item in items" :key="item" style="min-width: 0px;">
            <template slot:default class="px-0" >
              <span class="text-nonecase f-raleway fs-10pt fw-600">{{ item }}</span>
            </template>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">

          <!-- GRADUAÇÃO -->
          <v-tab-item>
            <v-card outlined class="mt-2">
              <v-card-text>
                <graducaoview :area="area"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- TÉCNICOS -->
          <v-tab-item>
            <v-card outlined class="mt-2" style="height: 400px;">
              <v-card-text>
                <v-alert type="info" class="text-dark" :color="hexTints(area.cor,.7)">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Pós -->
          <v-tab-item>
            <v-card outlined class="mt-2">
              <v-card-text>
                <posview :area="area"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Extensão -->
          <v-tab-item>
            <v-card outlined class="mt-2" style="height: 400px;">
              <v-card-text>
                <v-alert type="info" class="text-dark" :color="hexTints(area.cor,.7)">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </div>
    </div>

  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import graducaoview from "@/views/cursos/graduacaoView.vue"
import posview from "@/views/cursos/pos/posView.vue"

export default {
  name: "cursosView",
  props: [ "editLock", "area" ],
  components: { graducaoview, posview },
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
  },
  data(){
    return {
      tab: null,
      items: [ 'GRADUAÇÃO', 'TÉCNICOS', 'PÓS GRADUAÇÃO', 'EXTENSÃO' ],
    }
  },
  watch: {
    area(to, from) {
      var self = this;
      self.build();
    }
  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      console.log("cursosView change area");
    },

  }
}
</script>

<style scoped>

</style>
