<template>
  <div class="">
    <v-text-field v-model="filtro" color="black">
      <template v-slot:label>
        <span class="fs-10pt f-raleway">
          Será que o <strong>conteúdo</strong> já existe? Busque aqui
        </span>
        <v-icon style="vertical-align: middle">mdi-file-find</v-icon>
        <span class="fs-10pt f-raleway">
          ou selecione (lista abaixo)
        </span>
      </template>
    </v-text-field>
    <div class="mt-n2 mb-2 me-3 p-0 text-right" v-if="Object.keys(conteudosSug).length > 0">
      <v-btn outlined x-small @click="update" :color="cor.active.V">
        Confirmar recomendação
      </v-btn>
    </div>
    <v-card class="p-1 m-0 mb-4" v-if="Object.keys(conteudosSug).length == 0">
      <div class="clear m-0 p-0">
        <div class="right mt-n4 me-2 p-0">
          <v-switch v-model="optionCad" color="indigo" value="indigo" hide-details>
            <template v-slot:label class=" m-0 p-0">
              <span class="fs-10pt f-raleway">Obrigado, mas quero cadastrar um novo</span>
              <i v-i:duo#smiley#20 class="btn-icon-right"></i>
            </template>
          </v-switch>
        </div>
        <div class="clear m-0 p-0"></div>
        <div class="" v-if="optionCad">
          <v-text-field class="mb-0 mt-2 mx-2 p-0 mb-n3" dense solo label="digite" hint="Sempre procure evitar o adicionar conteúdos equivalentes aos já cadastrados" v-model="textoFiltro">
            <template v-slot:label class=" m-0 p-0">
              <span class="fs-10pt f-raleway">Digite novo conteúdo</span>
            </template>
          </v-text-field>
          <div class="text-right p-0 m-0 mt-2">
            <v-btn class="mt-n2 me-2" outlined x-small @click="toastW('Funcionalidade em discussão com gestão de área!')">
              Cadastrar e recomendar conteúdo
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <v-card class="mt-1 mx-3" outlined v-bg:b v-if="bestMatch != ''">
      <p class="m-0 p-0 ms-2 f-titillium fs-9pt fw-600">Você não quis dizer?</p>
      <v-card class="p-1 px-2 my-1 mx-2">
        <div class="m-0 p-0 f-raleway fs-9pt fw-400">
          <v-row>
            <v-col cols="10" class="text-justify">
              <span>{{bestMatch}}</span>
            </v-col>
            <v-col cols="2">
              <v-btn @click="recomendar(bestMatch)" v-if="conteudosSel[bestMatch] == undefined && conteudosSug[bestMatch] == undefined" class="right f-sanspro fw-600 fs-9pt text-nonecase px-1 py-0" v-c:I color="" text x-small>
                recomendar
              </v-btn>
              <v-tooltip right v-if="conteudosSug[bestMatch] != undefined" :key="bestMatch">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="desrecomendar(bestMatch)" small icon v-bind="attrs" v-on="on" class="right f-yanone fw-300 fs-8pt text-nonecase px-1 py-0" v-c:W color="">
                    <i v-i:ic#bookmark-added#24></i>
                  </v-btn>
                </template>
                <p class="m-0 p-0 fs-9pt f-sanspro fw-600 line-height-2 text-center">Conteúdo selecionado</p>
                <p class="m-0 p-0 fs-7pt f-sanspro fw-600 line-height-2 text-center">
                  <i v-i:ic#mouse#16></i>
                  Clique para desmarcar seleção
                </p>
              </v-tooltip>
              <v-tooltip right v-if="conteudosSel[bestMatch] != undefined">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="right f-yanone fw-300 fs-8pt text-nonecase px-1 py-0" v-c:V color="">
                    <i v-i:ic#bookmark-added#24></i>
                  </span>
                </template>
                <span class="m-0 p-0 fs-9pt f-sanspro fw-600">Conteúdo já recomendado</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-card>
    <v-card class="mt-3 mx-3 card-scroll" height="300px" outlined v-bg:b>
      <p class="m-0 p-0 ms-2 f-titillium fs-9pt fw-600">Sugestões extraidas da ementa:</p>
      <v-alert class="mx-3 my-2 p-1 px-2" type="info" color="grey" v-if="filtradas.length == 0">
        <span class="f-raleway fs-11pt">Sem sugestões</span>
      </v-alert>
      <v-card class="p-1 px-2 my-1 mx-2" v-for="(item,index) in filtradas" :key="index">
        <div class="m-0 p-0 f-raleway fs-9pt fw-400">
          <v-row>
            <v-col cols="10" class="text-justify">
              <readmore class="m-0 p-0" size="110" :text="item" :key="index"/>
            </v-col>
            <v-col cols="2">
              <v-btn :key="item" v-if="conteudosSel[item] == undefined && conteudosSug[item] == undefined" @click="recomendar(item)" class="right f-sanspro fw-600 fs-9pt text-nonecase px-1 py-0" v-c:I color="" text x-small>
                recomendar
              </v-btn>
              <v-tooltip right v-if="conteudosSug[item] != undefined" :key="item">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="desrecomendar(item)" small icon v-bind="attrs" v-on="on" class="right f-yanone fw-300 fs-8pt text-nonecase px-1 py-0" v-c:W color="">
                    <i v-i:ic#bookmark-added#24></i>
                  </v-btn>
                </template>
                <p class="m-0 p-0 fs-9pt f-sanspro fw-600 line-height-2 text-center">Conteúdo selecionado</p>
                <p class="m-0 p-0 fs-7pt f-sanspro fw-600 line-height-2 text-center">
                  <i v-i:ic#mouse#16></i>
                  Clique para desmarcar seleção
                </p>
              </v-tooltip>
              <v-tooltip right v-if="conteudosSel[item] != undefined" :key="item">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="right f-yanone fw-300 fs-8pt text-nonecase px-1 py-0" v-c:V color="">
                    <i v-i:ic#bookmark-added#24></i>
                  </span>
                </template>
                <span class="m-0 p-0 fs-9pt f-sanspro fw-600">Conteúdo já recomendado</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <div class="" v-if="outrasSugestoes.length > 0">
        <p class="m-0 p-0 ms-2 f-titillium fs-9pt fw-600">Outras sugestões:</p>
        <v-alert class="mx-3 my-2 p-1 px-2" type="info" color="grey" v-if="outrasSugestoes.length == 0">
          <span class="f-raleway fs-11pt">Sem sugestões</span>
        </v-alert>
        <v-card class="p-1 px-2 my-1 mx-2" v-for="(item,index) in outrasSugestoes" :key="index">
          <div class="m-0 p-0 f-raleway fs-9pt fw-400">
            <v-row>
              <v-col cols="10" class="text-justify">
                <readmore class="m-0 p-0" size="110" :text="item" :key="index"/>
              </v-col>
              <v-col cols="2">
                <v-btn @click="recomendar(item)" v-if="conteudosSel[item] == undefined && conteudosSug[item] == undefined" class="right f-sanspro fw-600 fs-9pt text-nonecase px-1 py-0" v-c:I color="" text x-small>
                  recomendar
                </v-btn>
                <v-tooltip right v-if="conteudosSug[item] != undefined" :key="item">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="desrecomendar(item)" small icon v-bind="attrs" v-on="on" class="right f-yanone fw-300 fs-8pt text-nonecase px-1 py-0" v-c:W color="">
                      <i v-i:ic#bookmark-added#24></i>
                    </v-btn>
                  </template>
                  <p class="m-0 p-0 fs-9pt f-sanspro fw-600 line-height-2 text-center">Conteúdo selecionado</p>
                  <p class="m-0 p-0 fs-7pt f-sanspro fw-600 line-height-2 text-center">
                    <i v-i:ic#mouse#16></i>
                    Clique para desmarcar seleção
                  </p>
                </v-tooltip>
                <v-tooltip right v-if="conteudosSel[item] != undefined">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="right f-yanone fw-300 fs-8pt text-nonecase px-1 py-0" v-c:V color="">
                      <i v-i:ic#bookmark-added#24></i>
                    </span>
                  </template>
                  <span class="m-0 p-0 fs-9pt f-sanspro fw-600">Conteúdo já recomendado</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import readmore from "@/components/readmore.vue"

export default {
  name: "sugestoesconteudos",
  components: { readmore },
  props: [ "meta" ],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      uc: null,
      optionCad: false,
      textoFiltro: "",
      filtro: "",
      sugestoes: [],
      todosConteudos: [],
      filtradas: [],
      outrasSugestoes: [],
      bestMatch: "",
      conteudosSel: {},
      conteudosSug: {},
    }
  },
  watch: {
    meta() {
      this.build();
    },
    filtro() {
      this.filtrar();
    }
  },
  mounted: function() {
    var self = this;
    //console.log("self.meta",self.meta);
    //self.conteudosSug = {};
    //self.loading = true;
    rdb.ref('/curriculo/ucs/'+self.meta.ucID).on('value',function(snapshot) {
      self.uc = snapshot.val();
      //console.log("self.uc",self.uc);
      rdb.ref('/curriculo/avaliacao/2021S2/conteudosMetas/').on('value',function(snapshot) {
        self.todosConteudos = snapshot.val();
        //console.log("self.todosConteudos",self.todosConteudos);
        rdb.ref('/curriculo/avaliacao/2021S2/metasConteudos/'+self.meta.id).on('value',function(snapshot) {
          var ret = snapshot.val();
          if(ret != null) {
            for(var key in ret.conteudos) {
              self.conteudosSel[ret.conteudos[key].conteudo.trim()] = true;
            }
          } else {
            self.conteudosSel = {};
          }
          //console.log("self.conteudosSel",self.conteudosSel);
        });
        self.build();
      });
    });
  },
  methods:{

    build() {
      var self = this;
      if(self.uc != null) {
        var ementa = self.uc.ementa.texto.trim();
        if(ementa.charAt(ementa.length-1) == ".") { ementa = ementa.substr(0,ementa.length-1); };
        var vet = ementa.split(".");
        for(var idx in vet) {
          if(vet[idx].trim() != "") {
            var texto = vet[idx].trim();
            if(texto.charAt(texto.length-1) == ";") { texto = texto.substr(0,texto.length-1); };
            var vet2 = texto.split(";");
            if(vet2.length > 1) {
              for(var idx2 in vet2) {
                if(vet2[idx2].trim() != "") {
                  self.sugestoes.push(vet2[idx2]);
                }
              }
            } else {
              self.sugestoes.push(vet[idx]);
            }
          }
        }
        //console.log("self.sugestoes",self.sugestoes);
        this.filtrar();
      }
    },

    filtrar() {
      //console.log("filtrar",this.filtro);
      if(this.filtro.trim() == "") {
        this.filtradas = this.sugestoes;
        this.outrasSugestoes = [];
        this.bestMatch = "";
        return;
      }
      this.bestMatch = "";
      this.filtradas = [];
      this.outrasSugestoes = [];
      var bestValue = 0;
      var bestText = "";
      for(var idx in this.sugestoes) {
        if(this.sugestoes[idx].toUpperCase().lastIndexOf(this.filtro.toUpperCase()) != -1) {
          var value = stringSimilarity.compareTwoStrings(this.sugestoes[idx].toUpperCase(),this.filtro.toUpperCase());
          if(value > bestValue) {
            bestValue = value;
            bestText = this.sugestoes[idx];
          }
          this.filtradas.push(this.sugestoes[idx]);
        }
      }
      if(this.filtro.length > 2) {
        for(var idx in this.todosConteudos) {
          if(this.todosConteudos[idx].conteudo.toUpperCase().lastIndexOf(this.filtro.toUpperCase()) != -1) {
            var value = stringSimilarity.compareTwoStrings(this.todosConteudos[idx].conteudo.toUpperCase(),this.filtro.toUpperCase());
            if(value > bestValue) {
              bestValue = value;
              bestText = this.todosConteudos[idx].conteudo;
            }
            this.outrasSugestoes.push(this.todosConteudos[idx].conteudo);
            //console.log(value,bestValue,bestText);
          }
        }
      }
      this.bestMatch = bestText;
    },

    recomendar(item) {
      //console.log("recomendar",item);
      this.conteudosSug[item] = true;
      //console.log("this.conteudosSug",this.conteudosSug);
      this.$forceUpdate();
    },

    desrecomendar(item) {
      //console.log("desrecomendar",item);
      delete this.conteudosSug[item];
      //console.log("this.conteudosSug",this.conteudosSug);
      this.$forceUpdate();
    },

    update() {
      var self = this;
      var updates = {}
      //console.log("update");
      //console.log("this.conteudosSug",this.conteudosSug);
      //console.log("this.todosConteudos",this.todosConteudos);
      for(var idx in this.conteudosSug) {
        var achei = false;
        for(var key in this.todosConteudos) {
            var conteudo = this.todosConteudos[key];
            if(idx == conteudo.conteudo) {
              achei = true;
              var meta = {
                id: this.meta.id,
                metaCodigo: this.meta.metaCodigo
              };
              delete conteudo.metas;
              updates["/curriculo/avaliacao/2021S2/conteudosMetas/"+conteudo.id+"/metas/"+meta.id] = meta;
              updates["/curriculo/avaliacao/2021S2/metasConteudos/"+meta.id+"/conteudos/"+conteudo.id] = conteudo;
              break;
            }
        }
        if(!achei) {
          var meta = {
            id: this.meta.id,
            metaCodigo: this.meta.metaCodigo
          };
          var newKey = rdb.ref("/curriculo/avaliacao/2021S2/conteudosMetas/"+conteudo.id).push().key;
          var conteudo = {
            id: newKey,
            conteudo: idx.trim(),
            metas: {}
          };
          conteudo.metas[meta.id] = meta;
          updates["/curriculo/avaliacao/2021S2/conteudosMetas/"+conteudo.id] = conteudo;
          updates["/curriculo/avaliacao/2021S2/metasConteudos/"+meta.id+"/conteudos/"+conteudo.id] = conteudo;
        }
      }

      //console.log("updates",updates);
      rdb.ref().update(updates);
      self.toastS("Indicação concluída com sucesso!");
      self.$emit("closeDialog");
    },

  }
}
</script>

<style scoped>

.card-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
