<template>
  <v-tab class="" style="min-width: 0px;" v-if="blocked">
    <div class="text-nonecase f-lato fs-10pt px-0 ms-0 fw-600" style="text-align: left; width: 100%;">
      <span class="text-nonecase f-lato fs-10pt px-0 ms-0 fw-600">
        {{itemName}}
        <v-tooltip bottom open-delay="600">
          <template right v-slot:activator="{ on, attrs }">
            <i v-cHex="color" class="absolute" v-i:ic#lock-open#16 v-bind="attrs" v-on="on" style="top: 1px; right: -4px;"></i>
          </template>
          <p class="m-0 mt-n1 p-0 fs-7pt f-sanspro fw-600 line-height-0 text-left">
            <i v-i:ic#lock-open#12></i>
            Funcionalidade liberada apenas p/ gestão de área
          </p>
        </v-tooltip>
      </span>
    </div>
  </v-tab>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "tabblocked",
  components: {},
  props: {
    area: { default: null, type: Object },
    color: { default: "#000000", type: String },
    itemName: { default: "item name", type: String },
    equipeID: { default: "equipeID", type: String },
  },
  computed: {
    blocked() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        if(this.userLog == undefined) return false;
        return (this.isUserAdmin || gestores.indexOf(this.userLog.cargo) != -1 && this.userLog.areasIC[this.area.id] != undefined);
      }
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      equipe: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //console.log(this.userLog);
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
