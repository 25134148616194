<template>
  <div class="row">
    <div class="col-12 col-lg-12 text-left pt-0 mx-0 px-1">

      <v-alert v-if="!uc.oferta2021S2" type="info" class="fs-10pt fw-500 mt-2 mb-0 text-dark" :color="hexTints(area.cor,.7)">
        Unidade Curricular SEM OFERTA em 2021/2.
      </v-alert>

      <!-- TABS -->
      <v-tabs v-if="uc.oferta2021S2" height="28" class="mt-0 fs-10pt text-left" v-model="tab" background-color="transparent" :color="area.cor">
        <v-tab class="" v-for="item in ['Planejamento', 'A1', 'A2', 'A3', 'AI']" :key="item" style="min-width: 0px;">
          <template slot:default class="px-0">
            <span class="text-nonecase f-lato fs-11pt fw-600">{{item}}</span>
          </template>
        </v-tab>

        <v-tabs-items v-model="tab">

          <v-tab-item>
            <v-card class="p-2 mt-2" outlined v-bg:b>
              <v-card>
                <atividadetimeline />
              </v-card>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card outlined class="mt-2">
              <v-card-text>
                <v-alert type="info" class="fs-10pt text-dark" :color="hexTints(area.cor,.7)">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card outlined class="mt-2">
              <v-card-text>
                <a2view :area="area" :ucSel="uc" :key="refresh"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card outlined class="mt-2">
              <v-card-text>
                <v-alert type="info" class="fs-10pt text-dark" :color="hexTints(area.cor,.7)">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card outlined class="mt-2">
              <v-card-text>
                <v-alert type="info" class="fs-10pt text-dark" :color="hexTints(area.cor,.7)">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>

      </v-tabs>


    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import a2view from "@/views/cursos/avaliacao/a2view.vue"
import atividadetimeline from "@/views/avaliacao/atividadeTimeLine.vue"

export default {
  name: "avaliacaoview",
  components: { readmore, dummy, a2view, atividadetimeline },
  props: [ "area", "uc" ],
  computed: {},
  data(){
    return {
      tab: null,
      refresh: 0,
    }
  },
  watch: {
    uc(to, from) {
      var self = this;
      self.refresh++;
      //console.log("Avaliação change uc");
    }
  },
  mounted: function() {
    var self = this;
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
