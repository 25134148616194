<template>
  <span>
    <v-btn :color="cor.active.S" x-small @click="click1">
      <i class="btn-icon-left" v-i:duo#upload#16></i>
      Upload
    </v-btn>
    <input type="file" ref="input1" style="display: none" @change="previewImage" accept="image/*" >
  </span>
</template>

<script>
import { fbst } from '@/firebasedb.js'

export default {
  props: [ "path", "refreshModel" ],
  data () {
    return {
      progressUpload: 0,
      file: File,
      imageData: '',
      caption : '',
      img1: null,
      uploadValue: 0,
    }
  },
  methods: {
    create () {
      console.log(this.caption)
    },
    click1() {
      this.$refs.input1.click()
    },

    previewImage(event) {
      this.uploadValue=0;
      this.img1=null;
      this.imageData = event.target.files[0];
      this.onUpload()
    },

    onUpload(){
      var self = this;
      this.img1=null;
      const storageRef = fbst.ref(this.path+"/"+`${this.imageData.name}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.img1 = url;
          this.$emit('urlUpload', url, this.refreshModel);
          console.log(this.img1)
        });
      }
    );
  },
}
}
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
</style>
