<template>
  <div class="row" v-if="uc != null">
    <div class="col-12 col-lg-12 mb-0 pb-0">
      <div class="mt-0 mb-1 text-dark fs-12pt f-lato fw-400">
        <span :style="scolor">Professores</span>
      </div>
      <hr class="my-0 p-0">
    </div>

    <!-- TABS -->
    <v-tabs class="mt-0" v-model="tab" background-color="transparent" grow :color="area.cor">
      <v-tab class="mt-0">
        <span class="f-roboto fw-300">
          <v-badge inline :style="scolor" :content="professores.length" v-if="professores.length > 0">
            LIBERADOS
          </v-badge>
          <span v-if="professores.length ==0">LIBERADOS</span>
        </span>
      </v-tab>
      <v-tab class="mt-0">
        <span class="f-roboto fw-300">
          <v-badge inline color="error" :content="pendentes.length" v-if="pendentes.length > 0">
            PENDENTES
          </v-badge>
          <span v-if="pendentes.length ==0">PENDENTES</span>
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <!-- LIBERADOS -->
      <v-tab-item>
        <div v-if="professores.length == 0" class="col-12 col-lg-12 mt-0 pt-1">
          <v-card outlined class="mt-2">
            <v-card-text class="m-0">
              <v-alert type="info" class="m-0 text-dark fs-10pt" :color="hexTints(area.cor,70)">
                Nenhum professor encontrado.
              </v-alert>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-12 col-lg-12 mt-2 mx-0 px-0">
          <v-list two-line>
            <professoritem  v-for="(prof,idx,index) in professores" :key="idx" :area="area" :ucSel="uc" :prof="prof" :liberadoSel="true"/>
          </v-list>
        </div>
      </v-tab-item>

      <!-- PENDENTES -->
      <v-tab-item>
        <div v-if="pendentes.length == 0" class="col-12 col-lg-12">
          <v-card outlined class="mt-0">
            <v-card-text class="">
              <v-alert type="info" class="m-0 text-dark fs-10pt" :color="hexTints(area.cor,70)">
                Nenhum professor encontrado.
              </v-alert>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-12 col-lg-12 mt-2 mx-0 px-0">
          <v-list two-line>
            <professoritem  v-for="(prof,idx,index) in pendentes" :key="idx" :area="area" :ucSel="uc" :prof="prof" :liberadoSel="false"/>
          </v-list>
        </div>
      </v-tab-item>

    </v-tabs-items>

  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import professoritem from '@/views/cursos/professores/professorItem.vue'

export default {
  name: "professoresView",
  components: { professoritem },
  props: [ "editLock", "area", "ucSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      tab: null,
      uc: null,
      professores: [],
      pendentes: [],
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      console.log("professoresView change uc");
      this.buildProfessores();
    }
  },
  mounted: function() {
    var self = this;
    this.buildProfessores();
  },
  methods:{

    buildProfessores() {
      var self = this;
      this.uc = this.ucSel;

      if(this.ucSel.id != undefined) {
        rdb.ref("/ucs/"+this.ucSel.id+"/professores").on('value',function(snapshot) {
          var ret = snapshotValToArray(snapshot.val());
          self.professores = [];
          self.pendentes = [];
          for (var i = 0; i < ret.length; i++) {
            if(ret[i].liberado) {
              rdb.ref("/pessoas/"+ret[i].id).once('value',function(snapshot) {
                var professor = snapshot.val();
                self.professores.push(professor);
                self.professores = self.professores.sort((a,b) => (a.NOME > b.NOME) ? 1 : ((b.NOME > a.NOME) ? -1 : 0))
              });
            } else {
              rdb.ref("/pessoas/"+ret[i].id).once('value',function(snapshot) {
                var professor = snapshot.val();
                self.pendentes.push(professor);
                self.pendentes = self.pendentes.sort((a,b) => (a.NOME > b.NOME) ? 1 : ((b.NOME > a.NOME) ? -1 : 0))
              });
            }
          }
          console.log(self.professores);
        });
      }
    },

  }
}
</script>

<style scoped>

</style>
