<template>
  <v-card elevation="4" outlined class="mb-0 pb-4" v-if="topico != null">
    <v-card-text class="text-left text-justify pb-4">
      <div class="row pb-0 pt-0">
        <div class="col-12 col-lg-12 px-0 py-0 my-0 fs-10pt">
          <v-card outlined height="22px" v-bg:b#9 v-if="(isGestores || isUserAdmin) && (topico.nova != undefined || topico.texto.trim() == 'não encontrada')">
            <div class="right p-0 m-0 line-height-1">
              <v-btn v-c:D class="" text x-small @click="$refs.promptdialogref.show(topico)">
                <i v-i:duo#x-circle#14 class="" style="margin-top: -3px; margin-right: 1px;"></i>
                <span class="text-nonecase f-roboto fw-300 fs-8pt">remover tópico</span>
                <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
              </v-btn>
            </div>
          </v-card>
          <descontinuarbtn v-if="false" :areaSel="area" fieldTitle="Tópico" @descontinuar="descontinuar"/>

          <div class="clear mx-2">
            <readmore class="text-dark mt-0 fs-10pt f-raleway fw-400" size="200" :text="topico.texto" />
            <v-row>
              <v-col cols="12" class="m-0 p-0 mt-3">
                <div class="m-0 me-3 mt-0 p-0" v-if="topico.createdAt != undefined" :style="'color:'+hexShades(area.cor,.6)">
                  <div class="right f-raleway fs-8pt fw-300">atualizado {{$moment(topico.createdAt).fromNow()}}</div>
                  <div v-i:duo#clock#12 class="right me-1 mt-n1 pt-0" :style="'color:'+hexShades(area.cor,.6)"></div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="m-0 p-0 mt-1">
                <feedcontroler ref="fcontroler" :area="area" :uc="ucSel" :color="area.cor" :rdbPath="topicoPath+'/'+topico.id" :key="refresh"/>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-card-text>

    <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />

  </v-card>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponent/feedControler.vue'
import descontinuarbtn from '@/views/cursos/planos/descontinuarBtn.vue'
import moment from 'moment';
import promptdialog from "./promptdialog.vue"
import spanblocked from "./spanBlocked.vue"

export default {
  name: "metaItem",
  components: { readmore, feedcontroler, descontinuarbtn, promptdialog, spanblocked },
  props: [ "areaSel", "ucSel", "topicoSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      topicoPath: null,
      topico: null,
      uc: null,
      area: null,
      refresh: 0,
    }
  },
  watch: {
    topicoSel(to, from) {
      var self = this;
      //console.log("metaItem change meta");
      this.buildTopico();
    },
    ucSel(to, from) {
      var self = this;
      //console.log("metaItem change uc");
      this.buildTopico();
    },
    areaSel(to, from) {
      var self = this;
      //console.log("metaItem change area");
      this.buildTopico();
    }
  },
  mounted: function() {
    var self = this;
    this.buildTopico();
  },
  methods:{

    buildTopico() {
      var self = this;
      this.uc = this.ucSel;
      this.area = this.areaSel;
      var topicoPath = "/curriculo/socialData/ucs/"+this.uc.id+"/topicos/";
      //console.log(topicoPath);
      this.topicoPath = topicoPath;

      if(this.topicoSel.id != undefined) {
        //console.log("/ucs/"+this.ucSel.id+"/topicos/"+this.topicoSel.id);
        rdb.ref("/curriculo/ucs/"+this.ucSel.id+"/topicos/"+this.topicoSel.id).on('value',function(snapshot) {
          self.topico = snapshot.val();
          if(self.topico != null) {
            self.topico.id = self.topicoSel.id;
          }
        });
      }

    },

    descontinuar(novoTexto) {
      //console.log("novoTexto");
      //console.log(novoTexto);
      //console.log(this.topico);
      var pathUrl = "/curriculo/ucs/"+this.uc.id+"/topicosDescontinuados/";
      var newKey = rdb.ref(pathUrl).push().key;
      pathUrl = pathUrl + newKey;
      var itemObj = Object.assign({}, this.topico);
      itemObj.emProducao = false;
      itemObj.id = newKey;
      var itemNew = {
        createdAt: moment().format(),
        emProducao: true,
        texto: novoTexto,
        id: this.topico.id,
      };
      //console.log("pushKey on: ",pathUrl);
      //console.log("itemObj",itemObj);
      //console.log("pushKey on: ","/curriculo/ucs/"+this.uc.id+"/topicos/"+this.topico.id);
      //console.log("itemNew",itemNew);
      var updates = {};
      updates[pathUrl] = itemObj;
      updates["/curriculo/ucs/"+this.uc.id+"/topicos/"+this.topico.id] = itemNew;
      rdb.ref().update(updates);
      //console.log("topicos atualizadas");
      this.topico = itemNew;
    },

    confirmaRemover() {
      //console.log("confirmaRemover tópico",this.topico);
      //console.log("confirmaRemover uc",this.uc);
      var updates = {};
      updates["/curriculo/ucs/"+this.uc.id+"/topicos/"+this.topico.id] = null;
      //console.log("updates",updates);
      rdb.ref().update(updates);
      this.toastS("Tópico Gerador removido com sucesso!");
    },



  }
}
</script>

<style scoped>

</style>
