<template>
  <v-autocomplete class="mt-0 mb-n4 pt-0 pb-n1" dense solo v-model="selecionados" :items="empresas" chips color="blue-grey lighten-2" item-text="nome" item-value="id" multiple>
    <template v-slot:label>
      <span class="fw-300 fs-11pt">pesquisar por nome</span>
    </template>
    <template v-slot:selection="data">
      <v-chip class="my-1 py-0" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
        <v-avatar left size="20">
          <v-img :src="data.item.urlFotoLogo"></v-img>
        </v-avatar>
        <span class="fw-300 f-raleway fs-8pt">{{ data.item.nome.substring(0,14) }}</span>
      </v-chip>
    </template>
    <template v-slot:item="data">
      <div class="my-0 py-0" style="max-height: 10px;">
        <v-avatar class="left my-0 py-0" size="26">
          <img :src="data.item.urlFotoLogo">
        </v-avatar>
        <p class="left fw-300 f-raleway fs-10pt ms-2 mt-1">
          {{data.item.nome}}
        </p>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";

export default {
  name: "selectusers",
  components: { },
  props: [],
  data() {
    return {
      loading: false,
      selecionados: [],
      isUpdating: false,
      name: '',
      empresas: [],
    }
  },
  computed: {
  },
  watch: {
    selecionados() {
      this.$emit("changesel",this.selecionados);
    }
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    this.selecionados = [];
    rdb.ref('/empresas').on('value', function(snapshot) {
      self.empresas = snapshotValToArray(snapshot.val());
      self.loading = false;
      self.log("carregado!");
    });
  },

  methods:{

    remove (item) {
      console.log("item",item);
      console.log("this.selecionados",this.selecionados);
      let pos = -1;
      for(var key in this.selecionados) {
        console.log("this.selecionados[key]",this.selecionados[key]);
        console.log("item.id",item.id);
        if(this.selecionados[key] == item.id) {
          pos = key;
          break;
        }
      }
      if (pos >= 0) {
        this.selecionados.splice(pos, 1);
      }
      console.log("this.selecionados",this.selecionados);
    },

  }
}
</script>

<style scoped>

</style>
