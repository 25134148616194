<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar :color="cor.active.b">
          <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
            Recomendar metas de compreensão - A2
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="f-sanspro fw-600 fs-12pt" v-if="uc != null">
            <div class="">
              <div class="m-0 p-0 mt-0">
                <i v-i:duo#graduation-cap#20 class="btn-icon-left me-1"></i>
                <span class="ms-0 mt-0 pt-0 text-left f-roboto fw-600 fs-11pt f-raleway">
                  {{uc.nome}}
                </span>
              </div>
              <div class="m-0 mt-n1 p-0" style="">
                <span v-for="(area, key, index) in uc.areas" :key="key">
                  <tagarea class="me-1" :sigla="area.sigla"/>
                </span>
              </div>
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="f-sanspro fw-600 fs-12pt">
            <div class="">
              <div class="m-0 p-0 mt-n2">
                <v-avatar size="34" class="mt-2">
                  <v-img :src="userLog.urlFoto"></v-img>
                </v-avatar>
                <span class="ms-0 mt-0 pt-0 text-left f-sanspro fw-600 fs-10pt f-raleway">
                  {{userLog.nome}}
                </span>
              </div>
              <div class="p-0" style="margin-left: 38px; margin-top: -20px;">
                <span class="ms-0 mt-0 pt-0 text-left f-roboto fw-200 fs-10pt f-raleway">
                  {{userLog.cargo}} - {{userLog.ies}} - {{userLog.regional}}
                </span>
              </div>
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined small @click="update">
            Confirmar recomendação
          </v-btn>
        </v-toolbar>

        <v-row class="mx-1 mt-1">
          <v-col cols="12" sm="3" class="">
            <v-card class="mt-1 mx-0" v-if="uc != null" flat>
              <v-card-title class="my-0 py-1 fs-9pt" v-c:I v-bg:S#2>
                <span class="fw-500 fs-11pt f-sanspro">Indique metas de compreensão</span>
                <v-badge v-if="metas.length > 0" :color="hexShades(cor.active.S,0.2)" class="ms-2" inline :content="metas.length"></v-badge>
              </v-card-title>
              <v-divider class="mt-0 mb-1 py-0"></v-divider>
              <v-card-text class="px-0">
                <v-row>
                  <v-col cols="12" class="my-0 py-0">
                    <v-card class="card-scroll" height="540px">
                      <v-list dense>
                        <div class="m-0 p-0" v-for="(meta,index) in metas" :key="index">
                          <div class="right" style="margin-top: auto;">
                            <v-btn icon color="" small @click="selecionarMeta(index)">
                              <i v-i:ic#arrow-right#40 v-cHex="'#79768a'"></i>
                            </v-btn>
                          </div>
                          <v-list-item class="m-0 p-0">
                            <template v-slot:default="{ active }">
                              <v-card flat class="m-1 ms-1" v-bg:B#3>
                                <div class="mx-0 px-1 f-lato fs-10pt text-justify">
                                  <readmore class="m-0 p-0" size="120" :text="meta.texto" :key="index"/>
                                </div>
                              </v-card>
                            </template>
                          </v-list-item>
                          <v-divider class="mx-0 mt-0 mb-2 p-0"></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="3" class="">
            <v-card class="mt-1 mx-0" v-if="uc != null" flat>
              <v-card-title class="my-0 py-1 fs-11pt" v-c:P v-bg:S#4>
                <span class="fw-500 fs-11pt f-sanspro">Metas de Compreensão sugeridas</span>
                <v-badge v-if="metasSel.length > 0" :color="hexShades(cor.active.S,0.5)" class="ms-2" inline :content="metasSel.length"></v-badge>
              </v-card-title>
              <v-divider class="mt-0 mb-1 py-0"></v-divider>
              <v-card-text  class="px-0">
                <v-row>
                  <v-col cols="12" class="my-0 py-0">
                    <v-card class="card-scroll" height="540px">
                      <v-list dense>
                        <v-list-item-group v-model="metaIndex" :color="cor.active.P">
                          <div class="m-0 p-0" v-for="(meta,index) in metasSel" :key="index">
                            <div class="left" style="margin-top: auto;">
                              <v-btn icon color="" small @click="naoSelecionarMeta(index)">
                                <i v-i:ic#arrow-left#40 v-cHex="'#79768a'"></i>
                              </v-btn>
                            </div>
                            <v-list-item class="m-0 p-0">
                              <template v-slot:default="{ active }">
                                <v-card flat class="m-1 ms-1" v-bg:B#3>
                                  <div class="mx-0 px-1 f-lato fs-10pt text-justify">
                                    <readmore class="m-0 p-0" size="120" :text="meta.texto" :key="index"/>
                                  </div>
                                </v-card>
                              </template>
                            </v-list-item>
                            <v-divider class="mx-0 mt-0 mb-2 p-0"></v-divider>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <painelconteudos :meta="metasSel[metaIndex]" :key="refresh+metaIndex"/>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/components/tagArea.vue"
import readmore from "@/components/readmore.vue"
import painelconteudos from "./conteudos/painelConteudos.vue"

export default {
  name: "recomendarmeda",
  components: { tagarea, readmore, painelconteudos },
  props: [],
  computed: {},
  data(){
    return {
      dialog: false,
      loading: false,
      refresh: 0,
      uc: null,
      metaIndex: 0,
      metas: {},
      metasSel: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
  },
  methods:{

    build() {
      var self = this;
    },

    show(uc) {
      var self = this;
      //console.log("show",uc);
      this.dialog = true;
      this.uc = uc;
      //console.log("this.uc",this.uc);

      rdb.ref('/curriculo/metas').orderByChild('ucID').equalTo(this.uc.id).once('value', function(snapshot) {
        var todasMetas = snapshot.val();
        var metasSel = {};
        var metas = {};
        rdb.ref('/curriculo/avaliacao/2021S2/ucs/'+self.uc.id+"/metasIndicacoes/"+self.userLog.id).once('value').then(function(snapshot) {
          var ret = snapshot.val();
          //console.log("showRet",ret);
          if(ret == null) {
            self.metas = snapshotValToArray(todasMetas);
            self.metasSel = [];
          } else {
            for(var idx in todasMetas) {
              for(var idx2 in ret.metasIndicadas) {
                if(todasMetas[idx].id == ret.metasIndicadas[idx2].id) {
                  metasSel[idx] = todasMetas[idx];
                } else {
                  metas[idx] = todasMetas[idx];
                }
              }
            }
            self.metas = snapshotValToArray(metas);
            self.metasSel = snapshotValToArray(metasSel);
            ///console.log("self.metas",self.metas);
            //console.log("self.metasSel",self.metasSel);
          }
        });
      });

    },

    selecionarMeta(index) {
      var self = this;
      //console.log("selecionarMeta",index);
      var meta = self.metas[index];
      self.metas.splice(index, 1);
      self.metasSel.push(meta);
      self.metaIndex = 0;
      self.refresh++;
      self.$forceUpdate();
    },

    naoSelecionarMeta(index) {
      var self = this;
      //console.log("naoSelecionarMeta",index);
      var meta = self.metasSel[index];
      self.metasSel.splice(index, 1);
      self.metas.push(meta);
      self.metaIndex = 0;
      self.refresh++;
      self.$forceUpdate();
    },

    update() {
      var self = this;
      if(this.metasSel.length == 0) {
        this.toastD("Nenhuma meta selecionada!");
        return;
      }
      //console.log("this.metasSel",this.metasSel);
      //console.log("this.metas",this.metas);
      //console.log("this.uc",this.uc);
      //console.log("this.userLog",this.userLog);

      var metasIndicacoes = {
        id: this.userLog.id,
        professorID: this.userLog.id,
        professor: {
          id: this.userLog.id,
          nome: this.userLog.nome,
          urlFoto: this.userLog.urlFoto
        },
        metasIndicadas: {}
      }
      for(var idx in this.metasSel) {
        var meta = this.metasSel[idx];
        delete meta.coments;
        delete meta.likes;
        delete meta.votos;
        delete meta.qtdeVotos;
        metasIndicacoes.metasIndicadas[meta.id] = meta;
      }

      rdb.ref('/curriculo/avaliacao/2021S2/ucs/'+this.uc.id).once('value').then(function(snapshot) {
        var ret = snapshot.val();
        var uc;
        var updates = {};
        if(ret == null) {
          uc = {
            id: self.uc.id,
            areas: self.uc.areas,
            codigo: self.uc.codigo,
            nome: self.uc.nome,
            metasIndicacoes: {},
          }
          uc.metasIndicacoes[self.userLog.id] = metasIndicacoes;
          updates["/curriculo/avaliacao/2021S2/ucs/"+uc.id] = uc;
        } else {
          uc = ret;
          updates["/curriculo/avaliacao/2021S2/ucs/"+uc.id+"/metasIndicacoes/"+self.userLog.id] = metasIndicacoes;
        }
        //console.log(updates);
        rdb.ref().update(updates);
        self.toastS("Indicação concluída com sucesso!");
      });
      this.dialog = false;
      //this.$emit("update",{ formData: this.formData, tipo: this.btnName } );
    }



  }
}
</script>

<style scoped>

.card-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
