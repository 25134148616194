<template>
  <div class="row mt-1">
    <div class="col-12 col-lg-12 text-left">
      <v-card>
        <v-fab-transition>
          <v-btn fab top right absolute x-small class="mt-6 me-n2" :style="scolor" @click="$emit('close')">
            <i class="btn-icon-left ps-1" v-i:duo#x-circle#20></i>
          </v-btn>
        </v-fab-transition>
        <v-card-text class="text-left text-justify">
          <div class="mt-1 mb-1 text-dark fs-13pt f-lato fw-400">
            <span :style="scolor">
              <v-badge bordered color="area.cor" :content="descontinuados.length" inline>
                Competências Descontinuadas
              </v-badge>
            </span>
          </div>
          <hr class="mt-0 mb-4 p-0">
          <div class="row">
            <div class="col-12 col-lg-12">

              <v-card class="pb-0 mb-4" v-for="(competencia, index) in descontinuados" :key="index">
                <v-card-text class="pb-0 mb-0 text-left text-justify">
                  <div class="row pb-0 pt-0 pb-0 mb-0">
                    <div class="col-12 col-lg-12 pt-1 pb-0 mb-0 fs-10pt">
                      <readmore class="text-dark mt-0 f-raleway fw-400" size="300" :text="competencia.texto" />
                      <feedcontroler ref="fcontroler" :areaSel="area" :rdbPathProp="'/ucs/'+uc.id+'/competenciasDescontinuadas/'+competencia.id+'/'"/>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray, snapshotValToArraySort } from '@/firebasedb.js'
import dummy from "@/components/dummy/dummy.vue"
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feeds/feedControler.vue'

export default {
  name: "descontinuadoscompetencia",
  components: { dummy, readmore, feedcontroler },
  props: [ "editLock", "area", "ucSel" ],
  computed: {
    bordercolor: function() { return 'border: 1px solid '+this.area.cor+';'; },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolorn50: function() { return 'color:'+this.hexTints(this.area.cor,-50)+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      descontinuados: [],
      uc: null,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      self.build();
    },
  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      console.log("descontinuados build...");
      var self = this;
      self.uc = self.ucSel;
      console.log(self.uc);
      rdb.ref('/curriculo/ucs/'+self.uc.id+'/competenciasDescontinuadas').on('value',function(snapshot) {
        self.descontinuados = snapshotValToArraySort(snapshot.val(),"createdAt",true);
      });
    }

  }
}
</script>

<style scoped>

</style>
