import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ['options','chartData'],
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    console.log("chartData")
    console.log(this.chartData)
    this.renderChart(this.chartData, {
      aspectRatio: 2,
      layout: {
          padding: 2,
      },
      tooltips: {
          mode: 'nearest',
          intersect: false,
      },
      responsive: true,
      legend: {
          position: 'top',
      },
      animation: {
          animateScale: true,
          animateRotate: true
      }
    });
  }
}
