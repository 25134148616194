<template>
  <div class="row">
    <div class="col-12 col-lg-12 text-left pt-0 mx-0 px-1">

      <boxview :slots="['first','second']" eventToggle="toggleMeta">
        <template v-slot:first>
          <v-card class="pt-0 mt-1" v-bg:b v-if="ucSel != null">
            <v-card-title class="mt-2 py-0 px-3" :style="'height: 30px; background-color: '+hexTints(area.cor,.9)">
              <template slot:default class="">
                <span class="f-roboto fw-400 fs-10pt">Metas de compreensão indicadas para A2</span>
                <span v-if="metasVet.length > 0" class="ms-4 f-lato fw-400 fs-9pt" :style="'color: '+hexTints(area.cor,.1)">
                  <a2tooltipprofsmeta :uc="ucSel" :metaSel="{ votos: profsVet }" />
                </span>
                <span class="ms-1 fw-400 fs-10pt"></span>
                <v-spacer></v-spacer>
                <v-btn v-if="false && blockIndicacao" :color="hexShades(cor.active.D,.1)" text small class="text-nonecase f-roboto fs-9pt" @click="$refs.indicarmetaref.show(ucSel)">
                  <i v-i:duo#note#16 class="btn-icon-left" v-c:D></i>
                  indicar metas A2
                  <spanblocked :area="area" equipeID="gestores" :color="cor.active.D" icon />
                </v-btn>
                <v-btn v-if="false" :color="hexShades(area.cor,.3)" text small class="text-nonecase f-roboto fs-9pt" @click="$refs.recomendarmetaref.show(ucSel)">
                  <i v-i:duo#plus-circle#16 class="btn-icon-left"></i>
                  recomendar metas
                </v-btn>
              </template>
            </v-card-title>
            <v-card-text class="text-left text-justify mt-2">
              <v-alert v-if="metasVet.length == 0" type="error" class="fs-10pt text-dark" :color="hexTints(cor.active.S,.5)">
                Nenhuma Meta de Compreensão indicada!
              </v-alert>
              <a2listametas :uc="ucSel" :metas="metasVet" :totalIndicacoes="profsVet.length"/>
            </v-card-text>
          </v-card>
        </template>
        <!-- SECOND -->
        <template v-slot:second="props">
          <metabox :dados="props.dados" />
        </template>
      </boxview>

      <recomendarmeta ref="recomendarmetaref" />
      <indicarmeta ref="indicarmetaref" />
    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import recomendarmeta from "./recomendarMeta.vue"
import indicarmeta from "./indicarMeta.vue"
import feedcontroler from '@/views/feeds/feedControler.vue'
import a2tooltipprofsmeta from '@/views/cursos/avaliacao/a2toolTipProfsMeta.vue'
import boxview from '@/components/boxView.vue'
import metabox from './metaBox.vue'
//import a2listametas from "./a2ListaMetas.vue"
import a2listametas from "./a2ListaMetasIndicadas.vue"
import btnverdetalhesmeta from "./metaDetalhes/btnVerDetalhesMeta.vue"
import spanblocked from "./spanBlocked.vue";

export default {
  name: "a2view",
  components: { readmore, spanblocked, btnverdetalhesmeta, boxview, metabox, recomendarmeta, indicarmeta, a2listametas, feedcontroler, a2tooltipprofsmeta },
  props: [ "area", "ucSel" ],
  computed: {
    blocked() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        return (this.isUserAdmin || gestores.indexOf(this.$store.state.user.cargo) != -1 && this.$store.state.user.areasIC[this.area.id] != undefined);
      }
      return false;
    },
    blockIndicacao() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        if(this.isUserAdmin || gestores.indexOf(this.$store.state.user.cargo) != -1 && this.$store.state.user.areasIC[this.area.id] != undefined) {
          return true;
        } else {
          if(this.profsRef[this.userLog.id] != undefined) {
            return true;
          }
        }
      }
      return false;
    }
  },
  data(){
    return {
      tab: null,
      metas: {},
      metasVet: [],
      metasTodosProfs: [],
      totalIndicacoes: 0,
      feedPath: "/curriculo/avaliacao/2021S2/ucs/",
      ucMetas: {},
      profs: {},
      profsVet: [],
      profsRef: {},
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      //console.log("Avaliação change uc",self.ucSel);
      this.buildMetas();
    }
  },
  mounted: function() {
    var self = this;
    //console.log("this.ucSel",this.ucSel);
    rdb.ref('/curriculo/metas').orderByChild('ucID').equalTo(this.ucSel.id).on('value', function(snapshot) {
      self.ucMetas = snapshot.val();
      //console.log("self.ucMetas",self.ucMetas);
    });
    this.buildMetas();
  },
  methods:{

    buildMetas() {
      var self = this;
      //console.log("buildMetas");
      //console.log("this.ucSel",this.ucSel);

      rdb.ref("/curriculo/avaliacao/2021S2/metasIndicadasFinal").orderByChild("ucID").equalTo(this.ucSel.id).on('value',function(snapshot) {
        self.metas = snapshot.val();
        self.metasVet = snapshotValToArray(self.metas);
        //console.log("self.metas",self.metas);
      });

      return;

      rdb.ref("/curriculo/2021S2/ucsProfsRef/"+this.ucSel.id+"/professores").on('value',function(snapshot) {
        self.profsRef = snapshot.val();
      });

      rdb.ref('/curriculo/avaliacao/2021S2/ucs/'+this.ucSel.id).on('value', function(snapshot) {
        var ucRef = snapshot.val();
        console.log("ucRef",ucRef);
        if(ucRef != null) {
          var indicacoes = ucRef.metasIndicacoes;
          self.profs = {};
          self.metas = {};
          for(var key in indicacoes) {
            self.profs[key] = indicacoes[key].professor;
            var metas = indicacoes[key].metasIndicadas;
            for(var metaKey in metas) {
              if(self.metas[metaKey] == undefined) {
                self.metas[metaKey] = metas[metaKey];
                self.metas[metaKey].votos = [ indicacoes[key].professor ];
                self.metas[metaKey].qtdeVotos = 1;
              } else {
                self.metas[metaKey].votos.push(indicacoes[key].professor);
                self.metas[metaKey].qtdeVotos++;
              }
            }
          }
          self.profsVet = objToArray(self.profs);
          //console.log("self.profs",self.profs);
          //console.log("self.profsVet",self.profsVet);
          self.metasVet = objToArray(self.metas);
          //console.log("self.metas",self.metas);
          //console.log("self.metasVet",self.metasVet);

          for(var key in self.metas) {
            self.metas[key].questoes = [];
            rdb.ref('/curriculo/questoes').orderByChild("metaID").equalTo(key).on('value',function(snapshot) {
              var questoes = snapshotValToArray(snapshot.val());
              //console.log("questoes",questoes);
              self.metas[key].questoes = questoes;
            });

          }
          self.metasVet = self.arrayObjectsSort(objToArray(self.metas),"qtdeVotos",true);
          //console.log("self.metas",self.metas);
          //console.log("self.metasVet",self.metasVet);

        }
      });

    },

    arrayObjectsSort(vet, sortKey, desc = false) {
      function compare( a, b ) {
        if ( a[sortKey] < b[sortKey] ){
          return (desc ? 1 : -1);
        }
        if ( a[sortKey] > b[sortKey] ){
          return (desc ? -1 : 1);
        }
        return 0;
      }
      vet.sort( compare );
      return vet;
    },

    questoesClick(meta) {
      //console.log("questoesClick");
      var self = this;

      rdb.ref('/curriculo/mmc/questoes').orderByChild("descritorID").equalTo(meta.codMeta).on('value',function(snapshot) {
        var questoes = snapshotValToArray(snapshot.val());
        //console.log("questoes",questoes);
        self.eventBus.$emit("toggleMeta",{
          slotKey: "second",
          title: "Lista de itens",
          dados: {
            meta: meta,
            questoes: questoes
          }
        });
      });

    }


  }
}
</script>

<style scoped>

</style>
