

export const color = {
  cor: "#000000",
  init: function(cor) {
    this.cor = cor;
  },
  brightColor: function(hex, percent){
      // strip the leading # if it's there
      if(hex == undefined) return "#000000";
      hex = hex.replace(/^\s*#|\s*$/g, '');

      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if(hex.length == 3){
          hex = hex.replace(/(.)/g, '$1$1');
      }

      var r = parseInt(hex.substr(0, 2), 16),
          g = parseInt(hex.substr(2, 2), 16),
          b = parseInt(hex.substr(4, 2), 16);

      return '#' +
         ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
         ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
         ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
  },
  border: function() { return 'border: 1px solid '+color.cor+';'; },
  area: function() { return 'color:'+color.cor+';'; },
  area20: function() { return 'color:'+color.brightColor(color.cor,20)+';'; },
  area30: function() { return 'color:'+color.brightColor(color.cor,30)+';'; },
  area50: function() { return 'color:'+color.brightColor(color.cor,50)+';'; },
  area70: function() { return 'color:'+color.brightColor(color.cor,70)+';'; },
}
