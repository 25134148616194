<template>
  <v-card elevation="4" outlined class="pb-3" v-if="competencia != null">
    <v-card-text class="text-left text-justify pb-3">
      <div class="row pb-0 pt-0">
        <div class="col-12 col-lg-12 py-0 my-0 fs-10pt">
          <v-card outlined height="22px" v-bg:b#9 v-if="false && (isGestores || isUserAdmin) && (competencia.nova != undefined || competencia.texto.trim() == 'não encontrada')">
            <div class="right p-0 m-0 line-height-1">
              <v-btn v-c:D class="" text x-small @click="$refs.promptdialogref.show(competencia)">
                <i v-i:duo#x-circle#14 class="" style="margin-top: -3px; margin-right: 1px;"></i>
                <span class="text-nonecase f-roboto fw-300 fs-8pt">remover competência</span>
                <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
              </v-btn>
            </div>
          </v-card>
          <descontinuarbtn v-if="false" :areaSel="area" fieldTitle="Meta" @descontinuar="descontinuar"/>
          <readmore class="text-dark mt-0 fs-10pt f-raleway fw-400" size="200" :text="competencia.texto" />
          <v-row>
            <v-col cols="12" class="m-0 p-0 mt-3">
              <div class="m-0 me-3 mt-0 p-0" v-if="competencia.createdAt != undefined" :style="'color:'+hexShades(area.cor,.6)">
                <div class="right f-raleway fs-8pt fw-300">atualizado {{$moment(competencia.createdAt).fromNow()}}</div>
                <div v-i:duo#clock#12 class="right me-1 mt-n1 pt-0" :style="'color:'+hexShades(area.cor,.6)"></div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="m-0 p-0 mt-0 mb-2">
              <feedcontroler ref="fcontroler" :area="area" :uc="ucSel" :color="area.cor" :rdbPath="competenciaPath+'/'+competencia.id" :key="refresh"/>
            </v-col>
          </v-row>
        </div>

      </div>
    </v-card-text>

    <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />

  </v-card>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponent/feedControler.vue'
import descontinuarbtn from '@/views/cursos/planos/descontinuarBtn.vue'
import moment from 'moment';
import promptdialog from "./promptdialog.vue"
import spanblocked from "./spanBlocked.vue"

export default {
  name: "competenciaItem",
  components: { readmore, feedcontroler, descontinuarbtn, promptdialog, spanblocked },
  props: [ "areaSel", "ucSel", "competenciaSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      competenciaPath: null,
      competencia: null,
      uc: null,
      area: null,
      refresh: 0,
    }
  },
  watch: {
    competenciaSel(to, from) {
      var self = this;
      //console.log("metaItem change meta");
      this.buildCompetencia();
    },
    ucSel(to, from) {
      var self = this;
      //console.log("metaItem change uc");
      this.buildCompetencia();
    },
    areaSel(to, from) {
      var self = this;
      //console.log("metaItem change area");
      this.buildCompetencia();
    }
  },
  mounted: function() {
    var self = this;
    this.buildCompetencia();
  },
  methods:{

    buildCompetencia() {
      var self = this;
      this.uc = this.ucSel;
      this.area = this.areaSel;
      var competenciaPath = "/curriculo/socialData/ucs/"+this.uc.id+"/competencias/";
      //console.log(metaPath);
      this.competenciaPath = competenciaPath;

      if(this.competenciaSel.id != undefined) {
        //console.log("/ucs/"+this.ucSel.id+"/metas/"+this.metaSel.id);
        rdb.ref("/curriculo/ucs/"+this.ucSel.id+"/competencias/"+this.competenciaSel.id).on('value',function(snapshot) {
          self.competencia = snapshot.val();
          if(self.competencia != null) {
            self.competencia.id = self.competenciaSel.id;
          }
          //console.log(self.descontinuados);
        });
      }

    },

    descontinuar(novoTexto) {
      console.log("novoTexto");
      console.log(novoTexto);
      console.log(this.competencia);
      var pathUrl = "/curriculo/ucs/"+this.uc.id+"/competenciasDescontinuadas/";
      var newKey = rdb.ref(pathUrl).push().key;
      pathUrl = pathUrl + newKey;
      var itemObj = Object.assign({}, this.competencia);
      itemObj.emProducao = false;
      itemObj.id = newKey;
      var itemNew = {
        createdAt: moment().format(),
        emProducao: true,
        texto: novoTexto,
        id: this.competencia.id,
      };
      console.log("pushKey on: ",pathUrl);
      console.log("itemObj",itemObj);
      console.log("pushKey on: ","/curriculo/ucs/"+this.uc.id+"/competencias/"+this.competencia.id);
      console.log("itemNew",itemNew);
      var updates = {};
      updates[pathUrl] = itemObj;
      updates["/curriculo/ucs/"+this.uc.id+"/competencias/"+this.competencia.id] = itemNew;
      rdb.ref().update(updates);
      console.log("competencias atualizadas");
      this.competencia = itemNew;
    },

    confirmaRemover() {
      console.log("confirmaRemover competencia",this.competencia);
      console.log("confirmaRemover uc",this.uc);
      var updates = {};
      updates["/curriculo/ucs/"+this.uc.id+"/competencias/"+this.competencia.id] = null;
      console.log("updates",updates);
      rdb.ref().update(updates);
      this.toastS("Competência removida com sucesso!");
    },



  }
}
</script>

<style scoped>

</style>
