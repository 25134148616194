<template>
  <div class="row mb-0 pt-0">

    <!-- LISTA DE CURSOS -->
    <poscursoslistview :area="area" @cursoClicked="cursoClicked"/>

    <!-- TITULO -->
    <div class="col-12 text-left my-0 py-0" v-if="curso != null">
      <p class="mb-2 my-0 py-0">
        <p :style="'color:'+hexTints(area.cor,30)" class="f-roboto fs-9pt my-0">Curso</p>
        <v-icon :color="area.cor" class="fs-13pt pb-1 mt-0 ms-0">fas fa-graduation-cap</v-icon>
        <span :style="'color:'+area.cor" class="ms-2 f-raleway fs-14pt fw-500">{{curso.nome}}</span>
      </p>
      <hr class="my-0 mt-n2 py-0">
    </div>

    <!-- LISTA DE UCS -->
    <div class="col-3 mx-0 mt-1">
      <p :style="'color:'+hexShades(area.cor,.1)" class="ms-1 my-0 f-roboto fs-11pt fw-400 text-left">
        Nanodegrees
      </p>
      <hr class="my-0 mx-0">
      <v-card outlined class="mt-2">
        <nanosview ref="nanosviewref" @clickNano="clickNano" v-if="curso != null" editLock="false" :area="area" :curso="curso"/>
      </v-card>
    </div>

    <!-- PLANO DE ENSINO -->
    <div class="col-9">
      <posplanocontainer v-if="nano != null" :nanoSel="nano" :area="area"/>
    </div>

  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import poscursoslistview from "@/views/cursos/pos/posCursosListView.vue"
import nanosview from "@/views/cursos/pos/nanosView.vue"
import posplanocontainer from "@/views/cursos/pos/posPlanoContainer.vue"

export default {
  name: "posView",
  props: [ "editLock", "area" ],
  components: { nanosview, posplanocontainer, poscursoslistview },
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
  },
  data(){
    return {
      curso: null,
      cursos: [],
      cursoSel: "",
      nano: null,
    }
  },
  watch: {
    area(to, from) {
      var self = this;
      console.log("posView change area");
      self.build();
    }
  },
  mounted: function() {
    var self = this;
    console.log("posView mounted");
    self.build();
  },
  methods:{

    build() {
      var self = this;
    },

    cursoClicked(curso) {
      console.log("component cursoClicked",curso);
      this.curso = curso;
      //this.$refs.nanosviewref.buildNanos(curso.nanos,true);
    },

    clickNano(nano) {
      console.log("clickNano");
      console.log(nano);
      this.nano = nano;
    }

  }
}
</script>

<style scoped>

</style>
