<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense>
      <v-col cols="12">
        <v-tabs height="20" class="mt-0 ms-2 fs-9pt" v-model="tab" background-color="transparent" :color="area.cor">
          <v-tab class="p-0 m-0" v-for="(tabName,index) in items" :key="index" >
            <div class="text-nonecase f-roboto fs-9pt px-0 ms-0 fw-400" style="">
              <span>{{ tabName }}</span>
            </div>
          </v-tab>
          <tabblocked :area="area" :color="area.cor" itemName="Profs. Ref."/>
        </v-tabs>

        <v-tabs-items class="mt-2" v-model="tab" v-bg:b>
          <v-tab-item>
            <div class="p-1">
              <ementaview :area="area" :ucSel="uc" @listaDescontinuados="listaDescontinuados('ementa')"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <topicosview :area="area" :ucSel="uc" @listaDescontinuados="listaDescontinuados('topicos')"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <metasview :area="area" :ucSel="uc" @listaDescontinuados="listaDescontinuados('metas')"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <certificacaoview :area="area" :ucSel="uc" :key="refresh"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <competenciasview :area="area" :ucSel="uc" @listaDescontinuados="listaDescontinuados('competencias')"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <bibliosview :area="area" :ucSel="uc"/>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="p-2 ps-4">
              <professoresview :area="area" :ucSel="uc"/>
            </div>
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
//import topicosview from "@/views/cursos/planos/topicosView"
//import metasview from "@/views/cursos/planos/metasView"
import competenciasview from "@/views/cursos/planos/competenciasView"
import bibliosview from "@/views/cursos/planos/bibliosView"
import professoresview from "@/views/cursos/professores/professoresPorUCView"
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import tabblocked from "./tabBlocked.vue"

export default {
  name: "planoView",
  components: { readmore, dummy, tabblocked,
    'ementaview': () => import('@/views/cursos/planosv2/ementa/ementaView.vue'),
    'metasview': () => import('@/views/cursos/planosv2/metas/metasView.vue'),
    'topicosview': () => import('@/views/cursos/planosv2/topicos/topicoView.vue'),
    'certificacaoview': () => import('@/views/cursos/planosv2/certificacao/certificacao-view.vue'),
    competenciasview, bibliosview, professoresview },
  props: [ "editLock", "area", "uc" ],
  computed: {

  },
  data(){
    return {
      tab: null,
      items: [ 'Ementa', 'Tópicos', 'Metas', 'Certificação', 'Competências', 'Bibliografia' ],
      refresh: 0,
    }
  },
  watch: {
    uc(to, from) {
      var self = this;
      console.log("planoView change uc");
    }
  },
  mounted: function() {
    var self = this;
    //if(this.isUserAdmin || (this.isGestores && this.userLog.areasIC[this.area.id] != undefined)) {
    //  this.items.push("Profs. Ref.")
    //}
  },
  methods:{

    listaDescontinuados(tipo) {
      this.$emit("listaDescontinuados",tipo);
    },

    goTab(tab) {
      this.tab = tab;
    }

  }
}
</script>

<style scoped>

</style>
