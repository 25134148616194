<template>
  <div class="">

    <v-card class="p-2 mt-2 mb-2 pb-0" outlined min-height="200" v-bg:b>

      <v-card class="p-0 mt-0 mb-0" outlined v-if="alert">
        <v-alert class="m-0" dense elevation="2" v-model="alert" border="left" color="cor.active.I">
          <p class="m-0 p-0 ms-0 f-titillium fs-9pt fw-600">Sobre acordos:</p>
          <p v-cHex="'#9d0000'" class="mt-0 mb-0 p-0 me-2 line-height-4 f-raleway fs-10pt text-justify">
            O objetivo do processo de recomendações para elaboração de questões da A2
            é justamente facilitar o entendimento das metas de compreensão,
            sob a perspectiva do que <strong>NÃO DEVE</strong> ser mencionado/citado/indicado nas questões
            que forem elaboradas por todos os nossos professores da UC para esta meta de compreensão.
          </p>
        </v-alert>
        <v-btn @click="alert = false" class="absolute right-0 top-0 me-2" color="" icon small>
          <i v-i:duo#x-circle#20></i>
        </v-btn>
      </v-card>

      <v-row v-if="acordos.length == 0">
        <v-col cols="12" lg="12" class="mt-2 mb-0">
          <v-alert class="m-0" type="info" :color="hexTints(cor.active.S,0.1)" v-c:K>
            <p class="m-0 p-0 f-sanspro fs-11pt">Nenhum acordo encontrado.</p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="acordos.length > 0">
        <v-col cols="12" lg="12" class="my-0">
          <v-card class="p-2 mt-2 card-scroll" outlined height="500px">
            <p class="mt-0 mb-1 fs-10pt f-lato fw-600">Acordos</p>
            <hr class="mt-0 mb-2">
            <v-card class="p-1 mb-4" outlined elevation="2" v-bg:b#3 v-for="(item,key,index) in acordos" :key="index">
              <p class="m-0 p-0 ms-1 f-titillium fs-9pt fw-600">Acordo:</p>
              <div class="mx-1 f-lato fs-10pt text-justify">
                <readmore class="m-0 p-0" size="180" :text="item.acordo" :key="index"/>
              </div>
              <div class="mt-4 mb-1">
                <feedcontroler class="mt-n1 pt-0" ref="fcontroler" :color="cor.active.P" :rdbPath="'/curriculo/socialData/metasAcordos/'+metaID+'/conteudo/'+item.id+'/'"/>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponentNoBlock/feedControler.vue'

export default {
  name: "listaacordos",
  components: { readmore, feedcontroler },
  props: [ "metaID" ],
  computed: {},
  data(){
    return {
      loading: false,
      alert: true,
      refresh: 0,
      acordos: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //console.log("this.metaID",this.metaID);
    //self.loading = true;
    rdb.ref('/curriculo/avaliacao/2021S2/metasAcordos/'+this.metaID).on('value',function(snapshot) {
      var ret = snapshot.val();
      if(ret != null) {
        self.acordos = snapshotValToArray(ret.conteudos);
      } else {
        self.acordos = [];
      }
      //console.log("self.acordos",self.acordos);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
