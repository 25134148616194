<template>
  <div class="">
    <v-card class="p-2 mt-2" outlined min-height="200" v-bg:b>
      <v-row v-if="conteudos.length == 0">
        <v-col cols="12" lg="12" class="my-0">
          <v-alert type="info" :color="hexTints(cor.active.S,0.1)" v-c:K>
            <p class="m-0 p-0 f-sanspro fs-11pt">Nenhum conteúdo encontrado.</p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="conteudos.length > 0">
        <v-col cols="12" lg="12" class="my-0">
          <v-card class="p-2 mt-2 card-scroll" outlined height="500px">
            <p class="mt-0 mb-1 fs-10pt f-lato fw-600">Conteúdos</p>
            <hr class="mt-0 mb-2">
            <v-card class="p-1 mb-4" outlined elevation="2" v-bg:b#3 v-for="(item,key,index) in conteudos" :key="index">
              <p class="m-0 p-0 ms-1 f-titillium fs-9pt fw-600">Conteúdo:</p>
              <div class="mx-1 f-lato fs-10pt text-justify">
                <readmore class="m-0 p-0" size="180" :text="item.conteudo" :key="index"/>
              </div>
              <div class="mt-4 mb-1">
                <feedcontroler class="mt-n1 pt-0" ref="fcontroler" :color="cor.active.P" :rdbPath="'/curriculo/socialData/metasConteudos/'+metaID+'/conteudo/'+item.id+'/'"/>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponentNoBlock/feedControler.vue'

export default {
  name: "listaconteudos",
  components: { readmore, feedcontroler },
  props: [ "metaID" ],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      conteudos: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    console.log("this.metaID",this.metaID);
    //self.loading = true;
    rdb.ref('/curriculo/avaliacao/2021S2/metasConteudos/'+this.metaID).on('value',function(snapshot) {
      var ret = snapshot.val();
      if(ret != null) {
        self.conteudos = snapshotValToArray(ret.conteudos);
      } else {
        self.conteudos = [];
      }
      console.log("self.conteudos",self.conteudos);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
