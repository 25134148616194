<template>
  <div class="row" v-if="uc != null">
    <div class="col-12 col-lg-12 text-left">
      <p class="m-0 mb-0 f-monserrat fs-12pt fw-500" :style="'color:'+area.cor">
        <i v-i:duo#graduation-cap#20 class="me-1"></i>
        <span class="fs-10pt fw-500">UC:</span>
        <span class="ms-1 me-6">{{uc.nome}}</span>
        <verplanoensino :uc="uc" :area="area" :key="refresh"/>
        <exportarplanoensino :uc="uc" :area="area" :key="refresh+1"/>
        <importarplanoensino :uc="uc" :area="area" :key="uc.id+refresh+1"/>
      </p>
      <hr class="my-0 mt-1">
      <boxview :slots="['first','second']" eventToggle="toggleBoxQuestoes">
        <template v-slot:first>
          <!-- TABS -->
          <v-container fluid class="m-0 px-1 pb-n1">
            <v-tabs class="m-0 p-0" height="28" v-model="tab" background-color="transparent" :color="area.cor">
              <v-tab class="m-0 px-2 me-1" v-for="item in tabItens" :key="item" style="min-width: 0px;">
                <template slot:default class="px-0" >
                  <span class="text-nonecase line-height-0 f-raleway fs-11pt fw-600">{{ item }}</span>
                </template>
              </v-tab>
            </v-tabs>
          </v-container>
          <v-tabs-items v-model="tab" class="m-0 p-0">
            <v-tab-item class="m-0 p-0">
              <v-card outlined elevation="4" class="m-0 p-1 pt-2" v-bg:b>
                <planoview ref="planoviewref" v-if="boxview == 'plano'" :area="area" :uc="uc" @listaDescontinuados="listaDescontinuados" />
                <descontinuadosementa v-if="boxview == 'ementa'" :area="area" :ucSel="uc" @close="close(0)" />
                <descontinuadostopicos v-if="boxview == 'topicos'" :area="area" :ucSel="uc" @close="close(1)" />
                <descontinuadosmetas v-if="boxview == 'metas'" :area="area" :ucSel="uc" @close="close(2)" />
                <descontinuadoscompetencias v-if="boxview == 'competencias'" :area="area" :ucSel="uc" @close="close(3)" />
              </v-card>
            </v-tab-item>
            <v-tab-item class="pb-0">
              <v-card outlined class="mt-2 pb-0 mb-0">
                <v-card-text class="pb-0 mb-0">
                  <professoresuc :area="area" :ucSel="uc" />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="tabItens.indexOf('Avaliação') != -1">
              <v-card outlined class="mt-2">
                <v-card-text>
                  <avaliacaoview :area="area" :uc="uc" />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card outlined class="mt-2" style="height: 400px;">
                <v-card-text>
                  <v-alert type="info" class="fs-10pt text-dark" :color="hexTints(area.cor,.7)">
                    Funcionalidade em construção.
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card outlined class="mt-2" style="height: 400px;">
                <v-card-text>
                  <v-alert type="info" class="fs-10pt text-dark" :color="hexTints(area.cor,.7)">
                    Funcionalidade em construção.
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </template>

        <template v-slot:second="props">
          <metabox :dados="props.dados" />
        </template>
      </boxview>


    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import dummy from "@/components/dummy/dummy.vue"
import planoview from "@/views/cursos/planos/planoView.vue"
import descontinuadosementa from "@/views/cursos/planos/descontinuadosEmenta.vue"
import descontinuadosmetas from "@/views/cursos/planosv2/metas/descontinuadosMetas.vue"
import descontinuadostopicos from "@/views/cursos/planos/descontinuadosTopicos.vue"
import descontinuadoscompetencias from "@/views/cursos/planos/descontinuadosCompetencias.vue"
import avaliacaoview from "@/views/cursos/avaliacao/avaliacaoView.vue"
import professoresuc from "@/views/cursos/professores/professoresUC.vue"
import boxview from '@/components/boxView.vue'
import metabox from './metaBox.vue'

export default {
  name: "planoContainer",
  components: {
    dummy, boxview, metabox, planoview, professoresuc,
    descontinuadosementa, descontinuadosmetas, descontinuadostopicos,
    descontinuadoscompetencias, avaliacaoview,
    'verplanoensino': () => import('@/views/cursos/planosv2/ver-plano-ensino.vue'),
    'exportarplanoensino': () => import('@/views/cursos/planosv2/exportar-plano-ensino.vue'),
    'importarplanoensino': () => import('@/views/cursos/planosv2/importar-docx/importar-docx.vue'),
  },
  props: [ "editLock", "area", "ucSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      tab: null,
      refresh: 0,
      tabItens: [],
      boxview: "plano",
      uc: null,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      self.uc = self.ucSel;
      this.boxview = "plano";
      console.log("planoContainer change uc");
    }
  },
  mounted: function() {
    var self = this;
    self.uc = self.ucSel;
    self.tabItens = ['Plano de ensino', 'Professores'];
    if(this.isUserAdmin || (this.isGestores && this.userLog.areasIC[this.area.id] != undefined)) {
      self.tabItens.push('Avaliação');
    }
    self.tabItens.push('Dual');
    self.tabItens.push('Relatos de Experiências');
  },
  methods:{

    listaDescontinuados(tipo) {
      console.log("listaDescontinuados ",tipo);
      this.boxview = tipo;
    },

    close(tab) {
      var self = this;
      console.log(tab);
      this.boxview = 'plano';
      this.$nextTick(function() {
        self.$refs.planoviewref.goTab(tab);
      });
    }

  }
}
</script>

<style scoped>

</style>
