<template>
  <v-card class="mt-2 card-scroll" height="510px">
    <v-card-text class="text-left text-justify">
      <v-alert class="p-2" type="info" :color="cor.active.S" v-if="meta == undefined">
        <p class="p-0 m-0 f-sanspro">Nenhuma meta selecionada!</p>
      </v-alert>
      <v-row v-if="meta != undefined">
        <v-col cols="12" lg="12">
          <v-row>
            <v-col cols="12" class="text-right mt-1 pt-0 mb-2">
              <v-btn :color="hexShades(cor.active.S,.3)" text small class="text-nonecase" @click="$refs.dialogconteudo.show(meta)">
                <i v-i:duo#plus-circle#20 class="btn-icon-left"></i>
                recomendar conteúdo
              </v-btn>
            </v-col>
          </v-row>
          <v-alert class="p-2" type="info" :color="cor.active.S" v-if="conteudos != undefined && Object.keys(conteudos).length == 0">
            <p class="p-0 m-0 f-sanspro">Nenhum conteúdo encontrado!</p>
          </v-alert>
          <v-card class="p-1 mb-4" outlined elevation="2" v-bg:b#3 v-for="(item,key,index) in conteudos" :key="index">
            <p class="m-0 p-0 ms-1 f-titillium fs-9pt fw-600">Conteúdo:</p>
            <div class="mx-1 f-lato fs-10pt text-justify">
              <readmore class="m-0 p-0" size="180" :text="item.conteudo" :key="index"/>
            </div>
            <div class="mt-4 mb-1">
              <feedcontroler class="mt-n1 pt-0" ref="fcontroler" :color="cor.active.P" :rdbPath="'/curriculo/socialData/metasConteudos/'+meta.id+'/conteudo/'+item.id+'/'"/>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <dialogrecomendarconteudo ref="dialogconteudo" @changeValue="novoTexto" />
  </v-card>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';
  import readmore from "@/components/readmore.vue"
  import feedcontroler from '@/views/feedsComponentNoBlock/feedControler.vue'
  import dialogrecomendarconteudo from './dialogRecomendarConteudo.vue'

  export default {
    name: "listaconteudos",
    components: { readmore, dialogrecomendarconteudo, feedcontroler },
    props: [ "meta" ],
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
        conteudos: {},
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      //console.log("this.meta",this.meta);
      self.build();
    },
    methods:{

      build() {
        var self = this;
        console.log("this.meta",this.meta);
        if(this.meta == undefined) return;
        rdb.ref('/curriculo/avaliacao/2021S2/metasConteudos/'+this.meta.id).on('value',function(snapshot) {
          var ret = snapshot.val();
          if(ret != null) {
            self.conteudos = ret.conteudos;
          } else {
            self.conteudos = {};
          }
          //console.log("self.conteudos",self.conteudos);
        });

      },

      novoTexto(texto) {
        //console.log("novoTexto",texto);
      }

    }
  }
</script>

<style scoped>

.card-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
