<template>
  <span v-if="user != undefined && user != null">
    <v-badge v-if="isOnline" bordered bottom :color="cor.active.V" dot offset-x="10" offset-y="10" :key="refresh">
      <v-avatar :size="size">
        <img :src="user.urlFoto" alt="" :key="refresh">
      </v-avatar>
    </v-badge>
    <v-badge v-if="!isOnline" bordered bottom :color="cor.active.b" dot offset-x="10" offset-y="10" :key="refresh">
      <v-avatar :size="size">
        <img :src="user.urlFoto" alt="" :key="refresh">
      </v-avatar>
    </v-badge>
  </span>
</template>

<script>
import { rdb } from '@/firebasedb.js';
import moment from "moment";

export default {
  name: "avatarcon",
  components: {},
  props: [ "user", "size" ],
  computed: {
    isOnline() {
      return this.online && moment().diff(this.lastPingServer, 'seconds') < 40;
    },
    diff() {
      return moment().diff(this.lastPingServer, 'seconds');
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      lastPing: moment().format(), //moment().subtract(5, 'minutes').format(),
      lastPingServer: moment().subtract(5, 'minutes').format(),
      interval: null,
      online: false,
    }
  },
  watch: {},
  created: function() {
    var self = this;

  },
  mounted: function() {
    var self = this;
    rdb.ref('/usuarios/pessoasLogControl/'+self.user.id).on('value',function(snapshot) {
      var last = moment(self.lastPingServer);
      var obj = snapshot.val();
      if(obj != null) {
        self.online = obj.logged;
        self.lastPingServer = obj.last;
        //console.log("self.lastPing",self.lastPingServer);
        //console.log("diff",moment().diff(last, 'seconds'));
        self.refresh++;
        self.$forceUpdate();
      }
    });
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
