<template>
  <div class="">
    <v-card class="p-2 mt-2" outlined min-height="200" v-bg:b>
      <v-row v-if="questoes.length == 0">
        <v-col cols="12" lg="12" class="my-0">
          <v-alert type="info" :color="hexTints(cor.active.S,0.1)" v-c:K>
            <p class="m-0 p-0 f-sanspro fs-11pt">Nenhum item de questão encontrado.</p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="questoes.length > 0">
        <v-col cols="12" lg="3" class="my-0">
          <v-card class="p-2 mt-2 card-scroll" outlined height="500px">
            <p class="mt-0 mb-1 fs-10pt f-lato fw-600">Questões</p>
            <hr class="mt-0 mb-2">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item class="border" style="min-height: 34px; max-height: 34px; height: 34px;" v-for="(item,key,index) in questoes" :key="index">
                  <v-list-item-icon class="me-0 pe-0">
                    <i v-i:duo#note#22 class="absolute me-0 pe-0" style="left: 10px; top: 0px;"></i>
                  </v-list-item-icon>
                  <v-list-item-content class="">
                    <v-list-item-title>
                      <span class="f-roboto fw-600 fs-8pt">ID:</span>
                      <span class="ms-1 f-roboto fw-500 fs-10pt">{{item.id}}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" lg="9">
          <v-card outlined class="p-2 mt-2">
            <p class="mt-0 mb-1 fs-10pt f-lato fw-600">Item</p>
            <hr class="mt-0 mb-2">
            <p class="m-0 p-0">
              <span class="p-0 m-0 fs-8pt f-titillium fw-600">Dificuldade:</span>
              <span v-bg:P v-c:B class="rounded px-1 mx-1 fs-9pt f-roboto fw-300">
                {{questoes[selectedItem].dificuldade}}
              </span>
              <span class="p-0 m-0 ms-4 fs-8pt f-titillium fw-600">Criado em:</span>
              <span v-bg:P v-c:B class="rounded px-1 mx-1 fs-9pt f-roboto fw-300" v-if="false">
                {{moment(questoes[selectedItem].dataCriacao).format("DD/MM/YYYY")}}
              </span>
            </p>
            <p class="m-0 p-0">
              <span class="p-0 m-0 fs-8pt f-titillium fw-600">Autor:</span>
              <span class="mx-1 fs-9pt f-roboto fw-300">
                {{questoes[selectedItem].autor}}
              </span>
            </p>
          </v-card>
          <v-card outlined class="p-2">
            <p class="p-0 m-0 fs-8pt f-titillium fw-600">Enunciado:</p>
            <p class="p-0 mx-2 fs-10pt f-lato fw-300 text-justify">
              {{questoes[selectedItem].enunciado}}
            </p>
            <p class="p-0 mx-0 mb-1 mt-2 fs-8pt f-titillium fw-600">Chave:</p>
            <v-card class="p-1" v-bg:B>
              <v-row>
                <v-col cols="1 text-right">
                  <span class="rounded px-1 fs-11pt f-sanspro fw-800" v-bg:b>A</span>
                </v-col>
                <v-col cols="11" class="ms-0 ps-0">
                  <v-card class="m-0 p-0" height="2" v-bg:b></v-card>
                  <v-card class="" height="2" v-bg:b style="margin-top: 6px;"></v-card>
                  <v-card class="" height="2" v-bg:b style="margin-top: 6px;"></v-card>
                </v-col>
              </v-row>
            </v-card>
            <p class="p-0 mx-0 mb-1 mt-2 fs-8pt f-titillium fw-600">Distratores:</p>
            <v-card class="p-1 mb-1" v-bg:B v-for="(item,index) in ['B','C','D','E']" :key="index">
              <v-row>
                <v-col cols="1 text-right">
                  <span class="rounded px-1 fs-11pt f-sanspro fw-800" v-bg:b>{{item}}</span>
                </v-col>
                <v-col cols="11" class="ms-0 ps-0">
                  <v-card class="m-0 p-0" height="2" v-bg:b></v-card>
                  <v-card class="" height="2" v-bg:b style="margin-top: 6px;"></v-card>
                  <v-card class="" height="2" v-bg:b style="margin-top: 6px;"></v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import moment from 'moment';

export default {
  name: "listaquestoes",
  components: { readmore },
  props: [ "metaID" ],
  computed: {
  },
  data(){
    return {
      loading: false,
      selectedItem: 0,
      questoes: [],
    }
  },
  watch: {
    metaID(to, from) {
      var self = this;
      //console.log("meta change");
      self.buildQuestoes();
    },
  },
  mounted: function() {
    var self = this;
    this.buildQuestoes();
  },
  methods:{

    buildQuestoes() {
      console.log("buildQuestoes",this.metaID);
      var self = this;
      self.loading = true;
      rdb.ref('/curriculo/questoes').orderByChild("metaID").equalTo(this.metaID).on('value',function(snapshot) {
        self.questoes = snapshotValToArray(snapshot.val());
        console.log("self.questoes",self.questoes);
        self.loading = false;
      });
    }

  }
}
</script>

<style scoped>

.card-scroll {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
