<template>
  <v-row class="mt-2 pt-0 me-1" v-if="empresa != null">
    <v-col cols="12" class="mt-3 p-0">
      <v-card class="p-2 px-4 mb-1">
        <v-card-title class="m-0 p-0 text-left">
          <v-avatar size="34">
            <img :src="empresa.urlFotoLogo" alt="">
          </v-avatar>
          <div class="m-0 ms-3 p-0">
            <p class="p-0 m-0 line-height-0 text-nonecase fs-10pt f-roboto">
              {{empresa.nome}}
            </p>
            <p class="p-0 m-0 fs-10pt fw-300 f-roboto mt-2 line-height-0">
              {{empresa.breveDescr}}
            </p>
          </div>
        </v-card-title>
      </v-card>
      <v-tabs v-bg:B class="mt-0 mb-0 pt-0 pb-2 px-2 border" height="26" v-model="tab2" background-color="transparent" :color="area.cor">
        <v-tab class="mt-0 mb-0 pb-0 px-2 me-1" v-for="(item,index) in [ 'Jornada', '2021/2', '2021/1' ]" :key="index" style="min-width: 0px;">
          <template slot:default class="px-0 mb-0 pb-0" >
            <span class="text-nonecase f-raleway fs-10pt fw-600">{{ item }}</span>
          </template>
        </v-tab>
      </v-tabs>

      <v-tabs-items class="mt-0 pt-0" v-model="tab2" v-bg:b>
        <v-tab-item class="mt-0 pt-0">
          <v-row class="mt-0 pt-0">
            <v-col cols="12" lg="12" class="mt-0 pt-0">
              <historico :area="area" :empresa="empresa" />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item class="mt-2 pt-0">
          <v-row class="mt-0 pt-0">
            <v-col cols="12" lg="12" class="mt-0 pt-0">
              <v-card>
                <ucsduais :area="area" :empresa="empresa" />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item class="mt-2 pt-0">
          <v-row class="mt-0 pt-0">
            <v-col cols="12" lg="12" class="mt-0 pt-0">
              <v-card class="p-2">
                Funcionalidade em construção!
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

      </v-tabs-items>

    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import dummy from "@/components/dummy/dummy.vue"
import historico from "./historico.vue"
import ucsduais from "./ucsDuais.vue"

export default {
  name: "painelEmpresas",
  components: { dummy, historico, ucsduais },
  props: [ "empresa", "area" ],
  computed: {},
  data(){
    return {
      tab2: null,
      tab3: null,
    }
  },
  watch: {
  },
  mounted: function() {
    var self = this;
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
