<template>
  <div class="px-1 py-1">
    <v-container class="m-0 p-0 rounded" :style="'background-color: '+bgColor">
      <v-row no-gutters align="center" justify="start">
        <v-col :class="(index>0) ? 'ps-01' : ''" :style="'max-width: '+item+'%'" v-for="(item,index) in values" :key="index">
          <v-progress-linear :height="height" :color="colors[index]" value="100">
            <template v-slot:default="{ value }">
              <span v-if="item > 8" class="fs-9pt fw-600" :style="'color:'+color">{{ Math.ceil(item) }}%</span>
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="m-0 p-0 mt-2 px-1">
      <v-row no-gutters align="center" justify="start">
        <v-col class="text-truncate" v-for="(item,index) in values" :key="'key'+index" style="max-width: 140px;">
          <span v-if="item > 8" class="ms-1 me-1 dot" :style="'background-color: '+colors[index]"></span>
          <span v-if="item <= 8" class="me-1 f-opensans fw-700 fs-9pt fh-1 fsp-0 rounded" :style="'padding-left: 2px; background-color: '+colors[index]+'; color: '+color">
            {{item}}%
          </span>
          <span class="f-opensans fw-600 fs-8pt fh-1 fsp-0" :style="'color: '+hexShades(colors[index],0.5)">{{labels[index]}}</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "progressmulti",
  components: {},
  props: {
    color: { default: "#fff", type: String },
    bgColor: { default: "#ccc", type: String },
    height: { default: 14, type: Number },
    values: { type: Array, default: () => [15,25,30,30] },
    colors: { type: Array, default: () => [ "#1da1f2", "#0077b5", "#00d27a", "#bbbbbb" ] },
    labels: { type: Array, default: () => [ "label01", "label02", "label03", "resto" ] },
  },
  computed: {
  },
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
.ps-01 {
  padding-left: 2px !important;
}
</style>
