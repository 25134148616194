<template>
  <div class="row" v-if="nano != null">
    <div class="col-12">
      <div class="mt-0 mb-1 text-dark fs-12pt f-lato fw-400">
        <span :style="scolor">Ementa</span>
        <button v-if="descontinuados.length > 0" :style="'letter-spacing: normal;'+scolor" class="float-right pe-0 mt-0 pb-3 f-roboto fs-10pt fw-400" @click="$emit('listaDescontinuados','ementas')">
          {{descontinuados.length}} descontinuada<span v-if="descontinuados.length>1">s</span>
        </button>
      </div>
      <hr class="mt-0 mb-2 p-0">
      <v-card class="pb-0">
        <v-card-text class="pb-0 mb-0 text-left text-justify">
          <div class="row pb-0 pt-0 pb-0 mb-0">
            <div class="col-12 col-lg-12 pt-1 pb-0 mb-0 fs-10pt">
              <descontinuarbtn :areaSel="area" fieldTitle="Ementa" @descontinuar="descontinuar"/>
              <readmore class="text-dark mt-0 f-raleway fw-400" size="300" :text="nano.ementa" />
              <feedcontroler ref="fcontroler" :areaSel="area" :rdbPathProp="ementaPath"/>

            </div>
          </div>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feeds/feedControler.vue'
import descontinuarbtn from '@/views/cursos/planos/descontinuarBtn.vue'
import moment from 'moment';

export default {
  name: "posementaView",
  components: { readmore, feedcontroler, descontinuarbtn },
  props: [ "editLock", "area", "nanoSel" ],
  computed: {
    isEditLock: function () { return this.editLock; },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolorn50: function() { return 'color:'+this.hexTints(this.area.cor,-50)+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      nano: null,
      ementaPath: null,
      descontinuados: [],
    }
  },
  watch: {
    nanoSel(to, from) {
      var self = this;
      console.log("posementaView change nano");
      this.buildEmenta();
    },
    $refs(to, from) {
      var self = this;
      console.log("fcontroler changed");
      self.$refs.fcontroler.build();
    },
  },
  mounted: function() {
    var self = this;
    this.buildEmenta();
  },
  methods:{

    buildEmenta() {
      var self = this;
      this.nano = this.nanoSel;
      var ementaPath = "/pos/nanos/"+this.nano.id+"/ementaFeed/";
      console.log("ementaPath",ementaPath);
      this.ementaPath = ementaPath;

      rdb.ref("/pos/nanos/"+this.nanoSel.id).on('value',function(snapshot) {
        self.uc = snapshot.val();
        if(self.nano.ementaDescontinuados != undefined) {
          self.ementaDescontinuados = snapshotValToArray(self.nano.ementaDescontinuados);
        } else {
          self.ementaDescontinuados = [];
        }
      });
    },

    descontinuar(novoTexto) {
      console.log("novoTexto");
      console.log(novoTexto);
      var ementaObj = Object.assign({}, this.nano.ementaDescontinuados);
      ementaObj.emProducao = false;
      console.log(ementaObj);
      var pathUrl = "/pos/nanos/"+this.nano.id+"/ementaDescontinuados";
      console.log("pushKey on: ",pathUrl);
      var newKey, itemNew;
      newKey = rdb.ref(pathUrl).push().key;
      console.log("put com nova chave: "+newKey);
      ementaObj.id = newKey;
      itemNew = {
        createdAt: moment().format(),
        emProducao: true,
        texto: novoTexto,
        descontinuados: {}
      };
      if(ementaObj.descontinuados == undefined) {
        itemNew.descontinuados[newKey] = ementaObj;
      } else {
        var descontinuados = Object.assign({}, ementaObj.descontinuados);
        delete ementaObj.descontinuados;
        itemNew.descontinuados = descontinuados;
        itemNew.descontinuados[newKey] = ementaObj;
      }
      console.log(itemNew);
      var updates = {};
      updates[pathUrl] = itemNew;
      rdb.ref().update(updates);
      console.log("pushKey success!");
    }

  }
}
</script>

<style scoped>

</style>
