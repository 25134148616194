<template>
  <b-popover :target="item.id+siglaArea" triggers="click">
    <div class="row">
      <div class="col-3 text-end">
        <b-avatar style="margin-top: 0px; margin-bottom: 0px; border: 2px solid #ccc;" size="3rem" :src="item.urlFoto"></b-avatar>
      </div>
      <div class="col-8 text-start">
        <p class="mt-1 mb-0" style="font-size: 9pt; font-weight: bold;">{{item.NOME}}</p>
        <p class="text-muted mt-0 mb-0" style="font-size: 8.5pt;">
          {{item.Cargo}}
          <span v-if="item.Cargo=='Coordenador(a) Regional'"> - {{item.Regional}}</span>
        </p>
        <p class="mt-1 mb-0 fs-7" style="font-weight: bold;" v-if="item.Cargo=='Coordenador(a) de Cursos'">
          {{item.Marca}} - {{item.Unidade}} - {{item.Regional}}
        </p>
        <p class="mt-1 mb-0 fs-7" style="font-weight: bold;" v-if="item.Cargo=='Coordenador(a) de Área'">
          {{item.Marca}} - {{item.Unidade}} - {{item.Regional}}
        </p>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="mt-1 mb-0 fs-7" style="font-weight: bold;" v-if="item.Cargo=='Coordenador(a) de Cursos'">
            <tagsarea :siglas="item.Areas"/>
          </p>
          <p class="mt-1 mb-0 fs-7" style="font-weight: bold;" v-if="item.Cargo=='Coordenador(a) de Área'">
            <tagsarea :siglas="item.Areas"/>
          </p>
        </div>
      </div>
      <hr class="mb-2 mt-2">
      <div class="row">
        <div class="col-4 mt-0 mb-0">
          <p class="mt-0 mb-0">
            <p class="mt-0 mb-0">
              <span class="text-muted ms-0 me-0">Titulação: </span>
            </p>
            <span class="fs-6 mb-0">{{item.Titulacao}}</span>
          </p>
        </div>
        <div class="col-8 mt-0 mb-0">
          <p class="mt-0 mb-0">
            <p class="mt-0 mb-0">
              <span class="text-muted ms-0 me-0">Formação: </span>
            </p>
            <span class="fs-6 mb-0">{{item.Formacao}}</span>
          </p>
        </div>
        <div class="col-12 mt-0 mb-0">
          <p class="mt-0 mb-0">
            <p class="mt-0 mb-0">
              <b-icon icon="envelope"></b-icon>
              <span class="text-muted ms-1 me-1">email: </span>
            </p>
            <span class="fs-6 mb-0">{{item.email}}</span>
          </p>
        </div>
        <div class="col-12 mt-0 mb-0">
          <p class="mt-0 mb-0">
            <p class="mt-0 mb-0">
              <b-icon icon="telephone"></b-icon>
              <span class="text-muted ms-1 me-1">telefone: </span>
            </p>
            <span class="fs-6">{{item.Telefone}}</span>
          </p>
        </div>
      </div>
    </div>
  </b-popover>
</template>

<script>
import tagsarea from '@/views/pessoas/tagsAreas'

export default {
  name: "popover",
  props: [ "item", "siglaArea" ],
  components: { tagsarea },
  computed: {
  },
  data(){
    return {
    }
  },
  mounted: function() {
  },

  methods:{


  }
}
</script>

<style scoped>

.popover {
  max-width: 400px !important;
  width: 380px;
  text-align: left !important;
}

</style>
