<template>
  <span class="" v-if="meta != null && meta != undefined && meta.votos != undefined">
    <v-tooltip right class="p-0" color="black" v-model="showToolTip" open-delay="500" v-if="meta.votos.length > 0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="m-0 px-0 pt-0 pb-1 text-nonecase" small plain text>
          <span class="m-0 p-0 f-lato fs-9pt fw-800" v-if="meta.votos.length>0">
            {{meta.votos.length}} professor<span v-if="meta.votos.length>1">es</span>
          </span>
        </v-btn>
      </template>
      <div class="p-0 m-0 f-raleway fs-8pt fw-500" v-for="(voto,index) in meta.votos" :key="index">
        <p class="m0 p0" v-c:B><i class="mr1" v-i:duo#user#14></i>{{voto.nome}}</p>
      </div>
    </v-tooltip>
  </span>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import moment from 'moment';

export default {
  name: 'a2tooltipprofsmeta',
  components: {},
  props: [ "uc", "metaSel" ],
  data(){
    return {
      valid: false,
      showToolTip: false,
      meta: null,
    }
  },
  computed: {
  },
  watch: {
    metaSel(to, from) {
      var self = this;
      //console.log("toolTipMeta change meta",self.metaSel);
      self.meta = self.metaSel;
    }
  },
  created() {
    //this.log_s('Created');
  },
  mounted() {
    //this.log_s('Mounted');
    var self = this;
    //console.log("toolTipMeta meta",self.metaSel);
    self.meta = self.metaSel;
  },
  methods: {

  }
}
</script>

<style scoped>


</style>
