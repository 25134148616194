<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2" v-if="uc != null">
      <v-card-title class="fs-13pt my-0 pb-1" v-c:I>
        <span class="ps-1 fw-300 f-raleway">Indique a Meta de Compreensão para A2</span>
      </v-card-title>
      <v-divider class="mt-0 mb-1 py-0"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12 my-0 py-0">
              <v-list dense>
                <v-list-item-group v-model="metasSel" multiple active-class="">
                  <div class="m-0 p-0" v-for="meta in metas" :key="meta.id">
                    <v-list-item class="m-0 p-0">
                      <template v-slot:default="{ active }">
                        <v-list-item-action class="ms-0 me-2 px-0">
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <div class="mx-0 px-0 f-lato fs-10pt text-justify">
                          {{meta.texto}}
                        </div>
                      </template>
                    </v-list-item>
                    <v-divider class="mx-0 mt-0 mb-2 p-0"></v-divider>
                  </div>
                </v-list-item-group>
              </v-list>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.S" small @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.V" v-c:B small @click="update">
          <i class="btn-icon-left" v-i:duo#check-circle#18></i>
          Indicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";

export default {
  name: "forma2indicameta",
  components: { },
  data() {
    return {
      dialog: false,
      loading: false,
      uc: null,
      metas: [],
      metasSel: [],
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
  },

  methods:{

    show(uc) {
      var self = this;
      //console.log("show",uc);
      this.dialog = true;
      this.uc = uc;
      //console.log("this.uc",this.uc);

      rdb.ref('/curriculo/metas').orderByChild('ucID').equalTo(this.uc.id).on('value', function(snapshot) {
        self.metas = snapshotValToArray(snapshot.val());
        //console.log("self.metas",self.metas);
        self.metasSel = [];
        rdb.ref('/curriculo/avaliacao/2021S2/ucs/'+self.uc.id+"/metasIndicacoes/"+self.userLog.id).once('value').then(function(snapshot) {
          var ret = snapshot.val();
          //console.log("showRet",ret);
          for(var idx in self.metas) {
            for(var idx2 in ret.metasIndicadas) {
              if(self.metas[idx].id == ret.metasIndicadas[idx2].id) {
                self.metasSel.push(parseInt(idx));
              }
            }
          }
        });

      });

    },

    update() {
      var self = this;
      if(this.metasSel.length == 0) {
        this.toastD("Nenhuma meta selecionada!");
        return;
      }
      //console.log("this.metasSel",this.metasSel);
      //console.log("this.metas",this.metas);
      //console.log("this.uc",this.uc);
      //console.log("this.userLog",this.userLog);

      var metasIndicacoes = {
        id: this.userLog.id,
        professorID: this.userLog.id,
        professor: {
          id: this.userLog.id,
          nome: this.userLog.nome,
          urlFoto: this.userLog.urlFoto
        },
        metasIndicadas: {}
      }
      for(var idx in this.metasSel) {
        var meta = this.metas[this.metasSel[idx]];
        delete meta.coments;
        delete meta.likes;
        metasIndicacoes.metasIndicadas[this.metas[this.metasSel[idx]].id] = meta;
      }

      rdb.ref('/curriculo/avaliacao/2021S2/ucs/'+this.uc.id).once('value').then(function(snapshot) {
        var ret = snapshot.val();
        var uc;
        var updates = {};
        if(ret == null) {
          uc = {
            id: self.uc.id,
            areas: self.uc.areas,
            codigo: self.uc.codigo,
            nome: self.uc.nome,
            metasIndicacoes: {},
          }
          uc.metasIndicacoes[self.userLog.id] = metasIndicacoes;
          updates["/curriculo/avaliacao/2021S2/ucs/"+uc.id] = uc;
        } else {
          uc = ret;
          updates["/curriculo/avaliacao/2021S2/ucs/"+uc.id+"/metasIndicacoes/"+self.userLog.id] = metasIndicacoes;
        }
        //console.log(updates);
        rdb.ref().update(updates);
        self.toastS("Indicação concluída com sucesso!");
      });
      this.dialog = false;
      //this.$emit("update",{ formData: this.formData, tipo: this.btnName } );
    }

  }
}
</script>

<style scoped>

</style>
