<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2">
      <v-card-title class="fs-11pt my-0 py-1" v-c:I>
        <span class="fw-700 f-raleway">{{btnName}}</span>
        <span class="ps-1 fw-300 f-raleway">nova empresa</span>
      </v-card-title>
      <v-divider class="mt-0 mb-1 py-0"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12 my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto mt-0 mb-0 pb-0 ps-1">Logo da empresa</p>
              <v-row class="mt-0 p-0">
                <v-col cols="12" lg="2">
                  <v-avatar size="56">
                    <img :src="formData.urlFoto" class="rounded-circle" alt="" style="">
                  </v-avatar>
                </v-col>
                <v-col cols="12" lg="10" class="my-0 py-0">
                  <v-text-field solo dense class="mb-n4 mt-3" v-model="formData.urlFoto" type="text" placeholder="url / link"></v-text-field>
                  <span class="ms-1 my-0 py-0 fs-7">
                    Proporção (largura/altura): 1 x 1
                  </span>
                  <fileupload class="ms-1" path="responsaveis" refreshModel="urlFoto" @urlUpload="urlUpload">Upload</fileupload>
                </v-col>
              </v-row>
              <hr class="mb-0">
            </v-col>
            <v-col cols="12 my-3 py-0">
              <p class="fw-300 fs-10pt f-roboto mt-2 mb-n2 pb-0 ps-1">Foto de capa da empresa</p>
              <v-row>
                <v-col cols="12" lg="4">
                  <img :src="formData.urlCapa" class="" alt="" style="width: 100%; max-height: 80px;">
                </v-col>
                <v-col cols="12" lg="8" class="my-0 py-0">
                  <v-text-field solo dense class="mb-n4 mt-3" v-model="formData.urlCapa" type="text" placeholder="url / link"></v-text-field>
                  <span class="ms-1 my-0 py-0 fs-7">
                    Proporção (largura/altura): 12 x 1
                  </span>
                  <fileupload class="ms-1" path="responsaveis" refreshModel="urlCapa" @urlUpload="urlUpload">Upload</fileupload>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12 my-3 py-0">
              <p class="fw-300 fs-10pt f-roboto mt-2 mb-n2 pb-0 ps-1">Foto do cartão da empresa</p>
              <v-row>
                <v-col cols="12" lg="4">
                  <img :src="formData.urlCapa" class="" alt="" style="width: 100%; max-height: 80px;">
                </v-col>
                <v-col cols="12" lg="8" class="my-0 py-0">
                  <v-text-field solo dense class="mb-n4 mt-3" v-model="formData.urlCapa" type="text" placeholder="url / link"></v-text-field>
                  <span class="ms-1 my-0 py-0 fs-7">
                    Proporção (largura/altura): 3 x 1
                  </span>
                  <fileupload class="ms-1" path="responsaveis" refreshModel="urlCapa" @urlUpload="urlUpload">Upload</fileupload>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="mt-2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Nome da empresa</p>
              <v-text-field v-model="formData.nome" :rules="[rules.required]" solo dense label="Nome" required placeholder="Digite o nome"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="mt-0 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Email contato</p>
              <v-text-field v-model="formData.email" :rules="[rules.required, rules.email]" solo dense label="Email" required placeholder="Digite o email"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="mt-0 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Telefone do contato</p>
              <v-text-field v-model="formData.telefone" solo dense label="Telefone" required placeholder="Digite o telefone"></v-text-field>
            </v-col>
            <div class="mb-3">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Breve Descrição da empresa</p>
              <input v-model="formData.breveDescr" type="text" class="form-control" placeholder="Breve descrição da empresa">
            </div>
            <div class="mb-3">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Descrição da empresa</p>
              <textarea v-model="formData.descricao" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
            <div class="mb-3">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Benefícios da empresa</p>
              <textarea v-model="formData.beneficios" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.S" small @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.V" v-c:B small @click="update">
          <i class="btn-icon-left" v-i:duo#check-circle#18></i>
          {{btnName}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import fileupload from './fileupload'

export default {
  name: "formsetor",
  components: { fileupload },
  data() {
    return {
      dialog: false,
      loading: false,
      btnName: "Adicionar",
      formData: {
        nome: "", cargo: "Educador(a)", urlCapa: "https://placeimg.com/1200/200/nature",
        urlFoto: "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-dummy.png?alt=media&token=507af834-9131-4952-bd99-d42fca04e160",
        formacao: { area: "", titulacao: "Graduado" }, grandeArea: 0, ies: "USJT", regional: "SP",
        unidade: "", email: "", telefone: "", senha: "xxxx", admin: false, equipes: {}, setores: {}
      },
      rules: {
        required: value => !!value || 'Obrigatório',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    self.loading = false;
  },

  methods:{

    urlUpload(urlLink,prop) {
      console.log(urlLink);
      console.log(prop);
      this.formData[prop] = urlLink;
      this.$forceUpdate();
    },

    show(dados) {
      console.log("dados",dados);
      this.dialog = true;
      return;
      if(dados == null) {
        this.formData = { nome: "", cor: null };
        this.btnName = "Adicionar";
      } else {
        this.formData = clone2(dados);
        this.btnName = "Atualizar";
      }
      console.log("this.formData",this.formData);
    },

    update() {
      if(this.formData.nome == "") {
        this.toastD("Nome da área obrigatória!");
        return;
      }
      this.dialog = false;
      this.$emit("update",{ formData: this.formData, tipo: this.btnName } );
    }

  }
}
</script>

<style scoped>

</style>
