<template>
  <div class="row py-0">
    <div class="col-12 col-lg-12 py-1 my-0 text-left">
      <v-list dense class="mx-0 px-0">
        <v-list-item-group v-model="nanoSel" :color="area.cor">
          <div class="px-0 mx-0" v-for="(nano, i) in nanos" :key="i">
            <v-list-item class="px-3 mx-0" @click="$emit('clickNano',nano)">
              <v-list-item-icon class="ps-0 ms-0 me-n1">
                <i v-i:duo#graduation-cap#20 class="btn-icon-left me-2"></i>
              </v-list-item-icon>
              <v-list-item-content class="ms-0">
                <v-list-item-title class="ms-0 text-left fs-9pt f-raleway">
                  {{nano.nome}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>

    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'

export default {
  name: "nanosView",
  components: { },
  props: [ "editLock", "area", "curso" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      nanoSel: "",
      nanos: [],
    }
  },
  watch: {
    curso(to, from) {
      var self = this;
      console.log("nanosView change curso");
      var vetNanos = objToArray(this.curso.nanos);
      this.nanos = [];
      this.buildNanos(vetNanos,true);
    }
  },
  mounted: function() {
    var self = this;
    var vetNanos = objToArray(this.curso.nanos);
    console.log("vetNanos",vetNanos);
    this.nanos = [];
    this.buildNanos(vetNanos,true);
  },
  methods:{

    buildNanos(vetNanos,first) {
      console.log("buildNanos");
      var self = this;
      if(vetNanos.length > 0) {
        var tpIdx = vetNanos[0].id;
        //console.log("tpIdx", tpIdx);
        vetNanos.shift();
        rdb.ref('/pos/nanos/'+tpIdx).once('value').then(function(snapshot) {
          var ret = snapshot.val();
          //console.log(ret);
          self.nanos.push(ret);
          if(first) {
            self.nanoSel = 0;
            self.$emit("clickNano",ret);
          }
          self.buildNanos(vetNanos,false);
        });
      } else {
        //console.log(self.ucs);
      }
    }

  }
}
</script>

<style scoped>

</style>
