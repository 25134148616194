<template>
  <div class="row" v-if="uc != null">
    <div class="col-12 col-lg-12 mb-0 pb-0">
      <div class="mt-0 mb-1 text-dark fs-12pt f-lato fw-400">
        <span :style="scolor">Tópicos Geradores</span>
        <spanblocked :area="area" equipeID="gestores" class="right">
          <v-btn v-if="false" color="cor.active.S" class="ms-1 px-1 f-sanspro text-nonecase" x-small @click="$refs.txtareadialog.show()">
            <i v-i:duo#plus-circle#16 class="btn-icon-left"></i>
            Adicionar <span class="ms-1 fw-700">tópico</span>
            <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
          </v-btn>
        </spanblocked>
        <button v-if="topicosDescontinuados.length > 0" :style="'letter-spacing: normal;'+scolor" class="float-right pe-0 mt-0 pb-3 f-roboto fs-10pt fw-400" @click="$emit('listaDescontinuados','topicos')">
          {{topicosDescontinuados.length}} descontinuado<span v-if="topicosDescontinuados.length>1">s</span>
        </button>
      </div>
      <hr class="my-0 p-0">
    </div>

    <div class="col-12 col-lg-12 mt-0 pt-1" v-for="(topico,index) in topicos" :key="index">
      <topicoitem :areaSel="area" :ucSel="uc" :topicoSel="topico"/>
    </div>

    <textareadialog ref="txtareadialog" fieldTitle="Tópico gerador" mensagem="teste" @changeValue="novoTexto" />

  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import topicoitem from '@/views/cursos/planos/topicoItem.vue'
import textareadialog from '@/views/cursos/planos/textAreaDialog.vue'
import spanblocked from "./spanBlocked.vue"

export default {
  name: "topicosView",
  components: { topicoitem, textareadialog, spanblocked },
  props: [ "editLock", "area", "ucSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      uc: null,
      topicoPath: null,
      topicos: [],
      topicosDescontinuados: [],
      refresh: 0,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      //console.log("topicosView change uc");
      this.buildTopicos();
    }
  },
  mounted: function() {
    var self = this;
    this.buildTopicos();
  },
  methods:{

    buildTopicos() {
      var self = this;
      this.uc = this.ucSel;

      if(this.ucSel.id != undefined) {
        //console.log("/ucs/"+this.ucSel.id+"/topicos");
        rdb.ref("/curriculo/ucs/"+this.ucSel.id+"/topicos").on('value',function(snapshot) {
          self.topicos = snapshotValToArray(snapshot.val());
          //console.log("self.topicos",self.topicos);
        });
        rdb.ref("/curriculo/ucs/"+this.ucSel.id+"/topicosDescontinuados").on('value',function(snapshot) {
          self.topicosDescontinuados = snapshotValToArray(snapshot.val());
          //console.log("topicosDescontinuados");
          //console.log(self.topicosDescontinuados);
        });
      }
    },

    novoTexto(texto) {
      //console.log("novoTexto",texto);
      //console.log("this.uc",this.uc);
      var newKey = rdb.ref("/curriculo/ucs/"+this.uc.id+"/topicos/").push().key;
      var itemNew = {
        createdAt: moment().format(),
        emProducao: true,
        texto: texto.trim(),
        id: newKey,
        nova: true
      };
      //console.log("itemNew",itemNew);
      var updates = {};
      updates["/curriculo/ucs/"+this.uc.id+"/topicos/"+newKey] = itemNew;
      //console.log("updates",updates);
      rdb.ref().update(updates);
      //console.log("metas atualizadas");
      this.toastS("Tópico Gerador adicionado com sucesso!");
      this.buildTopicos();
      this.refresh =+ 20;
    }


  }
}
</script>

<style scoped>

</style>
