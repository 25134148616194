<template>
    <v-card outlined class="mt-2">
      <v-card-text>
        <v-timeline align-top dense v-c:K :color="cor.active.K" :dark="isDark">
          <historicoitem :area="area" :empresa="empresa" />
          <historicoitem :area="area" :empresa="empresa" />

          <v-timeline-item class="mt-n4 pt-0" fill-dot large :color="cor.active.b" v-c:K>
            <template v-slot:icon>
              <v-avatar>
                <span v-c:I v-i:ic#lock#30></span>
              </v-avatar>
            </template>
            <v-row class="pt-1">
              <v-col cols="12" class="">
                <v-divider class="mt2"></v-divider>
              </v-col>
            </v-row>
          </v-timeline-item>

          <historicoitem :area="area" :empresa="empresa" :cor="true" />

          <v-timeline-item fill-dot :color="cor.active.S" v-c:K>
            <template v-slot:icon>
              <v-avatar :color="hexTints(cor.active.V,0.4)">
                <span v-i:duo#calendar#24></span>
              </v-avatar>
            </template>
            <v-row class="pt-1">
              <v-col cols="12" md="6" class="pt0 mt0">
                <p class="my-0 py-0 f-lato fs-10pt fw-500">
                  <span v-i:ic#task#16 class="left py-0"></span>
                  <span class="left py-0 mt-1 ms-1">Atividade:</span>
                </p>
                <p class="clear my-0 py-0 f-raleway fs-10pt fw-500">
                  Planejamento Acadêmico
                </p>
                <p class="my0 f-roboto fs-9pt fw-400" v-c:V>
                  Concluído em 20 de Abril de 2021
                </p>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 mt-0">
                <v-card class="" plain :color="cor.active.b">
                  <v-card-text class="py-1 px-1">
                    <div class="my0 f-roboto fs-8pt fw-300" v-c:K>
                      <p class="m-0 p-0">
                        <span class="fw-400">Responsável:</span>
                        <span class="ms-1 fs-10pt fw-500">Gestão de área</span>
                      </p>
                      <p class="m-0 p-0">
                        Avaliação das Metas de Compreensão e conteúdo
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item fill-dot :color="cor.active.S" v-c:K>
            <template v-slot:icon>
              <v-avatar :color="hexTints(cor.active.I,0.4)">
                <span v-i:duo#calendar#24></span>
              </v-avatar>
            </template>
            <v-row class="pt-1">
              <v-col cols="12" md="6" class="pt0 mt0">
                <p class="my-0 py-0 f-lato fs-10pt fw-500">
                  <span v-i:ic#task#16 class="left py-0"></span>
                  <span class="left py-0 mt-1 ms-1">Atividade:</span>
                </p>
                <p class="clear my-0 py-0 f-raleway fs-10pt fw-500">
                  Planejamento Operacional
                </p>
                <p class="my0 f-roboto fs-9pt fw-400" v-c:I>
                  Previsto para 20 de Abril de 2021
                </p>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 mt-0">
                <v-card class="" plain :color="cor.active.b">
                  <v-card-text class="py-1 px-1">
                    <div class="my-0 f-roboto fs-8pt fw-300" v-c:K>
                      <p class="m-0 p-0">
                        <span class="fw-400">Responsável:</span>
                        <span class="ms-1 fs-10pt fw-500">Gerência de Avaliação</span>
                      </p>
                      <p class="m-0 ms-1 p-0">UC’s ofertadas</p>
                      <p class="m-0 ms-1 p-0">Professores indicados</p>
                      <p class="m-0 ms-1 p-0">Pedido de itens por Metas de Compreensão</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-timeline-item>

        </v-timeline>


      </v-card-text>
    </v-card>

</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import historicoitem from "./historicoItem.vue";

export default {
  name: "historico",
  components: { historicoitem },
  props: [ "area", "empresa" ],
  computed: {},
  data(){
    return {
      tab: null,
      refresh: 0,
    }
  },
  watch: {
  },
  mounted: function() {
    var self = this;
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
