<template>
  <span v-if="areas[sigla] != undefined">
    <i class="absolute" v-i:ar##44="areas[sigla].icon" :style="'left: 6px; top: 2px; color: '+areas[sigla].cor+'; fill: '+areas[sigla].cor"/>
  </span>
</template>

<script>

export default {
  name: "iconArea",
  props: [ "sigla", "stack" ],
  data: function() {
    return {
      areas: {
        "AU&D": { cor: "#933b4a", title: "Arquitetura e Urb. & Design", sigla: "AU&D", collection: "ar", icon: "arquitetura2", link: "formacoes" },
        "CA&MA": { cor: "#c3b000", title: "C. Agrárias & Meio Ambiente", sigla: "CA&MA", collection: "ar", icon: "agraria2", link: "formacoes" },
        "CB&S": { cor: "#2c6500", title: "Ciências Biológicas e Saúde", sigla: "CB&S", collection: "ar", icon: "saude", link: "formacoes" },
        "CH": { cor: "#8407a3", title: "Ciências Humanas",  sigla: "CH",collection: "ar", icon: "humanas", link: "formacoes" },
        "CJ": { cor: "#6b1e61", title: "Ciências Jurídicas",  sigla: "CJ", collection: "ar", icon: "balance2", link: "formacoes" },
        "C&A": { cor: "#c14c02", title: "Comunicação & Artes",  sigla: "C&A", collection: "ar", icon: "comunicacao", link: "formacoes" },
        "ENG": { cor: "#000320", title: "Engenharias", sigla: "ENG", collection: "ar", icon: "engineering", link: "formacoes" },
        "G&N": { cor: "#4f2f09", title: "Gestão & Negócios", sigla: "G&N", collection: "ar", icon: "gestao", link: "formacoes" },
        "TI&C": { cor: "#526383", title: "TI & Computação", sigla: "TI&C", collection: "ar", icon: "tic", link: "formacoes" },
        "T&H": { cor: "#b63838", title: "Turismo & Hospitalidade", sigla: "T&H", collection: "ar", icon: "turismo", link: "formacoes" },
        "CORE": { cor: "#ff5400", title: "Core Curriculum", sigla: "CORE", collection: "ar", icon: "core", link: "formacoes" },
        "MARG": { cor: "#ff5400", title: "Margarida", sigla: "MARG", collection: "ar", icon: "margarida", link: "formacoes" },
        "VIOL": { cor: "#681e6a", title: "Violeta", sigla: "VIOL", collection: "ar", icon: "margarida", link: "formacoes" },
        "ROSA": { cor: "#681e6a", title: "Rosa", sigla: "ROSA", collection: "ar", icon: "margarida", link: "formacoes" },
        "LIR": { cor: "#681e6a", title: "Lirio", sigla: "LIR", collection: "ar", icon: "margarida", link: "formacoes" },
        "GERB": { cor: "#681e6a", title: "Gérbera", sigla: "GERB", collection: "ar", icon: "margarida", link: "formacoes" },
      }
    }
  },
  created: function() {

  },
  mounted: function() {
    var self = this;
    //console.log("tagarea Mounted");
    //console.log(this.siglas);
  },
  methods: {


  }
}
</script>

<style scoped>

</style>
