<template>
  <span>
    <v-progress-linear :color="getColor" :value="(qtde/qtdeTotal)*100"></v-progress-linear>
  </span>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "progressmeta",
    components: {},
    props: {
      qtdeTotal: { default: 0, type: Number},
      qtde: { default: 0, type: Number},
    },
    computed: {
      getColor() {
        if(this.qtde == 0) {
          return "#ffffff";
        }
        if((this.qtde/this.qtdeTotal)*100 > 80) {
          return "#00b02c";
        }
        if((this.qtde/this.qtdeTotal)*100 > 60) {
          return "#cfc600";
        }
        if((this.qtde/this.qtdeTotal)*100 > 40) {
          return "#e2c400";
        }
        if((this.qtde/this.qtdeTotal)*100 > 20) {
          return "#d65400";
        }
        if((this.qtde/this.qtdeTotal)*100 > 0) {
          return "#9f0404";
        }
        return "#ffffff";
      }
    },
    data(){
      return {
        loading: false,
        refresh: 0,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
    },
    methods:{

      build() {
          var self = this;
      }
    }
  }
</script>

<style scoped>
</style>
