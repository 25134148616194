<template>
  <v-dialog v-model="dialog" max-width="600" v-if="meta != null">
    <v-card>
      <v-toolbar :color="cor.active.b">
        <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
          Recomendar conteúdos
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-card class="mt-3 p-2" :color="hexTints(cor.active.I,0.8)">
          <p class="m-0 p-0 ms-0 f-titillium fs-9pt fw-600">Meta de compreensão:</p>
          <p class="m-0 p-0 text-justify">
            <readmore class="m-0 p-0" size="200" :text="meta.texto" :key="meta.texto"/>
          </p>
        </v-card>
        <v-divider class="my-3"></v-divider>
        <sugestoesconteudos :meta="meta" :filtro="texto" @closeDialog="dialog = false"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import sugestoesconteudos from "./sugestoesConteudos.vue"
import readmore from "@/components/readmore.vue"

export default {
  name: "dialogrecomendarconteudo",
  components: { readmore, sugestoesconteudos },
  props: [ "mensagem", "fieldTitle" ],
  computed: { },
  data(){
    return {
      texto: "",
      msgProp: "",
      dialog: false,
      rules: [
        value => !!value || 'Texto obrigatório!',
        value => (value && value.length >= 10) || 'Mínimo 10 caracteres',
      ],
      erroValor: false,
      meta: null,
    }
  },
  watch: {
    mensagem(to, from) {
      var self = this;
      this.build();
    },
    dialog(to, from) {
      var self = this;
      this.texto = "";
    },
  },
  mounted: function() {
    var self = this;
    this.build();
  },
  methods:{

    build() {
      var self = this;
      this.texto = "";
    },

    show(meta) {
      this.dialog = true;
      this.meta = meta;
    },

    erroUpdate(value) {
      this.erroValor = !value;
      //console.log(value);
    }

  }
}

</script>

<style scoped>

</style>
