<template>
  <v-row dense class="" v-bg:b>
    <v-col cols="12" class="mt-1">
      <!-- TABS -->
      <v-container fluid class="m-0 px-1 pb-n1">
        <v-tabs class="p-0" height="24" v-model="tab" background-color="white" :color="area.cor">
          <v-tab class="m-0 p-0 px-2 me-1" v-for="item in items" :key="item" style="min-width: 0px;">
            <template slot:default class="px-0" >
              <span class="text-nonecase line-height-0 f-raleway fs-10pt fw-600">{{ item }}</span>
            </template>
          </v-tab>
        </v-tabs>
      </v-container>
      <v-tabs-items v-model="tab" class="m-0 p-0">
        <!-- TODAS UCS -->
        <v-tab-item v-bg:b>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" md="4" lg="4" class="p-0">
                <p :style="'color:'+hexShades(area.cor,.3)" class="ms-1 my-0 f-roboto fs-11pt fw-400 text-left">
                  Unidades Curriculares
                </p>
                <ucsview @selectInitialUc="selectInitialUc" :especificas="false" @clickUc="clickUc" editLock="false" :area="area"/>
              </v-col>
              <v-col cols="12" md="8" lg="8" class="p-0 ps-4">
                <planocontainer v-if="uc != null" :ucSel="uc" :area="area" :key="refresh"/>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- OFERTADAS -->
        <v-tab-item>
          <v-card outlined class="mt-2">
            <v-card-text>
              <div class="row">
                <!-- LISTA DE UCS -->
                <div class="col-12 col-lg-4 mx-0 mt-1">
                  <p :style="'color:'+hexShades(area.cor,.3)" class="ms-1 my-0 f-roboto fs-11pt fw-400 text-left">
                    Unidades Curriculares
                  </p>
                  <v-card outlined class="mt-0">
                    <ucsview @selectInitialUc="selectInitialUc" :especificas="true" @clickUc="clickUc" editLock="false" :area="area"/>
                  </v-card>
                </div>

                <!-- PLANO DE ENSINO -->
                <div class="col-12 col-lg-8">
                  <planocontainer v-if="uc != null" :ucSel="uc" :area="area" :key="refresh"/>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

      </v-tabs-items>

    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import ucsview from "@/views/cursos/ucsViewArea.vue"
import planocontainer from "@/views/cursos/planos/planoContainer.vue"


export default {
  name: "ucsArea",
  props: [ "editLock", "area" ],
  components: { ucsview, planocontainer },
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
  },
  data(){
    return {
      tab: null,
      items: [ 'Todas UCs', 'Específicas' ],
      uc: null,
      refresh: 0,
    }
  },
  watch: {
    area(to, from) {
      var self = this;
      self.build();
    }
  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      //console.log("ucsArea change area");
    },

    selectInitialUc(uc) {
      //console.log("selectInitialUc");
      //console.log(uc);
      this.uc = uc;
      this.refresh++;
    },

    clickUc(uc) {
      //console.log("clickUc");
      //console.log(uc);
      this.uc = uc;
      this.refresh++;
    }

  }
}
</script>

<style scoped>

</style>
