<template>
  <div class="row" v-if="uc != null">
    <div class="col-12 col-lg-12 mb-0 pb-0">
      <div class="mt-0 mb-1 text-dark fs-12pt f-lato fw-400">
        <v-badge :color="cor.active.I" inline>
          <template v-slot:badge>
            <span class="fs-8pt fw-500 f-roboto">{{professores.length}}</span>
          </template>
          <span :style="scolor">Professores</span>
        </v-badge>
      </div>
      <hr class="my-0 p-0">
      <v-alert v-if="!uc.oferta2021S2" type="info" class="fs-10pt fw-500 mt-2 mb-0 text-dark" :color="hexTints(area.cor,.7)">
        Unidade Curricular SEM OFERTA em 2021/2.
      </v-alert>
    </div>
    <div v-if="!loading && professores.length == 0" class="col-12 col-lg-12 mt-0 pt-1 pb-0 mb-0">
      <v-card outlined class="mt-2 p-0 pb-0 mb-0">
        <v-card-text class="m-0 p-0">
          <v-alert type="info" class="m-0 text-dark fs-10pt" :color="hexTints(area.cor,.9)">
            Nenhum professor encontrado.
          </v-alert>
        </v-card-text>
      </v-card>
    </div>
    <div class="col-12 col-lg-12 mt-2 mx-0 px-0">
      <div v-if="loading" class="mt-0 mx-4">
        <span class="fs-8pt ms-2 fw-300">carregando...</span>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
      </div>
      <v-list two-line>
        <professoritemgeral  v-for="(prof,idx,index) in professores" :key="idx" :area="area" :ucSel="uc" :prof="prof" />
      </v-list>
    </div>

  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import professoritemgeral from '@/views/cursos/professores/professorItemGeral.vue'

export default {
  name: "professoresView",
  components: { professoritemgeral },
  props: [ "editLock", "area", "ucSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      tab: null,
      uc: null,
      professores: [],
      countDown: 0,
      loading: false,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      //console.log("professoresView change uc");
      this.buildProfessores();
    }
  },
  mounted: function() {
    var self = this;
    this.buildProfessores();
  },
  methods:{

    buildProfessores() {
      var self = this;
      this.uc = this.ucSel;
      //console.log("buildProfessores");
      //console.log("this.uc",this.uc);
      this.loading = true;
      if(this.uc.id != undefined) {
        self.professores = [];
        var profsRepetidos = {};
        rdb.ref("/curriculo/ofertas/2021S2").orderByChild("ucID").equalTo(self.uc.id).limitToFirst(1).on('value',function(snap) {
          if(snap.val() == null) {
            self.loading = false;
          } else {
            rdb.ref("/curriculo/ofertas/2021S2").orderByChild("ucID").equalTo(self.uc.id).on('child_added',function(snap) {
              self.loading = false;
              rdb.ref("/usuarios/pessoas").child(snap.val().profID).once('value',function(snap) {
                if(profsRepetidos[snap.val().id] == undefined) {
                  profsRepetidos[snap.val().id] = true;
                  self.professores.push(snap.val());
                }
              });
            });
          }
        });
      }
    },

  }
}
</script>

<style scoped>

</style>
