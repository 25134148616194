<template>
  <div class="m-0 p-0">
    <v-list-item class="" v-if="professor != null" style="height: 34px; min-height: 34px;">
      <v-list-item-avatar size="32" class="mt-0">
        <v-avatar size="32">
          <progressiveimage :height="32" :src="professor.urlFoto"/>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="text-left pt-0 mt-0">
        <v-list-item-title class="pt-0 mt-0">
          <p class="mb-0 fs-9pt f-raleway fw-500">
            {{professor.nome}}
          </p>
        </v-list-item-title>
        <v-list-item-subtitle>
          <p class="mb-2 text-muted fs-10pt f-roboto">
            <span class="badge py-1 mt-1 ms-0 fs-7pt f-roboto fw-400 text-dark" style="backgroundColor: #f0f0f0; vertical-align: bottom;">
              {{professor.ies}} - {{professor.regional}}
            </span>
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon class="fs-10pt px-0 mx-0">
      </v-list-item-icon>
    </v-list-item>
    <v-divider class="mt-0"></v-divider>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import tagsarea from '@/views/pessoas/tagsAreas'
import progressiveimage from "@/components/progressive-image/progressive-image.vue"

export default {
  name: "professoresView",
  components: { tagsarea, progressiveimage },
  props: [ "editLock", "area", "ucSel", "prof", "liberadoSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      uc: null,
      professor: null,
      liberado: false,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      console.log("professorItem change uc");
      this.buildProfessor();
    },
    liberadoSel(to, from) {
      var self = this;
      console.log("professorItem change liberado");
      this.buildProfessor();
    },
    prof(to, from) {
      var self = this;
      console.log("professorItem change prof");
      this.buildProfessor();
    }
  },
  mounted: function() {
    var self = this;
    this.buildProfessor();
  },
  methods:{

    buildProfessor() {
      var self = this;
      this.uc = this.ucSel;
      this.professor = this.prof;
      this.liberado = this.liberadoSel;
    },

    liberar() {
      console.log(this.professor);
      console.log(this.uc);
      var item = {
        id: this.professor.id,
        nome: this.professor.nome,
        urlFoto: this.professor.urlFoto,
        email: this.professor.email,
      }
      //item.liberado = true;
      var updates = {};
      updates['/curriculo/2021S2/ucsProfsRef/' + this.uc.id + "/professores/" + this.professor.id] = item;
      console.log(updates);
      rdb.ref().update(updates);
    },

    bloquear() {
      console.log(this.professor);
      console.log(this.uc);
      var updates = {};
      updates['/curriculo/2021S2/ucsProfsRef/' + this.uc.id + "/professores/" + this.professor.id] = null;
      console.log(updates);
      rdb.ref().update(updates);
    }


  }
}
</script>

<style scoped>

</style>
