<template>
  <v-row v-if="coment != null" class="mt-0 mx-0 px-0 mb-2">
    <v-col cols="12" class="my-0 py-0 mx-0 ">
      <v-card class="m-0 p-0 mt-0 mb-1" flat v-bg:b#3>
        <v-row class="mx-0">
          <v-col cols="1">
            <v-avatar size="36" class="ms-2">
              <v-img :src="coment.urlFoto"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="11" class="">
            <p class="m-0 ms-2 p-0 line-height-1">
              <span class="m-0 mb-1 p-0 f-roboto fw-500 fs-7pt">
                {{coment.usrNome}}
              </span>
              <span v-cHex="color" class="ms-1 f-sanspro fs-8pt fw-300">
                {{$moment(coment.createdAt).fromNow()}}
              </span>
            </p>
            <div class="m-0 ms-2 p-0 f-raleway fw-300 fs-10pt">
              <readmore class="m-0 p-0" size="100" :text="coment.texto" />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" class="my-0 py-0">
      <v-card class="mx-0 p-0 mt-0 mb-0" flat>
        <v-toolbar flat v-bg:b#7 height="26" max-height="26" class="px-2 mt-0">
          <v-toolbar-title>
            <likes :uc="uc" :color="color" :rdbPath="rdbPath" :key="refresh"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="" v-if="!commentblocked">
              <v-btn @click="showResposta('')" class="m-0 p-0" icon small>
                <i v-i:ic#reply#16 class="btn-icon-left"></i>
              </v-btn>
              <v-btn @click="showResposta('')" class="m-0 p-0 ms-n1 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
                <span class="f-raleway fs-9pt fw-500">responder</span>
              </v-btn>
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div v-if="respostas.length==0" style="width: 60px;"></div>
            <v-btn v-if="respostas.length>0" @click="showComents" small class="m-0 p-0 me-2" icon>
              <i v-i:ic#comment#16 class="btn-icon-left"></i>
            </v-btn>
            <v-btn v-if="respostas.length>0" @click="showComents" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
              <span class="ms-n3 f-raleway fs-9pt fw-500">
                {{respostas.length}} resposta<span v-if="respostas.length>1">s</span>
              </span>
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>
      </v-card>
    </v-col>
    <v-col cols="12" class="mt-n2 mb-n1">
      <v-row v-if="!commentblocked" class="mt-0 pt-1 mb-0">
        <v-col cols="12" md="2" class="text-right mt-1" v-if="caixaResposta">
          <v-avatar size="36" class="mt-n2 p-0">
            <v-img :src="user.urlFoto"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="11" md="9" class=" mt-1" v-if="caixaResposta">
          <v-row class="border">
            <textarea  class="f-overlock fs-10pt fw-400" rows="2" ref="txtmensagem2" v-model="textoResposta"></textarea>
          </v-row>
        </v-col>
        <v-col cols="1" md="1" class=" mt-1" v-if="caixaResposta">
          <v-btn @click="addResposta" class="m-0 p-0 ms-n2 mt-n1" icon small>
            <i v-i:ic#send#24 v-cHex="color"></i>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="respostaEnabled" class="mt-0">
        <v-col cols="12" :key="qtdeLista">
          <div class="m-0 p-0" v-for="(resposta,index) in respostas" :key="index">
            <feedcomentitem v-if="index<qtdeLista" :uc="uc" :color="color" :rdbPath="rdbPath+'/respostas/'+resposta.id" @novaResposta="showResposta"/>
          </div>
          <p class="m-0 mt-n4 mb-0 mx-4 p-0 text-right" v-if="respostas.length > qtdeLista">
            <v-btn @click="qtdeLista=qtdeLista+4" color="" class="p-0 m-0 text-nonecase fw-300 fs-8pt" text x-small>mostrar mais</v-btn>
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import { faker } from '@/components/dummy/faker.js'
import feedcomentitem from "./feedComentItem.vue"
import moment from 'moment';
import likes from "./likes.vue"

export default {
  name: 'feedComent',
  components: { readmore, dummy, feedcomentitem, likes },
  props: {
    color: { default: "#000000", type: String },
    uc: { default: null, type: Object },
    rdbPath: { default: "/pathNotDefined", type: String },
  },
  computed: {},
  data() {
    return {
      showCurtidas: false,
      respostaEnabled: false,
      caixaResposta: false,
      textoResposta: "",
      coment: null,
      likes: [],
      liked: false,
      likedId: "semID",
      respostas: null,
      textEdicaoEnabled: false,
      commentblocked: false,
      refresh: 0,
      qtdeLista: 4,
    }
  },
  watch: {
  },
  mounted() {
    var self = this;
    console.log("rdbPath",this.rdbPath);

    self.user = self.userLog;

    if(this.rdbPath != undefined) {
      rdb.ref(this.rdbPath).on('value',function(snapshot) {
        var ret = snapshot.val();
        console.log("coment");
        console.log(ret);
        self.coment = ret;
      });
      rdb.ref(this.rdbPath+"/respostas").on('value',function(snapshot) {
        var ret = snapshotValToArray(snapshot.val());
        console.log("respostas");
        console.log(ret);
        self.respostas = ret;
      });
    }

    this.eventBus.$on("closeAllComents", function() {
      console.log("closeAllComents")
      self.caixaResposta = false;
    });

  },
  methods: {

    showComents() {
      console.log("showComents");
      this.respostaEnabled = !this.respostaEnabled;
      console.log("this.respostaEnabled",this.respostaEnabled);
      this.caixaResposta = false;
      this.qtdeLista = 4;
    },

    showResposta(nome = "") {
      var self = this;
      self.$emit('closeResp');
      if(this.caixaResposta) {
        this.caixaResposta = false;
        return;
      }
      this.eventBus.$emit("closeAllComents");
      this.caixaResposta = !this.caixaResposta;
      if(this.caixaResposta) {
        if(nome == "") {
          this.textoResposta = "@"+this.coment.usrNome+" ";
        } else {
          this.textoResposta = nome;
        }
        this.$forceUpdate();
        this.$nextTick(function() {
          self.$refs.txtmensagem2.focus();
        })
      }
      return;
      if(this.respostaEnabled) {
        self.$emit('closeResp');
        this.eventBus.$emit("closeAllComents");
        this.respostaEnabled = false;
      } else {
        self.$emit('closeResp');
        this.eventBus.$emit("closeAllComents");
        this.respostaEnabled = true;
        if(nome == "") {
          this.textoResposta = "@"+this.coment.usrNome+" ";
        } else {
          this.textoResposta = nome;
        }
        this.$nextTick(function() {
          self.$refs.txtmensagem2.focus();
        })
      }
    },

    addResposta() {
      console.log("addResposta");
      console.log(this.textoResposta);
      if(this.textoResposta.trim().replaceAll("@"+this.coment.usrNome,"") == "") {
        console.log("sem texto");
        this.showResposta();
        return;
      }
      var respostaObj = {
        usrNome: this.user.nome,
        usrID: this.user.id,
        urlFoto: this.user.urlFoto,
        texto: this.textoResposta,
        createdAt: moment().format(),
      };
      console.log(this.rdbPath);
      this.pushKey("respostas",respostaObj);
      console.log("resposta pushed success!");
      this.textoResposta = "";
      //this.$emit('closeResp');
      //this.eventBus.$emit("closeAllComents");
      //this.respostasEnabled = true;
      //this.showResposta();
      this.caixaResposta = false;
    },

    pushKey(path,obj) {
      var pathUrl = this.rdbPath+"/"+path+"/";
      console.log("pushKey on: ",pathUrl);
      var newKey = rdb.ref(pathUrl).push().key;
      console.log("put com nova chave: "+newKey);
      var itemClone = Object.assign({}, obj);
      itemClone.id = newKey;
      var updates = {};
      console.log(pathUrl + newKey);
      updates[pathUrl + newKey] = itemClone;
      rdb.ref().update(updates);
      console.log("pushKey success!");
    },

    clickLike() {
      console.log("clickLike");
      console.log(this.rdbPath);
      console.log(this.user);
      //var user = faker.getRamdonRealUser();
      //console.log(user);
      if(this.liked) {
        var pathUrl = this.rdbPath+"/likes/"+this.likedId;
        console.log(pathUrl);
        rdb.ref(pathUrl).remove();
        this.liked = false;
        this.likedId = "semID";
        console.log("like removed!");
      } else {
        var likeObj = {
          usrNome: this.user.nome,
          usrID: this.user.id,
          createdAt: moment().format(),
        };
        this.pushKey("likes",likeObj);
      }
    }

  }
}
</script>

<style scoped>

.form-control:focus{border-color: #4d594d;  box-shadow: none; -webkit-box-shadow: none;}
.has-error .form-control:focus{box-shadow: none; -webkit-box-shadow: none;}

</style>
