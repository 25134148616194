<template>
  <div class="m-0 p-0" v-if="rdbPath != null">
    <div v-if="createdAt != undefined" v-cHex="hexShades(area.cor,.6)" class="col-12 col-lg-12 my-0 mx-0 p-0 f-raleway fs-8pt fw-300 text-right" style="margin-top: 6px;">
      atualizado {{$moment(createdAt).fromNow()}}
      <span class="fs-8pt fas fa-check" :style="cor.area()"></span>
    </div>
    <hr v-if="createdAt != undefined" class="m-0 my-0 p-0">
    <div class="row mt-1 mb-0 mx-0 p-0">
      <div class="col-12 col-lg-4 m-0 p-0 text-left">
        <v-btn class="m-0 p-0" icon @click="clickLike">
          <i class="btn-icon-left fs-12pt m-0 p-0" v-c:S v-if="!liked" v-i:ic#thumb-up#20></i>
          <i class="btn-icon-left fs-12pt m-0 p-0" v-cHex="hexShades(area.cor,.1)" v-if="liked" v-i:ic#thumb-up#20></i>
        </v-btn>
        <span class="m-0 p-0 f-raleway fs-10pt fw-500" v-if="likes.length==0">
          curtir
        </span>
        <v-tooltip v-if="likes.length>0" right class="p-0" color="black" v-model="showCurtidas" open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
              <span class="m-0 p-0 f-raleway fs-10pt fw-500" v-if="likes.length>0">
                {{likes.length}} curtida<span v-if="likes.length>1">s</span>
              </span>
            </v-btn>
          </template>
          <div class="p-0 m-0 f-raleway fs-8pt fw-500" v-for="(like,index) in likes" :key="index" v-if="index<=10">
            <p class="m-0 p-0" v-if="index<10">{{like.usrNome}}</p>
            <p class="m-0 p-0 fs-10pt fw-700" v-if="index==10">e mais {{likes.length-10}} pessoas...</p>
          </div>
        </v-tooltip>
      </div>
      <div class="col-12 col-lg-4 m-0 p-0 text-center">
        <v-btn v-if="coments.length >0" @click="showComents(false)" class="m-0 p-0 ms-2" icon>
          <i class="btn-icon-left" v-cHex="hexShades(area.cor,.1)" v-i:duo#chat-circle-text#20></i>
        </v-btn>
        <v-btn v-if="coments.length >0" @click="showComents(false)" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
          <span class="f-raleway fs-10pt fw-500">
            {{coments.length}} comentário<span v-if="coments.length>1">s</span>
          </span>
        </v-btn>
      </div>
      <div :style="cor.area()" class="col-12 col-lg-4 m-0 p-0 f-raleway fs-10pt fw-300 text-right">
        <v-btn @click="showComents(true)" class="m-0 p-0 ms-0" icon>
          <i class="btn-icon-left" v-cHex="hexShades(area.cor,.1)" v-i:duo#chat-circle-text#20></i>
        </v-btn>
        <v-btn @click="showComents(true)" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
          <span class="f-raleway fs-10pt fw-500">comentar</span>
        </v-btn>
      </div>
    </div>
    <hr class="m-0 my-1 p-0">

    <div v-if="comentsEnabled" class="mx-0 px-0">
      <div class="m-0 p-0 text-right">
        <v-icon class="m-0 p-0 me-1 fs-12pt fw-100">mdi-arrow-up-circle</v-icon>
        <v-btn @click="showComents(false)" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
          <span class="f-raleway fs-10pt fw-500">recolher comentários</span>
        </v-btn>
      </div>

      <!-- COMENTÁRIOS -->
      <feedcoment v-for="(coment,index) in coments" :key="index" :area="area" :rdbPath="rdbPath+'/coments/'+coment.id"/>

      <v-list-item class="m-0 p-0 mt-n4">
        <v-list-item-avatar size="44">
          <v-img :src="user.urlFoto"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-fab-transition>
            <v-btn @click="addComent" class="mt-9 me-1" :color="cor.area()" x-small fab right top absolute>
              <v-icon :style="cor.area()">mdi-send</v-icon>
            </v-btn>
          </v-fab-transition>
          <textarea ref="txtcomment" v-model="textoComent" class="pe-13 form-control form-control-sm fs-10pt f-raleway" style="height: 48px;"></textarea>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import { color } from "./colors.js"
import { faker } from '@/components/dummy/faker.js'
import feedcoment from "./feedComent.vue"
import moment from 'moment';

export default {
  name: 'biblioItem',
  components: { readmore, dummy, feedcoment },
  props: [ "areaSel", "rdbPathProp" ],
  computed: {
    cor: function() { return color; },
    profileImg: function() { return faker.profileImg(); },
    time: function() { return faker.get("date","past"); },
    paragraphs: function() { return faker.get("lorem","paragraphs"); },
  },
  data() {
    return {
      rdbPath: null,
      user: {},
      comentsEnabled: false,
      showCurtidas: false,
      respostaEnabled: false,
      likes: [],
      liked: false,
      likedId: "semID",
      coments: [],
      textoComent: "",
      createdAt: "",
    }
  },
  watch: {
    rdbPathProp(to, from) {
      var self = this;
      this.build();
    },
    areaSel(to, from) {
      var self = this;
      console.log("areaSel changed!");
      this.build();
    }
  },
  mounted() {
    var self = this;
    this.build();

    eventBus.$on("closeAllComents", function() {
      console.log("closeAllComents")
      self.respostaEnabled = false;
    });

    //faker.initGlobalUsers();

  },
  methods: {

    build() {
      var self = this;
      this.rdbPath = this.rdbPathProp;
      this.area = this.areaSel;
      self.liked = false;
      this.comentsEnabled = false;
      self.user = self.userLog;

      color.init(this.area.cor);
      //console.log(color);
      //console.log(color.area30());
      //console.log("rdbIdx",this.rdbPath);

      if(this.rdbPath != null) {
        rdb.ref(this.rdbPath+"/likes").on('value',function(snapshot) {
          var ret = snapshotValToArray(snapshot.val());
          //console.log("likes");
          //console.log(ret);
          self.likes = ret;
          for (var i = 0; i < ret.length; i++) {
            if(ret[i].usrID == self.user.id) {
              self.liked = true;
              self.likedId = ret[i].id;
              break;
            }
          }
        });
        rdb.ref(this.rdbPath+"/coments").on('value',function(snapshot) {
          var ret = snapshotValToArray(snapshot.val());
          //console.log("coments");
          //console.log(ret);
          self.coments = ret;
        });
        rdb.ref(this.rdbPath+"/createdAt").on('value',function(snapshot) {
          var ret = snapshot.val();
          self.createdAt = ret;
        });
      }
    },

    showComents(focus) {
      var self = this;
      console.log("showComents");
      if(!this.comentsEnabled) {
        this.comentsEnabled = true;
        eventBus.$emit("closeAllComents");
        if(focus) {
          this.$nextTick(function() {
            self.$refs.txtcomment.focus();
          });
        }
      } else {
        if(focus) {
          this.$nextTick(function() {
            self.$refs.txtcomment.focus();
          });
        } else {
          eventBus.$emit("closeAllComents");
          this.comentsEnabled = false;
        }
      }
    },

    showResposta(focus) {
      eventBus.$emit("closeAllComents");
      this.respostaEnabled = !this.respostaEnabled;
      this.textoComent = "";
    },

    addComent() {
      console.log("addComent");
      console.log(this.textoComent);
      var comentObj = {
        usrNome: this.user.nome,
        usrID: this.user.id,
        urlFoto: this.user.urlFoto,
        texto: this.textoComent,
        createdAt: moment().format(),
      };
      this.pushKey("coments",comentObj);
      console.log("coment pushed success!");
      this.respostaEnabled = !this.respostaEnabled;
      this.textoComent = "";
      this.comentsEnabled = true;
      eventBus.$emit("closeAllComents");
    },

    pushKey(path,obj) {
      var pathUrl = this.rdbPath+"/"+path+"/";
      console.log("pushKey on: ",pathUrl);
      var newKey = rdb.ref(pathUrl).push().key;
      console.log("put com nova chave: "+newKey);
      var itemClone = Object.assign({}, obj);
      itemClone.id = newKey;
      var updates = {};
      console.log(pathUrl + newKey);
      updates[pathUrl + newKey] = itemClone;
      rdb.ref().update(updates);
      console.log("pushKey success!");
    },

    clickLike() {
      console.log("clickLike");
      console.log(this.rdbPath);
      console.log(this.user);
      //var user = faker.getRamdonRealUser();
      //console.log(user);
      if(this.liked) {
        var pathUrl = this.rdbPath+"/likes/"+this.likedId;
        console.log(pathUrl);
        rdb.ref(pathUrl).remove();
        this.liked = false;
        this.likedId = "semID";
        console.log("like removed!");
      } else {
        var likeObj = {
          usrNome: this.user.nome,
          usrID: this.user.id,
          createdAt: moment().format(),
        };
        this.pushKey("likes",likeObj);
      }
    }

  }
}
</script>

<style scoped>

.form-control:focus{border-color: #eeeeee;  box-shadow: none; -webkit-box-shadow: none;}
.has-error .form-control:focus{box-shadow: none; -webkit-box-shadow: none;}

</style>
