<template>
  <div class="m-0 p-0">
    <v-btn @click="$refs.dialogmetadetalhesref.show()" class="mx-1 px-1 text-nonecase f-sanspro fw-300 fs-10pt" :color="area.cor" text x-small>
      <i v-i:duo#eye#14 class="btn-icon-left me-0"></i>
      <span class="ms-0 me-1 f-sanspro fw-600 ms-1 fs-8pt">ver detalhes</span>
    </v-btn>
    <span>
      <span class="f-sanspro fw-800 fs-9pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeConteudos > 0">{{qtdeConteudos}}</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeConteudos == 0">sem conteúdos</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeConteudos == 1">conteúdo</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeConteudos>1">conteúdos</span>
    </span>
    <span>
      <span class="f-sanspro fw-800 fs-9pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeAcordos > 0">{{qtdeAcordos}}</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeAcordos == 0">sem acordos</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeAcordos == 1">acordo</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="qtdeAcordos>1">acordos</span>
    </span>
    <span>
      <span class="f-sanspro fw-800 fs-9pt" v-cHex="hexTints(area.cor,0.4)" v-if="questoes.length > 0">{{questoes.length}}</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="questoes.length == 0">sem questões</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="questoes.length == 1">questão</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="questoes.length>1">questões</span>
      <spanblocked equipeID="gestores" :color="area.cor" icon />
      <span class="me-1 rounded py-0 ps-1 pe-1 fs-8pt fw-600 f-sanspro" v-c:B :style="'background-color:'+area.cor">
        <span v-i:duo#star#12 v-c:B class="relative" style="right: 0px; top: -1px;"></span>
        A2
      </span>
    </span>
    <dialogmetadetalhes ref="dialogmetadetalhesref" :uc="uc" :meta="meta" :area="area" />
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import spanblocked from "./spanBlocked.vue"
import dialogmetadetalhes from "@/views/cursos/avaliacao2/metaDetalhes/dialogMetaDetalhes.vue"

export default {
  name: "qtdequestoespormeta",
  components: { spanblocked, dialogmetadetalhes },
  props: [ "meta", "area", "uc" ],
  computed: {},
  data(){
    return {
      questoes: [],
      conteudos: {},
      qtdeConteudos: 0,
      acordos: {},
      qtdeAcordos: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    this.buildQuestoes();
  },
  methods:{

    buildQuestoes() {
      var self = this;
      rdb.ref("/curriculo/questoesValidas/").orderByChild("metaID").equalTo(this.meta.id).on('value',function(snapshot) {
        self.questoes = snapshotValToArray(snapshot.val());
        //console.log("questoes");
        //console.log(self.questoes);
      });
      rdb.ref("/curriculo/metasNovas/"+this.meta.id).on('value',function(snapshot) {
        var meta = snapshot.val();
        self.conteudos = {};
        self.qtdeConteudos = 0;
        self.acordos = {};
        self.qtdeAcordos = 0;
        if(meta != null) {
          if(meta.conteudos != undefined) {
            self.conteudos = meta.conteudos;
            self.qtdeConteudos = Object.keys(self.conteudos).length;
          }
          if(meta.acordos != undefined) {
            self.acordos = meta.acordos;
            self.qtdeAcordos = Object.keys(self.acordos).length;
          }
        }
        //console.log("questoes");
        console.log("self.conteudos",self.conteudos);
        console.log("self.acordos",self.acordos);
      });
    },

    qtdeClick() {
      var self = this;
      console.log("qtdeClick");
      this.eventBus.$emit("toggleBoxQuestoes",{
        slotKey: "second",
        title: "Lista de itens",
        dados: {
          meta: self.meta,
          questoes: self.questoes
        }
      });
    }


  }
}
</script>

<style scoped>

</style>
