<template>
  <v-row v-bg:b>
    <v-col cols="12" class="m-0 p-0">
      <v-row class="mt-0 pt-0">
        <v-col cols="12" lg="4" class="mt-0 pt-0">
          <listaempresas :area="area" @empresaClick="empresaClick"/>
        </v-col>
        <v-col cols="12" lg="8" class="mt-0 pt-0">
          <painelempresa :empresa="empresaSel" :area="area"/>
        </v-col>
      </v-row>
    </v-col>
    <div class="p-0 absolute" style="width: 100%; height: 700px; min-height: 600px; top: 0px; left: 0px; z-index: 2">
      <v-card class="m-0 p-0" style="width: 100%; min-height: 700px;" v-bg:K#5>
        <div class="mx-auto" style="width: 200px; padding-top: 50px;">
          <div class="p-2 rounded text-left text-center" v-c:B v-bg:K>
            <span class="f-raleway">em breve...</span>
          </div>
        </div>
      </v-card>
    </div>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import cardempresa from '@/components/duaiscomponents/cardEmpresa'
import listaempresas from "./listaEmpresas.vue"
import painelempresa from "./painelEmpresa.vue"

export default {
  name: "empresasareaview",
  props: [ "area" ],
  components: { cardempresa, listaempresas,painelempresa },
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
  },
  data(){
    return {
      tab: null,
      empresas: [],
      empresaSel: null,
    }
  },
  watch: {
    area(to, from) {
      var self = this;
      self.build();
    }
  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      console.log("empresasareaview build");
      rdb.ref('/empresas').on('value',function(snapshot) {
        var ret = snapshotValToArray(snapshot.val());
        self.empresas = ret;
        self.empresaSel = ret[0];
        console.log("self.empresas",self.empresas);
      });

    },

    empresaClick(empresa) {
      console.log("empresaClick",empresa);
      this.empresaSel = empresa;
    }

  }
}
</script>

<style scoped>

</style>
