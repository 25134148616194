<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="headline text-center">
      </v-card-title>
      <v-card-text>
        <div class="my-0 py-0 fs-12pt f-raleway fw-700 text-center">
          <v-icon class="fs-11pt me-2 pb-0 mb-0 mt-n1">fas fa-edit</v-icon>
          <span class="">{{acao}} </span>{{fieldTitle}}
        </div>
        <v-divider class="my-3"></v-divider>
        <v-textarea class="mb-0 f-lato fs-11pt" v-model="texto" @update:error="erroUpdate" label="Digite um texto" :rules="rules" auto-grow solo rows="8"></v-textarea>
        <v-divider class="mt-0"></v-divider>
        <div class="text-center">
          <v-btn color="" small @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn class="ms-4" color="success" small :disabled="!erroValor" @click="dialog = false; $emit('changeValue',texto);">
            ADICIONAR
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "textAreaDialog",
  components: { },
  props: {
    mensagem: { default: "", type: String },
    color: { default: "#000000", type: String },
    fieldTitle: { default: "", type: String },
    acao: { default: "Adicionar", type: String },
  },
  computed: { },
  data(){
    return {
      texto: "",
      msgProp: "",
      dialog: false,
      rules: [
        value => !!value || 'Texto obrigatório!',
        value => (value && value.length >= 10) || 'Mínimo 10 caracteres',
      ],
      erroValor: false,
    }
  },
  watch: {
    mensagem(to, from) {
      var self = this;
      this.build();
    },
    dialog(to, from) {
      var self = this;
    },
  },
  mounted: function() {
    var self = this;
    this.build();
  },
  methods:{

    build() {
      var self = this;
    },

    show(texto) {
      console.log("texto",texto);
      if(texto == undefined) {
        this.texto = "";
      } else {
        this.texto = texto;
        this.erroValor = true;
        if(this.texto >= 10) {
          this.erroValor = false;
        }
      }
      this.refresh++;
      this.dialog = true;
    },

    erroUpdate(value) {
      this.erroValor = !value;
      console.log(value);
    }

  }
}

</script>

<style scoped>

</style>
