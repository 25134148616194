<template>
  <!-- NIVEL 2 -->
  <div class="me-1 mt-n2 mb-0 py-0" style="margin-left: 8%" v-if="resposta != null">
    <v-list three-line class="my-0 py-0">
      <v-list-item>
        <v-list-item-avatar size="40">
          <v-img :src="resposta.urlFoto"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <div class="m-0 mb-1 p-0 fs-raleway fw-500 fs-11pt">
              {{resposta.usrNome}}
              <span class="ms-1 f-raleway fs-10pt fw-300">{{$moment(resposta.createdAt).fromNow()}}</span>
            </div>
          </v-list-item-title>
          <div class="m-0 p-0 fs-raleway fw-300 fs-11pt" style="word-wrap: normal;">
            <readmore class="m-0 p-0" size="300" :text="resposta.texto" />
          </div>
          <v-list-item-subtitle>
            <div class="row m-0 p-0">
              <div class="col-12 col-lg-6 text-left m-0 p-0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <v-btn class="me-0" icon @click="clickLike">
                  <i class="btn-icon-left fs-12pt m-0 p-0" v-c:S v-if="!liked" v-i:ic#thumb-up#20></i>
                  <i class="btn-icon-left fs-12pt m-0 p-0" v-cHex="hexShades(area.cor,.1)" v-if="liked" v-i:ic#thumb-up#20></i>
                </v-btn>
                <span class="m-0 p-0 f-raleway fs-10pt fw-500" v-if="likes.length==0">
                  curtir
                </span>
                <v-tooltip right class="p-0" v-model="showCurtidas" open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
                      <span class="m-0 p-0 f-raleway fs-10pt fw-500" v-if="likes.length>0">
                        {{likes.length}} curtida<span v-if="likes.length>1">s</span>
                      </span>
                    </v-btn>
                  </template>
                  <div class="p-0 m-0 f-raleway fs-8pt fw-500" v-for="(like,index) in likes" :key="index" v-if="index<=10">
                    <p class="m-0 p-0" v-if="index<10">{{like.usrNome}}</p>
                    <p class="m-0 p-0 fs-10pt fw-700" v-if="index==10">e mais {{likes.length-10}} pessoas...</p>
                  </div>
                </v-tooltip>
              </div>
              <div class="col-12 col-lg-6 text-left m-0 p-0">
                <v-btn @click="showResposta" class="m-0 p-0 ms-2" icon>
                  <v-icon class="fs-11pt m-0 p-0">mdi-reply</v-icon>
                </v-btn>
                <v-btn @click="showResposta" class="m-0 p-0 text-lowercase text-dark" small plain text style="letter-spacing: normal;">
                  <span class="f-raleway fs-9pt fw-500">responder</span>
                </v-btn>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="py-0 mt-n4" v-if="respostaEnabled">
        <v-list-item-avatar size="40" class="mt-6">
          <v-img :src="user.urlFoto"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-fab-transition>
            <v-btn class="mt-11 me-1" :color="cor.area()" x-small fab right top absolute>
              <v-icon :style="cor.area()">mdi-send</v-icon>
            </v-btn>
          </v-fab-transition>
          <textarea ref="txtmensagem" v-model="texto" class="pe-13 form-control form-control-sm fs-10pt f-raleway" style="height: 44px;"></textarea>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </div>

</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import readmore from "@/components/readmore.vue"
import dummy from "@/components/dummy/dummy.vue"
import { color } from "./colors.js"
import { faker } from '@/components/dummy/faker.js'
import moment from 'moment';

export default {
  name: 'feedComentItem',
  components: { readmore, dummy },
  props: [ "area", "rdbPath" ],
  computed: {
    cor: function() { return color; },
    profileImg: function() { return faker.profileImg(); },
    time: function() { return faker.get("date","soon"); },
    paragraphs: function() { return faker.get("lorem","paragraphs"); },
  },
  data() {
    return {
      respostaEnabled: false,
      texto: "",
      user: null,
      resposta: null,
      showCurtidas: false,
      likes: [],
      liked: false,
      likedId: "semID",
    }
  },
  watch: {
  },
  mounted() {
    var self = this;
    color.init(this.area.cor);
    console.log(color);
    console.log(color.area30());
    console.log("rdbPath",this.rdbPath);

    self.user = self.userLog;

    if(this.rdbPath != undefined) {
      rdb.ref(this.rdbPath).on('value',function(snapshot) {
        var ret = snapshot.val();
        console.log("resposta");
        console.log(ret);
        self.resposta = ret;
      });
      rdb.ref(self.rdbPath+"/likes").on('value',function(snapshot) {
        var ret = snapshotValToArray(snapshot.val());
        console.log("likes in: ",self.rdbPath+"/likes");
        console.log(ret);
        self.likes = ret;
        for (var i = 0; i < ret.length; i++) {
          if(ret[i].usrID == self.user.id) {
            self.liked = true;
            self.likedId = ret[i].id;
            break;
          }
        }
        self.$nextTick(function() {
          self.$forceUpdate();
        })
      });
    }

    eventBus.$on("closeAllComents", function() {
      console.log("closeAllComents")
      self.respostaEnabled = false;
    });

  },
  methods: {

    showResposta() {
      var self = this;
      console.log("showResposta");
      this.$emit("novaResposta", "@"+this.resposta.usrNome+" ");
    },

    pushKey(path,obj) {
      var pathUrl = this.rdbPath+"/"+path+"/";
      console.log("pushKey on: ",pathUrl);
      var newKey = rdb.ref(pathUrl).push().key;
      console.log("put com nova chave: "+newKey);
      var itemClone = Object.assign({}, obj);
      itemClone.id = newKey;
      var updates = {};
      console.log(pathUrl + newKey);
      updates[pathUrl + newKey] = itemClone;
      rdb.ref().update(updates);
      console.log("pushKey success!");
    },

    clickLike() {
      console.log("clickLike");
      console.log(this.rdbPath);
      console.log(this.user);
      //var user = faker.getRamdonRealUser();
      //console.log(user);
      if(this.liked) {
        var pathUrl = this.rdbPath+"/likes/"+this.likedId;
        console.log(pathUrl);
        rdb.ref(pathUrl).remove();
        this.liked = false;
        this.likedId = "semID";
        console.log("like removed!");
      } else {
        var likeObj = {
          usrNome: this.user.nome,
          usrID: this.user.id,
          createdAt: moment().format(),
        };
        this.pushKey("likes",likeObj);
      }
    }


  }
}
</script>

<style scoped>

.form-control:focus{border-color: #4d594d;  box-shadow: none; -webkit-box-shadow: none;}
.has-error .form-control:focus{box-shadow: none; -webkit-box-shadow: none;}

</style>
