<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar :color="cor.active.b">
          <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-400 fs-11pt">
            <p class="p-0 m-0">Indicação de metas de compreensão - A2</p>
            <p class="p-0 m-0 fw-600" v-c:D>GESTÃO DE ÁREA</p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="f-sanspro fw-600 fs-12pt" v-if="uc != null">
            <div class="">
              <div class="m-0 p-0 mt-0">
                <i v-i:duo#graduation-cap#20 class="btn-icon-left me-1"></i>
                <span class="ms-0 mt-0 pt-0 text-left f-roboto fw-600 fs-11pt f-raleway">
                  {{uc.nome}}
                </span>
              </div>
              <div class="m-0 mt-n1 p-0" style="">
                <span v-for="(area, key, index) in uc.areas" :key="key">
                  <tagarea class="me-1" :sigla="area.sigla"/>
                </span>
              </div>
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="f-sanspro fw-600 fs-12pt">
            <div class="">
              <div class="m-0 p-0 mt-n2">
                <v-avatar size="34" class="mt-2">
                  <v-img :src="userLog.urlFoto"></v-img>
                </v-avatar>
                <span class="ms-0 mt-0 pt-0 text-left f-sanspro fw-600 fs-10pt f-raleway">
                  {{userLog.nome}}
                </span>
              </div>
              <div class="p-0" style="margin-left: 38px; margin-top: -20px;">
                <span class="ms-0 mt-0 pt-0 text-left f-roboto fw-200 fs-10pt f-raleway">
                  {{userLog.cargo}} - {{userLog.ies}} - {{userLog.regional}}
                </span>
              </div>
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-row class="mx-1 mt-1">
          <v-col cols="12" sm="6" class="">
            <v-card class="mt-1 mx-0" v-if="uc != null" flat>
              <v-card-title class="my-0 py-1 fs-9pt" v-c:I v-bg:S#2>
                <span class="fw-500 fs-11pt f-sanspro">Indique metas de compreensão</span>
                <v-badge v-if="metas.length > 0" :color="hexShades(cor.active.S,0.2)" class="ms-2" inline :content="metas.length"></v-badge>
                <span v-if="metasVet.length > 0" class="ms-4 f-lato fw-400 fs-9pt" :style="'color: '+hexTints(cor.active.P,.1)">
                  <a2tooltipprofsmeta :uc="uc" :metaSel="{ votos: profsVet }" />
                </span>
                <span class="ms-3">{{profsVet.length}}</span>
                <span class="ms-1">Metas recomendadas</span>
              </v-card-title>
              <v-divider class="mt-0 mb-1 py-0"></v-divider>
              <v-card-text class="px-1" v-bg:B>
                <v-row>
                  <v-col cols="12" class="my-0 py-0" v-bg:B>
                    <v-card class="card-scroll px-2 pt-2" height="540px" v-bg:b>
                      <v-card class="m-0 mb-2 p-1 pt-2" v-for="(meta,index) in metasVet" :key="index" :color="hexTints(cor.active.b,0.5)" style="min-height: 70px;">
                        <div class="absolute text-center" style="right: 5px; top: 0px;">
                          <v-card class="mt-2 mx-0 mb-2 p-0 px-1 fs-7pt line-height-3" v-bg:S#3 flat>
                            <span class="p-0 mx-0 line-height-0 f-sanspro fw-400">{{meta.qtdeVotos}}</span>
                            <span class="p-0 mx-0 line-height-0 f-sanspro fw-600"> / {{profsVet.length}}</span>
                          </v-card>
                          <span v-if="!indicacaoBlock">
                            <v-btn v-if="metasIndicadas[meta.id] != undefined" class="ms-0" icon v-bg:V##Dk#2 icon small @click="selecionarMeta(meta)">
                              <i v-i:duo#thumbs-up#20 class="btn-icon-left ps-1" v-cHex="'#ffffff'"></i>
                            </v-btn>
                            <p v-if="metasIndicadas[meta.id] != undefined" v-cHex="'#07711b'" class="p-0 m-0 mt-1 line-height-1 fs-7pt text-center f-sanspro fw-400">
                              INDICADA
                            </p>
                            <v-btn v-if="metasIndicadas[meta.id] == undefined" class="me-1" icon v-bg:S##Lt#4 icon small @click="selecionarMeta(meta)">
                              <i v-i:duo#thumbs-up#20 class="btn-icon-left ps-1" v-cHex="'#79768a'"></i>
                            </v-btn>
                          </span>
                          <span v-if="indicacaoBlock && metasIndicadas[meta.id] != undefined">
                            <i v-i:duo#thumbs-up#20 class="btn-icon-left ps-1" v-cHex="'#089c00'"></i>
                            <p v-cHex="'#07711b'" class="p-0 m-0 mt-1 line-height-1 fs-7pt text-center f-sanspro fw-400">
                              INDICADA
                            </p>
                          </span>
                          <span class="me-2" v-if="indicacaoBlock && metasIndicadas[meta.id] == undefined">
                            <i v-i:duo#thumbs-down#20 class="btn-icon-left ps-1 me-2" v-cHex="'#b55555'"></i>
                            <p v-cHex="'#07711b'" class="p-0 m-0 mt-1 line-height-1 fs-7pt text-center f-sanspro fw-400">

                            </p>
                          </span>
                        </div>
                        <v-tooltip v-if="metaSelObj != null" :disabled="meta.qtdeVotos == 0" content-class="m-0 p-0" open-delay="1200" bottom nudge-bottom="-20" nudge-left="0" :color="cor.active.B">
                          <template v-slot:activator="{ on, attrs }">
                            <v-card flat class="m-1 mt-2 ms-1" style="width: 90%" v-bind="attrs" v-on="on">
                              <v-card v-bg:S#3 v-if="meta.id == metaSelObj.id" hover class="mx-0 px-3 py-1 f-sanspro fs-11pt fw-300 text-justify" @click="clickMeta(meta)">
                                <readmore class="m-0 p-0" size="300" :text="meta.texto" :key="index"/>
                              </v-card>
                              <v-card v-bg:B v-if="meta.id != metaSelObj.id" hover class="mx-0 px-3 py-1 f-sanspro fs-11pt fw-300 text-justify" @click="clickMeta(meta)">
                                <readmore class="m-0 p-0" size="300" :text="meta.texto" :key="index"/>
                              </v-card>
                              <progressmeta :qtde="meta.qtdeVotos" :qtdeTotal="profsVet.length"/>
                            </v-card>
                          </template>
                          <tooltipcontent :meta="meta" :qtde="meta.qtdeVotos" :qtdeTotal="profsVet.length" :votos="meta.votos"/>
                          <template>
                          </template>
                        </v-tooltip>
                      </v-card>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <painelconteudosindicacao v-if="metaSelObj != null" :uc="uc" :meta="metaSelObj" :key="metaSelObj.id"/>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/components/tagArea.vue"
import readmore from "@/components/readmore.vue"
import painelconteudosindicacao from "./conteudos/painelConteudosIndicacao.vue"
import a2tooltipprofsmeta from '@/views/cursos/avaliacao/a2toolTipProfsMeta.vue'
import progressmeta from "./progress-meta.vue"
import tooltipcontent from "./tooltip-content.vue"

export default {
  name: "recomendarmeda",
  components: { tagarea, readmore, painelconteudosindicacao, a2tooltipprofsmeta, progressmeta, tooltipcontent },
  props: [],
  computed: {
    indicacaoBlock() {
      return (this.aprovacao && !this.correcao);
    }
  },
  data(){
    return {
      model: 0,
      dialog: false,
      loading: false,
      refresh: 0,
      uc: null,
      metaIndex: 0,
      metas: {},
      metaSelObj: null,
      metasSel: {},
      metasVet: [],
      metasTodosProfs: [],
      totalIndicacoes: 0,
      ucMetas: {},
      profs: {},
      profsVet: [],
      metasIndicadas: {},
      correcao: false,
      aprovacao: false,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
  },
  methods:{

    build() {
      var self = this;
    },

    show(uc) {
      var self = this;
      //console.log("show",uc);
      this.dialog = true;
      this.uc = uc;
      //console.log("this.uc",this.uc);

      rdb.ref("/curriculo/avaliacao/2021S2/ucsA2Indicadas/"+self.uc.id).on('value', function(snapshot) {
        var value = snapshot.val();
        if(value == null) {
          self.correcao = false;
          self.aprovacao = false;
        } else {
          self.correcao = value.correcao;
          self.aprovacao = true;
        }
      });

      rdb.ref('/curriculo/avaliacao/2021S2/metasIndicadasFinal/').orderByChild('ucID').equalTo(this.uc.id).on('value', function(snapshot) {
        self.metasIndicadas = {};
        if(snapshot.val() != null) {
          self.metasIndicadas = snapshot.val();
        }
        console.log("self.metasIndicadas",self.metasIndicadas);

        rdb.ref('/curriculo/metas').orderByChild('ucID').equalTo(self.uc.id).once('value', function(snapshot) {
          self.metas = snapshot.val();
          for(var key in self.metas) {
            self.metas[key].votos = [];
            self.metas[key].qtdeVotos = 0;
          }

          rdb.ref('/curriculo/avaliacao/2021S2/ucs/'+self.uc.id).on('value', function(snapshot) {
            var ucRef = snapshot.val();
            //console.log("ucRef",ucRef);
            if(ucRef != null) {
              var recomendacoes = ucRef.metasIndicacoes;
              self.profs = {};
              for(var key in recomendacoes) {
                self.profs[key] = recomendacoes[key].professor;
                var metas = recomendacoes[key].metasIndicadas;
                for(var metaKey in metas) {
                  if(self.metas[metaKey] == undefined) {
                    console.log("meta não encontrada!",metas[metaKey]);
                  } else {
                    self.metas[metaKey].votos.push(recomendacoes[key].professor);
                    self.metas[metaKey].qtdeVotos++;
                  }
                }
              }
              self.profsVet = objToArray(self.profs);
              //console.log("self.profs",self.profs);
              //console.log("self.profsVet",self.profsVet);
              self.metasVet = objToArray(self.metas);
              //console.log("self.metas",self.metas);
              //console.log("self.metasVet",self.metasVet);

              for(var key in self.metas) {
                self.metas[key].questoes = [];
                rdb.ref('/curriculo/questoes').orderByChild("metaID").equalTo(key).on('value',function(snapshot) {
                  var questoes = snapshotValToArray(snapshot.val());
                  //console.log("questoes",questoes);
                  self.metas[key].questoes = questoes;
                });

              }
            }

            self.metasVet = self.arrayObjectsSort(objToArray(self.metas),"qtdeVotos",true);
            self.metaSelObj = self.metasVet[0];
            //console.log(self.$refs);
            //console.log("self.metas",self.metas);
            console.log("self.metasVet",self.metasVet);

          });
        });
      });

    },

    arrayObjectsSort(vet, sortKey, desc = false) {
      function compare( a, b ) {
        if ( a[sortKey] < b[sortKey] ){
          return (desc ? 1 : -1);
        }
        if ( a[sortKey] > b[sortKey] ){
          return (desc ? -1 : 1);
        }
        return 0;
      }
      vet.sort( compare );
      return vet;
    },

    selecionarMeta(meta) {
      var self = this;
      console.log("selecionarMeta",meta);
      var updates = {};
      if(self.metasIndicadas[meta.id] == undefined) {
        updates["/curriculo/avaliacao/2021S2/metasIndicadasFinal/"+meta.id] = meta;
      } else {
        updates["/curriculo/avaliacao/2021S2/metasIndicadasFinal/"+meta.id] = null;
      }
      console.log("updates",updates);
      rdb.ref().update(updates);
      self.refresh++;
      self.$forceUpdate();
    },

    clickMeta(meta) {
      console.log("clickMeta",meta);
      this.metaSelObj = meta;
    }

  }
}
</script>

<style scoped>

.card-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
