<template>
  <v-card class="p-1 px-2 pe-3 m-0" v-if="area != null" :color="hexTints(area.cor,0.98)">
    <v-container class="p-0 m-0 mt-1 mb-1">
      <v-row no-gutters align="center" justify="start">
        <v-col class="" style="max-width: 60px;">
          <div class="text-center">
            <tagarea class="me-1" :sigla="area.sigla"/>
          </div>
        </v-col>
        <v-col cols="9" class="pt-1">
          <p class="mb-0 line-height-2 f-roboto fs-10pt fw-500 text-truncate" v-cHex="hexShades(area.cor,0.2)">
            {{area.nome}}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="p-0 m-0 mt-2 mb-0">
      <div class="">
        <semidonut class="" :color="area.cor" :values="[porcLiberados]"/>
        <p class="m-0 p-0 mt-n6 text-center f-lato fs-9pt fw-700" v-cHex="hexShades(area.cor,0.2)">
          Liberados
        </p>
      </div>
      <v-card outlined elevation="1" class="px-1 m-0 mt-2 text-center" :color="hexTints(area.cor,0.95)">
        <span class="f-lato fs-10pt fw-600">{{qtde.liberado}}</span>
        <span class="ms-1 f-lato fs-9pt fw-300">itens liberados em</span>
        <span class="ms-1 f-lato fs-10pt fw-800">{{qtde.encomendas}}</span>
        <span class="ms-1 f-lato fs-9pt fw-300">encomendas</span>
      </v-card>
    </v-container>
    <v-card class="mt-2 pt-1">
      <progressmulti :values="[porcVisualizados,porcNaoVisualizados]" :colors="[area.cor,'#bbbbbb']" :labels="['Visualizados', 'Não visualizados']"/>
    </v-card>
    <v-container class="p-0 m-0 mt-2 mb-0">
      <v-row dense align="center" justify="space-around">
        <v-col cols="" class="">
          <v-card outlined elevation="1" class="p-0 px-2 py-1 m-0" :color="hexTints(area.cor,0.98)">
            <p class="m-0 p-0 ms-0 f-sanspro fw-400 fs-9pt text-center line-height-2" v-cHex="hexShades(area.cor,0.3)">
              <span class="fw-800">{{qtde.revisao}}</span> em revisão
            </p>
            <div class="mt-1 p-0">
              <v-progress-linear rounded height="7" :value="emRevisao" :color="hexTints(area.cor,0.2)"></v-progress-linear>
            </div>
            <div class="mt-1 fw-600 fs-8pt text-center line-height-1">{{emRevisao}}%</div>
          </v-card>
        </v-col>
        <v-col cols="" class="">
          <v-card outlined elevation="1" class="p-0 px-2 py-1 m-0" :color="hexTints(area.cor,0.97)">
            <p class="m-0 p-0 ms-0 f-sanspro fw-400 fs-9pt text-center line-height-2" v-cHex="hexShades(area.cor,0.3)">
              <span class="fw-800">{{qtde.correcao}}</span> em correção
            </p>
            <div class="mt-1 p-0">
              <v-progress-linear rounded height="7" :value="emCorrecao" :color="hexShades(area.cor,0.2)"></v-progress-linear>
            </div>
            <div class="mt-1 fw-600 fs-8pt text-center line-height-1">{{emCorrecao}}%</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="mt-2" outlined elevation="1" :color="hexTints(area.cor,0.95)">
      <v-container class="p-0 m-0 mt-0 mb-0">
        <v-row dense align="center" justify="space-around">
          <v-col cols="6" class="">
            <p class="my-1 text-center line-height-2" v-cHex="hexShades(area.cor,0.1)">
              <span class="my-1 line-height-2 f-lato fs-10pt fw-600" v-cHex="hexShades(area.cor,0.1)">{{qtde.ucs}}</span>
              <span class="ms-1 f-lato fs-9pt fw-300">UCs Ofertadas</span>
            </p>
          </v-col>
          <v-col cols="6" class="">
            <p class="my-1 text-center line-height-2 f-sanspro fs-8pt fw-500" v-cHex="hexShades(area.cor,0.1)">
              <span class="my-1 line-height-2 f-lato fs-10pt fw-600" v-cHex="hexShades(area.cor,0.1)">{{qtde.profs}}</span>
              <span class="ms-1 f-lato fs-9pt fw-300">professores</span>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div class="m-0 my-1 mt-2 text-center" v-if="button">
      <v-btn color="" @click="$emit('areaClick',area)" x-small class="px-1 pt-0 text-nonecase f-lato fs-7pt" outlined :color="hexShades(area.cor,0.2)">
        mais informações
      </v-btn>
    </div>
    <v-overlay absolute :value="loading">
      <div class="text-center">
        <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
      </div>
      <div class="text-center mb-4" style="margin-top: 20px;">
        <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import semidonut from "./semi-donut.vue";
import tagarea from "@/views/cursos/tagArea.vue";
import progressmulti from "./progress-multi.vue";

export default {
  name: "areacard",
  components: { semidonut, tagarea, progressmulti },
  props: {
    area: { default: null, type: Object },
    button: { default: true, type: Boolean },
  },
  computed: {
    emRevisao() {
      return parseFloat(this.qtde.revisao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    emCorrecao() {
      return parseFloat(this.qtde.correcao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    porcLiberados() {
      return parseFloat(this.qtde.liberado / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcNaoVisualizados() {
      return parseFloat(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcVisualizados() {
      return parseFloat(100-(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100)).toFixed(1);
    },
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      encomendas: {},
      entregas: {},
      ucs: {},
      profs: {},
      profsCientes: {},
      qtde: { encomendas: 0, entregas: 0, naoVisualizados: 0, ucs: 0, profs: 0, revisao: 0, correcao: 0, liberado: 0 },
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    if(this.area != null) {

      rdb.ref('/curriculo/avaliacao/2021S2/profsCientes').on('value',function(snapshot) {
        self.profsCientes = snapshot.val();
        //console.log("self.profsCientes ["+Object.keys(self.profsCientes).length+"]",self.profsCientes);
        self.build();
      });

      rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').orderByChild("area"+self.area.sigla).equalTo(true).on('value',function(snapshot) {
        self.encomendas = snapshot.val();
        if(self.encomendas != null) {
          //console.log("self.encomendas ["+Object.keys(self.encomendas).length+"]",self.encomendas);
        } else {
          self.encomendas = {};
        }
        self.qtde.encomendas = Object.keys(self.encomendas).length;
        rdb.ref('/curriculo/avaliacao/2021S2/entregasA2Limpo').orderByChild("area"+self.area.sigla).equalTo(true).on('value',function(snapshot) {
          self.entregas = snapshot.val();
          if(self.entregas != null) {
            //console.log("self.entregas ["+Object.keys(self.entregas).length+"]",self.entregas);
          } else {
            self.entregas = {};
          }
          self.qtde.entregas = Object.keys(self.entregas).length;
          self.build();
        });
      });
    }
  },
  methods:{

    build() {
      var self = this;
      self.loading = false;
      self.ucs = {};
      for(var key in self.encomendas) {
        var ucID = self.encomendas[key].ucID;
        var profID = self.encomendas[key].profID;
        self.ucs[ucID] = ucID;
        self.profs[profID] = profID;
      }
      self.qtde.ucs = Object.keys(self.ucs).length;
      self.qtde.profs = Object.keys(self.profs).length;

      self.qtde.revisao = 0;
      self.qtde.correcao = 0;
      self.qtde.liberado = 0;
      for(var key in self.entregas) {
        //console.log(self.entregas[key].revisaoStatus);
        if(self.entregas[key].revisaoStatus == "Revisão") {
          self.qtde.revisao++;
        }
        if(self.entregas[key].revisaoStatus == "Para correção") {
          self.qtde.correcao++;
        }
        if(self.entregas[key].revisaoStatus == "Liberadas para aplicação") {
          self.qtde.liberado++;
        }
      }

      var cientes = 0;
      for(var key in self.encomendas) {
        if(self.profsCientes[self.encomendas[key].profID] != undefined) {
          cientes++;
        }
      }
      self.qtde.naoVisualizados = self.qtde.encomendas - cientes;

      //console.log("qtde",self.qtde);
    }
  }
}
</script>

<style scoped>
</style>
