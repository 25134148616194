<template>
  <div class="row" v-if="nano != null">
    <div class="col-12 col-lg-12 text-left">
      <p class="m-0 mb-0 f-monserrat fs-13pt fw-400" :style="'color:'+area.cor">
        <i v-i:duo#graduation-cap#24 class="me-1"></i>
        {{nano.nome}}
      </p>
      <hr class="my-0 mt-1">
      <posplanoview ref="planoviewref" v-if="boxview == 'plano'" :area="area" :nano="nano" @listaDescontinuados="listaDescontinuados" />
      <!--
      <descontinuadosementa v-if="boxview == 'ementa'" :area="area" :ucSel="uc" @close="close(0)" />
      <descontinuadostopicos v-if="boxview == 'topicos'" :area="area" :ucSel="uc" @close="close(1)" />
      <descontinuadosmetas v-if="boxview == 'metas'" :area="area" :ucSel="uc" @close="close(2)" />
      <descontinuadoscompetencias v-if="boxview == 'competencias'" :area="area" :ucSel="uc" @close="close(3)" />
    -->
    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import dummy from "@/components/dummy/dummy.vue"
import posplanoview from "@/views/cursos/pos/posPlanoView.vue"
import descontinuadosementa from "@/views/cursos/planos/descontinuadosEmenta.vue"
import descontinuadosmetas from "@/views/cursos/planos/descontinuadosMetas.vue"
import descontinuadostopicos from "@/views/cursos/planos/descontinuadosTopicos.vue"
import descontinuadoscompetencias from "@/views/cursos/planos/descontinuadosCompetencias.vue"

export default {
  name: "planoContainer",
  components: { dummy, posplanoview, descontinuadosementa, descontinuadosmetas, descontinuadostopicos, descontinuadoscompetencias },
  props: [ "editLock", "area", "nanoSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      boxview: "plano",
      nano: null,
    }
  },
  watch: {
    nanoSel(to, from) {
      var self = this;
      self.nano = self.nanoSel;
      this.boxview = "plano";
      console.log("posplanoContainer change nano");
    }
  },
  mounted: function() {
    var self = this;
    self.nano = self.nanoSel;
  },
  methods:{

    listaDescontinuados(tipo) {
      console.log("listaDescontinuados ",tipo);
      this.boxview = tipo;
    },

    close(tab) {
      var self = this;
      console.log(tab);
      this.boxview = 'plano';
      this.$nextTick(function() {
        self.$refs.planoviewref.goTab(tab);
      });
    }

  }
}
</script>

<style scoped>

</style>
