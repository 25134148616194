<template>
  <div class="">

    <boxview class="mt-0" :slots="['main','second']" eventToggle="toggleConteudoBox">
      <template v-slot:main>
        <v-card class="mt-1 mx-0" flat>
          <v-card-title class="my-0 py-1 fs-11pt" v-c:P v-bg:S#4>
            <span class="fw-500 fs-11pt f-sanspro">Recomendar conteúdos / acordos</span>
          </v-card-title>
          <v-divider class="mt-0 mb-1 py-0"></v-divider>
        </v-card>
        <v-row>
          <v-col cols="12">
            <v-card class="mt-1">
              <v-tabs height="28" class="mt-0 fs-10pt text-left" v-model="tab" background-color="transparent" :color="cor.active.P">
                <v-tab class="" v-for="item in ['Conteúdos', 'Acordos']" :key="item" style="min-width: 0px;">
                  <template slot:default class="px-0">
                    <span class="text-nonecase f-lato fs-11pt fw-600">{{item}}</span>
                  </template>
                </v-tab>

                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <listaconteudos :meta="meta" />
                  </v-tab-item>
                  <v-tab-item>
                    <listacordos :meta="meta" />
                  </v-tab-item>
                </v-tabs-items>

              </v-tabs>

            </v-card>
          </v-col>
        </v-row>

      </template>

      <!-- SECOND -->
      <template v-slot:second>
        <v-card>
            <v-card-title class="text-left">
              olá
            </v-card-title>
          </v-card>
      </template>
    </boxview>

    <textareadialog ref="txtareadialogacordo" fieldTitle="Recomendar acordo" mensagem="" @changeValue="novoTexto" />

  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import boxview from '@/components/boxView.vue'
import textareadialog from './textAreaDialog.vue'
import listaconteudos from "./listaConteudos.vue"
import listacordos from "./listaAcordos.vue"

export default {
  name: "painelconteudos",
  components: { boxview, listaconteudos, listacordos, textareadialog },
  props: [ "meta" ],
  computed: {},
  data(){
    return {
      tab: null,
      loading: false,
      refresh: 0,
      conteudos: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //console.log("this.meta",this.meta);
    //self.build();
  },
  methods:{

    build() {
      var self = this;
    },

    novoTexto(texto) {
      console.log("novoTexto",texto);
    }

  }
}
</script>

<style scoped>

.card-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #909090 #BBBBBB;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

</style>
