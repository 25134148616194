<template>
  <div class="m-0 p-0" :style="'width: 100%; height:'+height+'px'">
    <progressive-background :src="src">
      <div slot="content" slot-scope="{ visible }">
        <v-card class="text-center" :height="height+'px'" color="rgba(0, 0, 0, 0.2)" loading v-show="visible">
          <div class="text-center p-0 mb-0" :style="'margin-top:'+(height/3)+'px'">
            <img :width="(height/2.5)+'px'" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-icone.png?alt=media&token=e79300a1-6691-4a84-b4c6-6f6086ba3c92" alt="">
          </div>
          <div class="py-0" :style="'margin-top: -'+(height/1.9)+'px;'">
            <v-progress-circular indeterminate :width="2" :size="(height/1.6)" color="grey"></v-progress-circular>
          </div>
        </v-card>
      </div>
    </progressive-background>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "",
  components: {},
  props: [ "src", "height" ],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
